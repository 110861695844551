import React from 'react';
import { Switch } from 'react-router-dom';
import Partners from '../../../Partnership/Partners';
import AddPartners from '../../../Partnership/AddPartner';
import DetailPartner from '../../../Partnership/Detail';
import PrivateRoute from '../../../../shared/components/PrivateRoute';

export default () => (
  <Switch>
    <PrivateRoute path="/partnership/index" component={Partners} />
    <PrivateRoute path="/partnership/add-partner" component={AddPartners} />
    <PrivateRoute path="/partnership/detail/:id" component={DetailPartner} />
  </Switch>
);
