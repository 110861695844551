import { createAction } from 'redux-actions';
import axios from 'axios';
import {
    LOCALSTORAGE_TOKEN,
    ADD_PARTNER_FAILED, ADD_PARTNER_TIMEOUT,
    PARTNER_LIST,
} from '../types';
import { PARTNER_URL } from '../../utils/endPoint';

export const addPartnerFailed = createAction(ADD_PARTNER_FAILED);
export const addPartnerTimeout = createAction(ADD_PARTNER_TIMEOUT);
export const getPartnerSuccess = createAction(PARTNER_LIST);


export const getPartner = () => async (dispatch) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios.get(PARTNER_URL, { headers: { Authorization: `Token ${token}` } }) 
        .then((res) => {
        dispatch(getPartnerSuccess({ data: res.data }));
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addPartnerTimeout()), 3000);
        dispatch(addPartnerFailed({ error }));
    });
};

