/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
 Container, ButtonToolbar, Row, Col, Spinner,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import renderSelectField from '@/shared/components/form/Select';
import axios from 'axios';
import { getUser } from '../../../../redux/actions/userActions';
import { CUSTOMER_URL } from '../../../../utils/endPoint';
import { LOCALSTORAGE_TOKEN } from '../../../../redux/types';

const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
const CustomerForm = (stateOri) => {
    const [isEditable, setEditable] = useState(false);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const history = useHistory();
    const { dispatch } = stateOri;
    const { location } = history;
    const { state } = location;
    const { data } = state;
    const { initialize } = stateOri;
    const { listProduct } = stateOri; 

    useEffect(() => {
      initialize({
 ...data, name: data?.fullname, email: data?.email, phone: data?.phone, role: { value: data.role, label: data.role },
});
    }, []);

    useEffect(() => {
      dispatch(getUser());
    }, [dispatch]);
    console.log(listProduct);
    console.log(data);

    return (
      <Container>
        <form 
          className="form product-edit"
          onSubmit={(e) => {
            e.preventDefault();
            const dataUpdate = new FormData();
            dataUpdate.append('fullname', [e.target.name][0].value);
            dataUpdate.append('email', [e.target.email][0].value);
            dataUpdate.append('phone', [e.target.phone][0].value);
            dataUpdate.append('role', [e.target.role][0].value);
            
            const updateDataCustomer = async () => {
              setLoading(true);
              try {
                const res = await axios.patch(`${CUSTOMER_URL}${id}/`, dataUpdate, {
                  headers: {
                    Authorization: `Token ${token}`,
                  },
                });
                console.log(res);
                setTimeout(() => {
                  setLoading(false);
                  history.push('/customer/index');
                }, 1000);
              } catch (err) {
                console.log(err.response);
              }
            };
            updateDataCustomer();
          }}
        >
          
          <div className="form__form-group">
            <span className="form__form-group-label">Nama</span>
            <div className="form__form-group-field">
              <Field
                name="name"
                component="input"
                type="text"
                required
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Email</span>
            <div className="form__form-group-field">
              <Field
                name="email"
                component="input"
                type="email"
                required
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">No. HP</span>
            <div className="form__form-group-field">
              <Field
                name="phone"
                component="input"
                type="number"
                required
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className="form__form-group-id-category">
            <div className="form__form-group">
              <span className="form__form-group-label">Role</span>
              <div className="form__form-group-field">
                <Field 
                  name="role"
                  component={renderSelectField}
                  type="text"
                  required
                  options={[{ value: 'Admin', label: 'Admin' },
                    { value: 'Kasie Pelatih', label: 'Kasie Pelatih' },
                    { value: 'Kepala Stable', label: 'Kepala Stable' },
                    { value: 'Pelatih', label: 'Pelatih' },
                    { value: 'Penanggung Jawab Kuda', label: 'Penanggung Jawab Kuda' },
                    { value: 'Administrasi Dan Keuangan', label: 'Administrasi Dan Keuangan' },
                    { value: 'Administrasi Dan Lapangan', label: 'Administrasi Dan Lapangan' },
                    { value: 'Pengunjung ', label: 'Pengunjung ' },
                    { value: 'Pegawai', label: 'Pegawai' }]}
                  placeholder="Pilih Role"
                  disabled={!isEditable}
                />
              </div>
            </div>
          </div>
          {/* <div className="form__form-group">
            <span className="form__form-group-label">Password</span>
            <div className="form__form-group-field">
              <Field
                name="password"
                component="input"
                type="text"
                required
                disabled
              />
            </div>
          </div> */}
          <ButtonToolbar className="form__button-toolbar">
            <Row className="mt-4">
              <Col md={12}>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setEditable(!isEditable)}
                >
                  {!isEditable ? 'Edit' : 'Cancel'}
                </button>
                {isEditable && (
                <button type="submit" className="btn btn-primary">
                  {
                    loading ? <Spinner size="sm">Loading...</Spinner> : 'Update'
}
                </button>
              )}
              </Col>
            </Row>
          </ButtonToolbar>
        </form>       
      </Container>
    );
};
 

export default connect(
// bind account loading action creator
)(reduxForm({
form: 'customer_edit_form', // a unique identifier for this form
enableReinitialize: true,
})(CustomerForm));
