/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router';
import ProductListTable from './components/ImageProductListTable';
import CreateTableData from './components/CreateData';
import { LOCALSTORAGE_TOKEN } from '../../../../../../redux/types';


const ImageProductList = () => {
  const { id } = useParams();
  console.log(id);
  const [image, setImage] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios.get(`https://api.ghaidas.com/api-ghaidas/imagebyidproduct/${id}`,
    { headers: { Authorization: `Token ${token}` } })
    .then((res) => {
      setImage(res.data);
      // console.log(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);
  const history = useHistory();
  const { location } = history;
  const { state } = location;
  const { data } = state;

  const ProductListTableData = CreateTableData(image);
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Daftar Gambar Produk</h3>
        </Col>
      </Row>
      <Row>
        <ProductListTable ProductListTableData={ProductListTableData} />
      </Row>
    </Container>
  );
};

export default (ImageProductList);
