/* eslint-disable default-case */
import {
    COMMUNITY_LIST,
} from '../types';

const defaultState = {
    data: [],
    loading: true,
  };
  

const communityReducer = (state = defaultState, action) => {
    switch (action.type) {
        case COMMUNITY_LIST: {
          return {
            ...state,
            data: action.payload.data,
            loading: false,
          };
        }
        default: {
          return {
            ...state,
          };
        }
      }
};

export default communityReducer;
