import React, { useState } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import moment from 'moment';
import { useHistory } from 'react-router';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DetailSchedule from './ScheduleForm';
import { updateSchedule } from '../../../../redux/actions/scheduleActions';

const ScheduleCard = () => {
  const history = useHistory();
  const {
    location: {
      state: { data },
    },
  } = history;
  const dispatch = useDispatch();
  const [, setErr] = useState('');

  const onSubmit = (e) => {
    const updatedData = new FormData();
    if (e.user_id && e.type && e.trainer_id) {
      updatedData.append('userId', e.user_id.value);
      updatedData.append('date', moment(e.start).format('YYYY-MM-DD'));
      updatedData.append('start_time', moment(e.start).format('HH:mm'));
      updatedData.append('end_time', moment(e.end).format('HH:mm'));
      updatedData.append('status', e.status.value);
      updatedData.append('type', e.type.value);
      if (e.horse_id.value) {
        updatedData.append('horseId', e.horse_id.value);
      }
      if (e.trainer_id.value) {
        updatedData.append('trainerId', e.trainer_id.value);
      }
      dispatch(updateSchedule(updatedData, data.id, history));
    } else {
      setErr('Mohon Isi Data Dengan Lengkap');
    }
    // console.log(e.user_id);
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Schedule</h5>
            <div className="products-list__btn-toolbar-top" />
          </div>
          <DetailSchedule onSubmit={onSubmit} />
        </CardBody>
      </Card>
    </Col>
  );
};

ScheduleCard.propTypes = {};

const mapDispatchToProps = {
  updateScheduleAction: updateSchedule,
};

const mapStateToProps = () => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ScheduleCard),
);
