import React from 'react';
import { Switch } from 'react-router-dom';
// import DetailCategories from '../../../ServiceCategory/DetailCategory';
import PrivateRoute from '../../../../shared/components/PrivateRoute';
import BookingList from '../../../Booking/BookingList';

import AddBooking from '../../../Booking/AddBooking';
import BookingDetail from '../../../Booking/Detail';

export default () => (
  <Switch>
    <PrivateRoute path="/booking/index" component={BookingList} />
    <PrivateRoute path="/booking/add-booking" component={AddBooking} />
    <PrivateRoute path="/booking/detail/:id" component={BookingDetail} />

  </Switch>
);
