/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
 ButtonToolbar, Col, Row, Container, 
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { useHistory } from 'react-router-dom';
import renderFileInputField from '@/shared/components/form/FileInput';
import axios from 'axios';
import { FACILITY_URL } from '../../../../../utils/endPoint';
import { LOCALSTORAGE_TOKEN } from '../../../../../redux/types';

const DetailPartners = (stateOri) => {
  const history = useHistory();
  const [isEditable, setEditable] = useState(false);
  const { location } = history;
  const { state } = location;
  const { data } = state;
  const { initialize } = stateOri;

  useEffect(() => {
    initialize(data);
  }, []);

  return (
    <Container>
      <form
        className="form form--horizontal"
        onSubmit={(e) => {
          e.preventDefault();
          const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
          const updatedData = new FormData();
          updatedData.append('name', [e.target.name][0].value);
          if ([e.target.icon][0].files[0]) {
            updatedData.append('icon', [e.target.icon][0].files[0]);
          } else if (data.icon && data.icon.file) {
            updatedData.append('icon', data.icon.file);
          }
          axios
            .patch(`${FACILITY_URL + data?.id}/`, data, {
              headers: { Authorization: `Token ${token}` },
            })
            .then((res) => {
              console.log(res.data);
              history.push('/product/facility-product-list');
            })
            .catch((err) => {
              console.log(err);
            });
        }}
      >
        <div className="form__form-group">
          <span className="form__form-group-label">Nama Fasilitas</span>
          <div className="form__form-group-field">
            <Field
              name="name"
              component="input"
              type="text"
              required
              placeholder="Nama Fasilitas"
              disabled={!isEditable}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Upload Ikon</span>
          <div className="form__form-group-field">
            <Field name="icon" required component={renderFileInputField} />
            <span>
              {data.icon ? (
                <a className="ml-2" target="blank" href={data.icon}>
                  {data.icon.split('?')[0]}
                </a>
              ) : (
                'Tidak Ada'
              )}
            </span>
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar">
          <Row className="mt-4">
            <Col md={12}>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setEditable(!isEditable)}
              >
                {' '}
                {!isEditable ? 'Edit' : 'Cancel'}
              </button>
              {isEditable && (
                <button type="submit" className="btn btn-primary">
                  {' '}
                  Update
                </button>
              )}
              {isEditable === false && (
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                    axios
                      .delete(`${FACILITY_URL + data?.id}/`, {
                        headers: { Authorization: `Token ${token}` },
                      })
                      .then((res) => {
                        console.log(res.data);
                        history.push('/product/facility-product-list');
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                  className="btn btn-danger"
                >
                  Delete
                </button>
              )}
            </Col>
          </Row>
        </ButtonToolbar>
      </form>
    </Container>
  );
};

export default reduxForm({
  form: 'facility_edit_form', // a unique identifier for this form
  enableReinitialize: true,
})(DetailPartners);
