/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
 ButtonToolbar, Col, Row, Alert, Container, 
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import axios from 'axios';
import renderFileInputField from '@/shared/components/form/FileInput';
import renderSelectField from '@/shared/components/form/Select';
import {
  deleteImageProduct,
  updateImageProduct,
} from '../../../../../redux/actions/imageproductActions';

const DetailPartners = (stateOri) => {
  const history = useHistory();
  const [isEditable, setEditable] = useState(false);
  const { updateResponse, updateData } = stateOri;
  const { dispatch } = stateOri;
  const { location } = history;
  const { state } = location;
  const { data } = state;
  const { initialize } = stateOri;

  if (updateData) {
    initialize(updateData);
  }
  useEffect(() => {
    initialize(data);
  }, []);
  const [products, setProducts] = useState([]);
  // const [loading, setLoading] = useState(false);
  useEffect(() => {
    axios
      .get(`https://api.ghaidas.com/api-ghaidas/product/${data.product}`)
      .then((res) => {
        setProducts(res.data);
        // setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Container>
      <form
        className="form form--horizontal"
        onSubmit={(e) => {
          e.preventDefault();
          const updatedData = new FormData();
          // updatedData.append('name', [e.target.name][0].value);
          updatedData.append('nama', [e.target.nama][0].value);
          updatedData.append('product', [e.target.product][0].value);
          if ([e.target.product][0].value) {
            updatedData.append('product', [e.target.product][0].value);
          } else if (data.product && data.product.value) {
            updatedData.append('product', data.product.value);
          }
          // updatedData.append('image', [e.target.image][0].files[0]);
          if ([e.target.image][0].files[0]) {
            updatedData.append('image', [e.target.image][0].files[0]);
          } else if (data.image && data.image.file) {
            updatedData.append('image', data.image.file);
          }
          dispatch(updateImageProduct(updatedData, data.id, history, products));
        }}
      >
        <div className="form__form-group">
          <span className="form__form-group-label">Nama</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="nama"
              component="input"
              type="text"
              placeholder="Nama"
              disabled={!isEditable}
              required
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Produk</span>
          <div className="form__form-group-field">
            <Field component="input" disabled placeholder={products.name} />
          </div>
          <div className="form__form-group-field">
            <Field
              name="product"
              component={renderSelectField}
              type="text"
              required
              options={[{ value: `${products.id}`, label: `${products.name}` }]}
              placeholder="Pilih Kategori"
            />
            {/* <Select 
                defaultValue={products.id}
                name="product" 
                classNamePrefix="react-select"
                className="react-select"
                placeholder={products.name}
                disable
              /> */}
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Upload Gambar</span>
          <div className="form__form-group-field">
            <Field
              name="image"
              required
              component={renderFileInputField}
              disabled={!isEditable}
            />
            <span>
              {data.image ? (
                <a className="ml-2" target="blank" href={data.image}>
                  {data.image.split('?')[0]}
                </a>
              ) : (
                'Tidak Ada'
              )}
            </span>
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar">
          <Row className="mt-4">
            <Col md={12}>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setEditable(!isEditable)}
              >
                {' '}
                {!isEditable ? 'Edit' : 'Cancel'}
              </button>
              {isEditable && (
                <button type="submit" className="btn btn-primary">
                  {' '}
                  Update
                </button>
              )}
              {isEditable === false && (
                <button
                  type="button"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(deleteImageProduct(data.id, history, products));
                  }}
                  className="btn btn-danger"
                >
                  {' '}
                  Delete
                </button>
              )}
            </Col>
          </Row>
        </ButtonToolbar>
      </form>
      {updateResponse ? (
        <Row>
          <Col md={12}>
            <Alert className="container mb-4 p-1" color="info">
              <p>Update Data berhasil</p>
            </Alert>
          </Col>
        </Row>
      ) : null}
    </Container>
  );
};

const mapStateToProps = state => ({
  errorMessage: state.partner.errorMessage,
  updateResponse: state.partner.updateResponse,
  updateData: state.partner.updateData,
});
const mapDispatchToProps = {
  updateImageProductAction: updateImageProduct,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps, // bind account loading action creator
)(
  reduxForm({
    form: 'partner_edit_form', // a unique identifier for this form
    enableReinitialize: true,
  })(DetailPartners),
);
