import React from 'react';
import {
 Card, CardBody, Col, Button, 
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import DetailProducts from './PaymentForm';

function productDetailHandler(history, info) {
  history.push({
    pathname: `/product/add-images/${info.id}`, 
    state: { data: info },
  });
}
const PaymentCard = () => {
  const history = useHistory();
  const { location } = history;
  const { state } = location;
  const { data } = state;
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Produk</h5>
            {/* <div className="products-list__btn-toolbar-top">
              <Button 
                type="button"
                className="btn btn-primary products-list__btn-add"
                onClick={() => productDetailHandler(history, data)}
              >
                Gambar Baru
              </Button>
            </div> */}
          </div>
          <DetailProducts />
        </CardBody>
      </Card>
    </Col>
  );
};

export default PaymentCard;
