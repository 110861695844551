import React from 'react';
import { Switch } from 'react-router-dom';
import HorseList from '../../../Horse/HorseList';
// import DetailCategories from '../../../ServiceCategory/DetailCategory';
import PrivateRoute from '../../../../shared/components/PrivateRoute';
import AddHorse from '../../../Horse/AddHorse';
import HorseDetail from '../../../Horse/Detail';
import NewImageHorse from '../../../Horse/Detail/ImageHorse/NewImage';

export default () => (
  <Switch>
    <PrivateRoute path="/horse/index" component={HorseList} />
    <PrivateRoute path="/horse/add-horse" component={AddHorse} />
    <PrivateRoute path="/horse/detail/:id" component={HorseDetail} />
    <PrivateRoute path="/horse/image/:id" component={NewImageHorse} />
    {/* <PrivateRoute
      path="/horse/detail-horse/:id"
      component={DetailHorse}
    /> */}
  </Switch>
);
