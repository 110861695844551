import { createAction } from 'redux-actions';
import axios from 'axios';

import {
    LOCALSTORAGE_TOKEN,
    ADD_MEMBER_SUCCESS, ADD_MEMBER_FAILED, ADD_MEMBER_ONGOING, ADD_MEMBER_TIMEOUT,
    MEMBER_LIST,
    UPDATE_MEMBER_SUCCESS, UPDATE_MEMBER_FAILED,
    DELETE_MEMBER_SUCCCES, DELETE_MEMBER_FAILED,
} from '../types';
import { MEMBER_URL } from '../../utils/endPoint';

export const addMemberSuccess = createAction(ADD_MEMBER_SUCCESS);
export const addMemberFailed = createAction(ADD_MEMBER_FAILED);
export const addMemberOngoing = createAction(ADD_MEMBER_ONGOING);
export const addMemberTimeout = createAction(ADD_MEMBER_TIMEOUT);
export const getMemberSuccess = createAction(MEMBER_LIST);
export const updateMemberSuccess = createAction(UPDATE_MEMBER_SUCCESS);
export const updateMemberFailed = createAction(UPDATE_MEMBER_FAILED);
export const deleteMemberSuccess = createAction(DELETE_MEMBER_SUCCCES);
export const deleteMemberFailed = createAction(DELETE_MEMBER_FAILED);


export const addMember = (data, history) => async (dispatch) => {
    dispatch(addMemberOngoing());
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    console.log('dari action Member 1', token);
    axios.post(MEMBER_URL, data, { headers: { Authorization: `Token ${token}` } })
        .then((res) => {
            console.log('Member data', res.data);
            dispatch(addMemberSuccess({ data: res.data }));
            setTimeout(() => dispatch(addMemberTimeout()), 3000);
            history.push('/member/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addMemberTimeout()), 3000);
        dispatch(addMemberFailed({ error }));
    });
};

export const getMember = () => async (dispatch) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios.get(MEMBER_URL, { headers: { Authorization: `Token ${token}` } }) 
        .then((res) => {
        dispatch(getMemberSuccess({ data: res.data }));
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addMemberTimeout()), 3000);
        dispatch(addMemberFailed({ error }));
    });
};

export const updateMember = (data, id, history) => async (dispatch) => {
    dispatch(addMemberOngoing());
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios.patch(`${MEMBER_URL}${id}/`, data, { headers: { Authorization: `Token ${token}` } })
    .then((res) => {
        setTimeout(() => {
            dispatch(addMemberTimeout());
        }, 3000);
        dispatch(updateMemberSuccess({ data: res.data }));
        history.push('/member/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addMemberTimeout()), 3000);
        dispatch(updateMemberFailed({ error }));
    });
};

export const deleteMember = (id, history) => async (dispatch) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    console.log('dari action Member 4', token);
    axios.delete(`${MEMBER_URL}${id}/`, { headers: { Authorization: `Token ${token}` } })
    .then(() => {
        dispatch(deleteMemberSuccess());
        history.push('/member/index');
    })
    .catch((err) => {
        const error = err.response.data;
        dispatch(deleteMemberFailed({ error }));
    });
};
