import { createAction } from 'redux-actions';
import axios from 'axios';

import {
    LOCALSTORAGE_TOKEN,
    ADD_USER_SUCCESS, ADD_USER_FAILED, ADD_USER_ONGOING, ADD_USER_TIMEOUT,
    USER_LIST,
    UPDATE_USER_SUCCESS, UPDATE_USER_FAILED,
    DELETE_USER_SUCCCES, DELETE_USER_FAILED,
} from '../types';
import { USER_URL } from '../../utils/endPoint';

export const addUserSuccess = createAction(ADD_USER_SUCCESS);
export const addUserFailed = createAction(ADD_USER_FAILED);
export const addUserOngoing = createAction(ADD_USER_ONGOING);
export const addUserTimeout = createAction(ADD_USER_TIMEOUT);
export const getUserSuccess = createAction(USER_LIST);
export const updateUserSuccess = createAction(UPDATE_USER_SUCCESS);
export const updateUserFailed = createAction(UPDATE_USER_FAILED);
export const deleteUserSuccess = createAction(DELETE_USER_SUCCCES);
export const deleteUserFailed = createAction(DELETE_USER_FAILED);


export const addUser = (data, history) => async (dispatch) => {
    dispatch(addUserOngoing());
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    console.log('dari action User 1', token);
    axios.post(USER_URL, data, { headers: { Authorization: `Token ${token}` } })
        .then((res) => {
            console.log('User data', res.data);
            dispatch(addUserSuccess({ data: res.data }));
            setTimeout(() => dispatch(addUserTimeout()), 3000);
            history.push('/user/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addUserTimeout()), 3000);
        dispatch(addUserFailed({ error }));
    });
};

export const getUser = () => async (dispatch) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios.get(USER_URL, { headers: { Authorization: `Token ${token}` } }) 
        .then((res) => {
        dispatch(getUserSuccess({ data: res.data }));
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addUserTimeout()), 3000);
        dispatch(addUserFailed({ error }));
    });
};

export const updateUser = (data, id, history) => async (dispatch) => {
    dispatch(addUserOngoing());
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios.patch(`${USER_URL}${id}/`, data, { headers: { Authorization: `Token ${token}` } })
    .then((res) => {
        setTimeout(() => {
            dispatch(addUserTimeout());
        }, 3000);
        dispatch(updateUserSuccess({ data: res.data }));
        history.push('/user/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addUserTimeout()), 3000);
        dispatch(updateUserFailed({ error }));
    });
};

export const deleteUser = (id, history) => async (dispatch) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    console.log('dari action User 4', token);
    axios.delete(`${USER_URL}${id}/`, { headers: { Authorization: `Token ${token}` } })
    .then(() => {
        dispatch(deleteUserSuccess());
        history.push('/user/index');
    })
    .catch((err) => {
        const error = err.response.data;
        dispatch(deleteUserFailed({ error }));
    });
};
