import { createAction } from 'redux-actions';
import axios from 'axios';
import {
    LOCALSTORAGE_TOKEN,
    ADD_CATEGORIES_FAILED, ADD_CATEGORIES_TIMEOUT,
    CATEGORIES_LIST
    ,
} from '../types';
import { SERVICECATEGORY_URL } from '../../utils/endPoint';


export const addCategoriesFailed = createAction(ADD_CATEGORIES_FAILED);
export const addCategoriesTimeout = createAction(ADD_CATEGORIES_TIMEOUT);
export const getCategoriesSuccess = createAction(CATEGORIES_LIST);


const token = localStorage.getItem(LOCALSTORAGE_TOKEN);

export const getCategories = () => async (dispatch) => {
    axios.get(SERVICECATEGORY_URL, { headers: { Authorization: `Token ${token}` } })
    .then((res) => {
        dispatch(getCategoriesSuccess({ data: res.data }));
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addCategoriesTimeout()), 3000);
        dispatch(addCategoriesFailed({ error }));
    });
};
