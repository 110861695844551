/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
 Card, CardBody, Col, Spinner, 
} from 'reactstrap';
import axios from 'axios';
import moment from 'moment/moment';
// import { WhatsappShareButton, WhatsappIcon } from 'react-share';
import { WhatsappShareButton, WhatsappIcon } from 'react-share';
import Calendar from './Calendar';
import { SCHEDULE_URL, SCHEDULE_URL_BYTRAINER } from '../../../../../utils/endPoint';
// import events from './events';


const BigCalendar = () => {
  const getUser = JSON.parse(localStorage.getItem('LOCALSTORAGE_MYUSER'));
  const getToken = localStorage.getItem('LOCALSTORAGE_TOKEN');
  const [schedule, setSchedule] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [myEvents, setEvents] = useState(events);

  // const handleSelectSlot = useCallback(
  //   ({ start, end }) => {
  //     const title = window.prompt('New Event name');
  //     if (title) {
  //       setEvents(prev => [...prev, { start, end, title }]);
  //     }
  //   },
  //   [setEvents],
  // );

  // const handleSelectEvent = useCallback(
  //   event => window.alert(event.title),
  //   [],
  // );
  // const isMonday = date => moment(date).isoWeekday() === 1;
  console.log(schedule);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      if (getUser.role !== 'Pelatih') {
        await axios.get(SCHEDULE_URL).then(({ data }) => {
         const formattedData = data.map(item => ({
           id: item.id,
           title: 
           item.horse_detail?.name ? item.horse_detail?.name 
           : item.type === 'Memanah' ? 'Memanah' : '-',
           // horse: item.horse_detail,
           // type: item.type,
           // trainer: item.horse_detail?.trainer_detail,
           start: new Date(`${item.date} ${item.start_time.replace(/-/g, '/')}`),
           end: new Date(`${item.date} ${item.end_time.replace(/-/g, '/')}`),
           priority:
             item.type === 'Berkuda'
               ? item.status === 'Pending'
                 ? 'kpending'
                 : item.status === 'Approved'
                 ? 'kapproved'
                 : 'rreschedule'
               : item.type === 'Memanah' ? item.status === 'Pending'
               ? 'ppending'
               : item.status === 'Approved'
               ? 'papproved'
               : 'preschedule' : null,
         }));
         // console.log(data);
         setSchedule(formattedData);
       });
      } else {
        await axios.get(SCHEDULE_URL_BYTRAINER, 
          { headers: { Authorization: `Token ${getToken}` } }).then(({ data }) => {
            console.log(data);
          const formattedData = data.map(item => ({
            id: item.id,
            title: item.horse_detail?.name
              ? item.horse_detail?.name
              : item.type === 'Memanah'
              ? 'Memanah'
              : '-',
            // horse: item.horse_detail,
            // type: item.type,
            // trainer: item.horse_detail?.trainer_detail,
            start: new Date(
              `${item.date} ${item.start_time.replace(/-/g, '/')}`,
            ),
            end: new Date(`${item.date} ${item.end_time.replace(/-/g, '/')}`),
            priority:
              item.type === 'Berkuda'
                ? item.status === 'Pending'
                  ? 'kpending'
                  : item.status === 'Approved'
                  ? 'kapproved'
                  : 'rreschedule'
                : item.type === 'Memanah'
                ? item.status === 'Pending'
                  ? 'ppending'
                  : item.status === 'Approved'
                  ? 'papproved'
                  : 'preschedule'
                : null,
          }));
          // console.log(data);
          setSchedule(formattedData);
        });
      }

      setLoading(false);
    };
    getData();
  }, []);

  // const startDate = moment()
  //   .startOf('week')
  //   .add(6, 'days')
  //   .format('DD MMMM YYYY');
  // const endDate = moment().endOf('week').add(6, 'days').format('DD MMMM YYYY');

  const startDate = moment()
    .startOf('week')
    .add(-1, 'weeks')
    .add(6, 'days')
    .format('DD MMMM YYYY');
  const endDate = moment()
    .endOf('week')
    .add(-1, 'weeks')
    .add(6, 'days')
    .format('DD MMMM YYYY');
  // console.log('asdadadsadasdadwdad', startDate, endDate);

  const shareUrl = 'https://www.ecosunnah.com/schedule';
  const title = `Assalamualaikum. Berikut jadwal Berkuda dan Memanah
Dari Tanggal ${startDate} - ${endDate}
Untuk lebih lanjut silahkan kunjungi link dibawah ini👇
`;
  return (
    <Col
      md={12}
      lg={12}
      xl={
        getUser.role === 'Kepala Stable'
        || getUser.role === 'Kasie Pelatih'
        || getUser.role === 'Pelatih'
          ? 12
          : 6
      }
    >
      <Card>
        <CardBody>
          <div>
            <div className="card__title" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h5 className="bold-text">event labels</h5>
              <WhatsappShareButton url={shareUrl} title={title}>
                {/* eslint-disable-next-line react/button-has-type */}
                <WhatsappIcon size={30} round={20} />
              </WhatsappShareButton>
            </div>
            <p className="typography-message">
              <span
                style={{ backgroundColor: '#CB1C8D' }}
                className="calendar-label calendar-label--red"
              />
              Berkuda Pending
            </p>
            <p className="typography-message">
              <span
                style={{ backgroundColor: '#70bbfd' }}
                className="calendar-label calendar-label--green"
              />
              Berkuda Approved
            </p>
            <p className="typography-message">
              <span
                style={{ backgroundColor: '#008068' }}
                className="calendar-label calendar-label--green"
              />
              Berkuda Reschedule
            </p>
          </div>
          <p className="typography-message">
            <span
              style={{ backgroundColor: '#332FD0' }}
              className="calendar-label calendar-label--blue"
            />
            Memanah Pending
          </p>
          <p className="typography-message">
            <span
              style={{ backgroundColor: '#10A19D' }}
              className="calendar-label calendar-label--blue"
            />
            Memanah Approved
          </p>
          <p className="typography-message">
            <span
              style={{ backgroundColor: '#6aff00' }}
              className="calendar-label calendar-label--blue"
            />
            Memanah Reschedule
          </p>
          {!loading ? (
            <Calendar
              events={schedule}
              // dayPropGetter={date => ({
              //     className: isMonday(date) ? 'bg-red-500 text-white' : '',
              //   })}
              
              // onSelectSlot={handleSelectSlot} 
              // onSelectEvent={handleSelectEvent}
            />
) : <Spinner />}
        </CardBody>
      </Card>
    </Col>
  );
};
export default BigCalendar;
