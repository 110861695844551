/* eslint-disable no-console */
import {
  RESETPASSWORD_SUCCESS,
  RESETPASSWORD_FAILED,
  RESETPASSWORDCONFIRM_SUCCESS,
  RESETPASSWORDCONFIRM_FAILED,
} from '../types';

const defaultState = {
  data: null,
  errorMessage: null,
  status: null,
};

const resetPasswordReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RESETPASSWORD_SUCCESS: {
      return {
        ...state, data: action.payload.data, status: 'success',
      };
    }
    case RESETPASSWORD_FAILED: {
      return {
        ...state, status: 'danger', error: action.payload.error,
      };
    }
    case RESETPASSWORDCONFIRM_SUCCESS: {
      return {
        ...state, data: action.payload.data, status: 'success',
      };
    }
    case RESETPASSWORDCONFIRM_FAILED: {
      return {
        ...state, status: 'danger', errorMessage: action.payload.errorMessage,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default resetPasswordReducer;
