/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const scheduleDetailHandler = (history, info) => {
  history.push({
    pathname: `/schedule/detail/${info.id}`, 
    state: { data: info },
  });
};


const CreateDataScheduleListTable = (dataAPI) => {
  const getUser = JSON.parse(localStorage.getItem('LOCALSTORAGE_MYUSER'));
  const rolePelatih = getUser.role === 'Pelatih';
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        accessor: 'id',
        Header: 'ID',
        width: 20,
        disableGlobalFilter: true,
      },
      {
        accessor: 'name',
        Header: 'Pelanggan',
      },
      {
        accessor: 'status',
        Header: 'Status',
      },
      {
        accessor: 'date',
        Header: 'Date',
      },
      {
        accessor: 'start',
        Header: 'start Time',
      },
      {
        accessor: 'end',
        Header: 'End Time',
      },
      {
        accessor: 'type',
        Header: 'Type',
      },
      {
        accessor: 'action',
        Header: 'Action',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
        // show: false,
      },
    ],
    [getUser],
  );

  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
        nama: '-',
        lokasi: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
            nama: '-',
            lokasi: '-',
          });
        } else {
          data.push({
            id: `${i + 1}`,
            name: dataAPI[i].user_detail?.fullname ?? '',
            status: dataAPI[i].status,
            date: dataAPI[i].date,
            start: `${dataAPI[i].start_time}`,
            end: `${dataAPI[i].end_time}`,
            type: dataAPI[i].type,
            action: [
              // eslint-disable-next-line no-loop-func
              // eslint-disable-next-line max-len
              (getUser.role !== 'Pelatih' && <Button key={i} size="sm" color="success" onClick={() => scheduleDetailHandler(history, dataAPI[i])}>Detail</Button>),
            ],
          });
        }
      }
    }
  };

  rows();
  const ScheduleListTableData = { 
    tableHeaderData: rolePelatih ? columns.filter(item => item.accessor !== 'action') : columns, 
    tableRowsData: data,
  };
  return ScheduleListTableData;
};

export default CreateDataScheduleListTable;
