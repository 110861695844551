import {
    ADD_PRODUCT_SUCCESS, ADD_PRODUCT_FAILED, ADD_PRODUCT_ONGOING, ADD_PRODUCT_TIMEOUT,
    PRODUCT_LIST,
    UPDATE_PRODUCT_SUCCESS, UPDATE_PRODUCT_FAILED,
} from '../types';

const defaultState = {
    data: '',
    updateData: '',
    isRegistrating: false,
    updateResponse: null,
    errorMessage: '',
    status: null,
    listData: [],
};


const productReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_PRODUCT_SUCCESS: {
            return {
                ...state,
                isRegistrating: false,
                data: 'success', 
            };
        }
        case ADD_PRODUCT_FAILED: {
            return {
                ...state, 
                isRegistrating: false,
                errorMessage: action.payload.error,
            };
        }
        case ADD_PRODUCT_ONGOING: {
            return {
                ...state,
                isRegistrating: false,
            };
        }
        case ADD_PRODUCT_TIMEOUT: {
            return {
                ...state,
                data: '', 
                errorMessage: '',
            }; 
        }
        case UPDATE_PRODUCT_SUCCESS: {
            return {
                ...state,
                isRegistrating: false,
                updateData: action.payload.updateDate,
                status: 'success',
            };
        }
        case UPDATE_PRODUCT_FAILED: {
            return {
                ...state,
                errorMessage: action.payload.errorMessage,
                status: 'danger',
            };
        }
        case PRODUCT_LIST: {
            return {
                ...state,
                listData: action.payload.data,
            };
        }
        default: {
            return {
              ...state,
            };
          }
    }
};

export default productReducer;
