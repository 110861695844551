/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const productDetailHandler = (history, info) => {
  history.push({
    pathname: `/community/detail/${info.id}`, 
    state: { data: info },
  });
};


const CreateDataOrderListTable = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        accessor: 'number',
        Header: 'No',
        width: 20,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        accessor: 'nama',
        Header: 'Community Name',
      },
      {
        accessor: 'lokasi',
        Header: 'Location',
      },
      {
        accessor: 'status',
        Header: 'Status',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );


  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
        nama: '-',
        lokasi: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
            nama: '-',
            lokasi: '-',
          });
        } else {
          data.push({
            id: dataAPI[i].id,
            number: `${i + 1}`,
            nama: dataAPI[i].nama,
            lokasi: dataAPI[i].lokasi,
            image: dataAPI[i].image,
            keterangan: dataAPI[i].keterangan,
            status: [
              // eslint-disable-next-line no-loop-func
              // eslint-disable-next-line max-len
              (<Button size="sm" color="success" onClick={() => productDetailHandler(history, dataAPI[i])}>Detail</Button>),
            ],
          });
        }
      }
    }
  };

  rows();
  const CommunityListTableData = { tableHeaderData: columns, tableRowsData: data };
  return CommunityListTableData;
};

export default CreateDataOrderListTable;
