/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import LogInForm from '../../../shared/components/loginForm/LogInForm';
import { authLogin } from '../../../redux/actions/authActions';

const onSubmit = (e, handleFunction, history) => {
  e.preventDefault();
  const data = new FormData();
  data.append('email', [e.target.email][0].value);
  data.append('password', [e.target.password][0].value);
  handleFunction(data, history);
};

const LogIn = ({
  authData, loginAction, isAuthenticating, errorAuth, history,
}) => (
  <div className="account account--not-photo">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">Welcome to
            <span className="account__logo"> Ghaida
              <span className="account__logo-accent">S</span>
            </span>
          </h3>
          <h4 className="account__subhead subhead">Smart Services Management</h4>
        </div>
        <Alert
          color={authData === 'success' ? 'success' : 'danger'}
          isOpen={!!errorAuth || authData === 'success'}
        >
          <p className="p-2 pl-3">{authData === 'success' ? 'Berhasil login' : errorAuth}</p>

        </Alert>
        <LogInForm isAuthenticating={isAuthenticating} handleSubmit={(e) => onSubmit(e, loginAction, history)} />
      </div>
    </div>
  </div>
);

const mapStateToProps = (state) => ({
    authData: state.auth.data,
    isAuthenticating: state.auth.isAuthenticating,
    errorAuth: state.auth.errorAuth,
});

const mapDispatchToProps = {
  loginAction: authLogin,
};

LogIn.defaultProps = {
  isAuthenticating: false,
};

LogIn.propTypes = {
  authData: PropTypes.string.isRequired,
  loginAction: PropTypes.func.isRequired,
  isAuthenticating: PropTypes.bool,
  errorAuth: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LogIn),
);
