export const BASE_URL = 'https://api.ghaidas.com/';
// export const BASE_URL = 'http://ec2-13-213-43-9.ap-southeast-1.compute.amazonaws.com/';
// export const BASE_URL = 'http://127.0.0.1:8000/';
export const API_URL = `${BASE_URL}api-ghaidas/`;
export const AUTH_URL = `${BASE_URL}auth-ghaidas/token/login`;
export const AUTH_USERSME_URL = `${BASE_URL}auth-ghaidas/users/me/`;

// reset password
export const RESETPASSWORD_URL = `${BASE_URL}auth-ghaidas/users/reset_password/`;
export const RESETPASSWORDCONFIRM_URL = `${BASE_URL}auth-ghaidas/users/reset_password_confirm/`;

// partner
export const PARTNER_URL = `${API_URL}partner/`;
export const SERVICECATEGORY_URL = `${API_URL}servicecategory/`;
export const PRODUCT_URL = `${API_URL}product/`;

// community
export const COMMUNITY_URL = `${API_URL}community/`;

// imageproduct
export const IMAGEPRODUCT_URL = `${API_URL}imageproduct/`;

// facility
export const FACILITY_URL = `${API_URL}facility/`;

// customuser
export const USER_URL = `${API_URL}user/`;

// Order
export const ORDER_URL = `${API_URL}order/`;

// horse
export const HORSE_URL = `${API_URL}horse/`;

// horse
export const CAGE_URL = `${API_URL}cage/`;

// booking
export const BOOKING_URL = `${API_URL}booking/`;

// customer
export const CUSTOMER_URL = `${API_URL}user/`;

// visiting
export const VISITING_URL = `${API_URL}quotaproduct/`;

// member
export const MEMBER_URL = `${API_URL}member/`;

// schedule
export const SCHEDULE_URL = `${API_URL}schedule/`;

// kbm
export const KBM_URL = `${API_URL}kbm/`;

export const SCHEDULE_URL_BYTRAINER = `${API_URL}schedulebytrainer/`;
