/* eslint-disable arrow-parens */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import ResetPasswordForm from '@/shared/components/reset_password/ResetPasswordForm';
import { postResetPassword } from '../../../redux/actions/resetPasswordActions';


const onSubmit = (e, handleFunction) => {
  e.preventDefault();
  const data = new FormData();
  data.append('email', [e.target.email][0].value);
  handleFunction(data);
};

const ResetPassword = ({
  status, errorMessage, resetPasswordAction,
}) => (
  <div className="account account--not-photo">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">Welcome to
            <span className="account__logo"> Ghaida
              <span className="account__logo-accent">S</span>
            </span>
          </h3>
          <h4 className="account__subhead subhead">Password reset</h4>
        </div>
        <ResetPasswordForm
          status={status}
          errorMessage={errorMessage}
          handleSubmit={(e) => onSubmit(e, resetPasswordAction)}
          form="reset_password_form"
        />
        <div className="account__have-account">
          <p>Remember your password?  <NavLink to="/">Login</NavLink></p>
        </div>
      </div>
    </div>
  </div>
  );

  ResetPassword.propTypes = {
    resetPasswordAction: PropTypes.func.isRequired,
    errorMessage: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  };
  
  const mapDispatchToProps = {
    resetPasswordAction: postResetPassword,
  };
  
  const mapStateToProps = (state) => {
    console.log('data', state.resetPassword);
    return ({
      data: state.resetPassword.data,
      errorMessage: state.resetPassword.errorMessage,
      status: state.resetPassword.status,
    });
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
