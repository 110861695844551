/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, CardBody, Col } from 'reactstrap';
import HorseAddForm from './HorseAddForm';
import { addHorse } from '../../../../redux/actions/horseActions';



const HorseCard = ({
  addHorseAction, history, errorMessage, status,
}) => ( 
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">Informasi Kuda</h5>
        </div>
        <HorseAddForm
          status={status}
          errorMessage={errorMessage}
          // handleSubmit={(e) => onSubmit(e, addHorseAction, history)}
          history={history}
          handleAction={addHorseAction}
        />
      </CardBody>
    </Card>
  </Col>
);

HorseCard.propTypes = {
  addHorseAction: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapDispatchToProps = {
  addHorseAction: addHorse,
};

const mapStateToProps = (state) => ({ 
  data: state.horse.data,
  isRegistrating: state.horse.isRegistrating,
  errorMessage: state.horse.errorMessage,
  status: state.horse.data,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HorseCard),
);
