/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import {
 Card, CardBody, Col, ButtonToolbar, 
} from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactTableBase from '@/shared/components/table/ReactTableBase';

const KbmListTable = ({ KbmListTableData }) => {
  const tableConfig = {
    isEditable: false,
    isSortable: true,
    isResizable: false,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Daftar KBM</h5>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Link
                className="btn btn-primary products-list__btn-add"
                to="/kbm/add-kbm"
              >
                KBM Baru
              </Link>
            </ButtonToolbar>
          </div>
          <ReactTableBase
            columns={KbmListTableData.tableHeaderData}
            data={KbmListTableData.tableRowsData}
            tableConfig={tableConfig}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

KbmListTable.propTypes = {
  KbmListTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default KbmListTable;
