import { createAction } from 'redux-actions';
import axios from 'axios';
import {
  LOCALSTORAGE_MYUSER,
  LOCALSTORAGE_TOKEN,
  UPDATEUSER_SUCCESS,
  UPDATEUSER_FAILED,
} from '../types';
import { USER_URL } from '../../utils/endPoint';

export const updateUserSuccess = createAction(UPDATEUSER_SUCCESS);
export const updateUserFailed = createAction(UPDATEUSER_FAILED);

const token = localStorage.getItem(LOCALSTORAGE_TOKEN);

export const updateUser = (data, id) => async (dispatch) => {
  // dispatch(RegisterRequest());
  axios.patch(`${USER_URL + id}/`, data, { headers: { Authorization: `Token ${token}` } })
    .then((res) => {
    // eslint-disable-next-line no-console
      console.log('UPDATE AKUN ACTION', res.data);
      dispatch(updateUserSuccess({ data: res.data }));
      localStorage.setItem(LOCALSTORAGE_MYUSER, JSON.stringify(res.data));
      setTimeout(() => window.location.reload(), 3000);
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.log('ERROR UPDATE USER', error.response);
      dispatch(updateUserFailed({ errorMessage: error }));
      // setTimeout(() => {
      //   dispatch(RegisterTimeoutAlert());
      // }, 5000);
    });
};
