import React from 'react';
import { Row } from 'reactstrap';
import ScheduleAddCard from '../../AddSchedule/components/ScheduleAddCard';
import BigCalendar from './components/BigCalendar';


const Calendar = () => {
  const getUser = JSON.parse(localStorage.getItem('LOCALSTORAGE_MYUSER'));
  return (
    <Row>
      <BigCalendar />
      {getUser.role === 'Kepala Stable'
      || getUser.role === 'Kasie Pelatih'
      || getUser.role === 'Pelatih' ? null : (
        <ScheduleAddCard />
      )}
    </Row>
  );
};



export default (Calendar);
