/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
 Container, Col, Row, Spinner, 
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CategoriesListTable from './components/CategoriesListTable';
import CreateTableData from './components/CreateData';
import { getCategories } from '../../../redux/actions/categoriesActions';

const Categories = ({ dispatch, listData, loading }) => {
  const categoriesListTableData = CreateTableData(listData);
  useEffect(() => {
    dispatch(getCategories());
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Daftar Kategori</h3>
          {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
            information
          </h3> */}
        </Col>
      </Row>
      <Row>{loading && <Spinner color="success" size="md" />}</Row>
      <Row>
        <CategoriesListTable categoriesListTableData={categoriesListTableData} />
      </Row>
    </Container>
  );
};

Categories.propTypes = {
  dispatch: PropTypes.func.isRequired,
  listData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    listData: state.categories.data,
    loading: state.categories.loading,
});

export default connect(mapStateToProps, null)(Categories);
