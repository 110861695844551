/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProductsListTable from './components/ProductsListTable';
import CreateTableData from './components/CreateData';
import { getProduct } from '../../../redux/actions/productActions';
import { LOCALSTORAGE_MYUSER } from '../../../redux/types';

const ProductsLists = ({ dispatch, listData }) => {
  const productListTableData = CreateTableData(listData.sort((a, b) => b.is_available - a.is_available));

  useEffect(() => {
    const localdata = JSON.parse(localStorage.getItem(LOCALSTORAGE_MYUSER));
    dispatch(getProduct(localdata.id));
  }, []);
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Daftar Produk</h3>
        </Col>
      </Row>
      <Row>
        <ProductsListTable productListTableData={productListTableData} />
      </Row>
    </Container>
  );
};

ProductsLists.propTypes = {
  dispatch: PropTypes.func.isRequired,
  listData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = (state) => {
  console.log(state);
  return (

  {
    listData: state.product.listData,
  }); 
};

export default connect(mapStateToProps, null)(ProductsLists);
