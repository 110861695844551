/* eslint-disable import/extensions */
import { createAction } from 'redux-actions';
import axios from 'axios';
import { AUTH_URL, AUTH_USERSME_URL } from '../../utils/endPoint';

import {
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_TIMEOUT_ALERT,
  LOCALSTORAGE_MYUSER,
  LOCALSTORAGE_TOKEN,
} from '../types';

export const authLoginSuccess = createAction(AUTH_LOGIN_SUCCESS);
export const authLoginFailure = createAction(AUTH_LOGIN_FAILURE);
export const authLoginRequest = createAction(AUTH_LOGIN_REQUEST);
export const authTimeoutAlert = createAction(AUTH_TIMEOUT_ALERT);

export const authLogin = (data, history) => async (dispatch) => {
  dispatch(authLoginRequest());
  axios.post(AUTH_URL, data)
    .then((res) => {
      let token = '';
      if (res.data.auth_token) {
        token = res.data.auth_token;
        localStorage.setItem(LOCALSTORAGE_TOKEN, token);
        console.log('token', token);
      }

      axios.get(AUTH_USERSME_URL, { headers: { Authorization: `Token ${token}` } }).then((userData) => {
        localStorage.setItem(LOCALSTORAGE_MYUSER, JSON.stringify(userData.data));
        setTimeout(() => {
          dispatch(authTimeoutAlert());
          if (userData.data.role === 'Admin') {
            history.push('/dashboard');
          } else {
            history.push('/dashboard');
          }
        }, 2000);
        dispatch(authLoginSuccess({ token }));
        setTimeout(() => dispatch(authTimeoutAlert()), 3000);
      }).catch((err) => {
        dispatch(authLoginFailure({ err }));
      });
    })
   .catch((err) => {
      let error = err.response.data;
      if (error.non_field_errors) {
        error = 'Gagal login, email dan password yang dimasukkan salah';
      }
      setTimeout(() => dispatch(authTimeoutAlert()), 3000);
      dispatch(authLoginFailure({ error }));
    });
};
