/* eslint-disable default-case */
import {
    ADD_USER_SUCCESS, ADD_USER_FAILED, ADD_USER_ONGOING, ADD_USER_TIMEOUT,
    USER_LIST,
    UPDATE_USER_SUCCESS, UPDATE_USER_FAILED,
} from '../types';

const defaultState = {
    data: '',
    updateData: '',
    isRegistrating: false,
    updateResponse: null,
    errorMessage: '',
    status: null,
    listData: [],
};

const userReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_USER_SUCCESS: {
            return {
                ...state,
                isRegistrating: false,
                data: 'success', 
            };
        }
        case ADD_USER_FAILED: {
            return {
                ...state, 
                isRegistrating: false,
                errorMessage: action.payload.error,
            };
        }
        case ADD_USER_ONGOING: {
            return {
                ...state,
                isRegistrating: false,
            };
        }
        case ADD_USER_TIMEOUT: {
            return {
                ...state,
                data: '', 
                errorMessage: '',
            }; 
        }
        case UPDATE_USER_SUCCESS: {
            return {
                ...state,
                isRegistrating: false,
                updateData: action.payload.data,
                status: 'success',
                // updateResponse: true,
            };
        }
        case UPDATE_USER_FAILED: {
            return {
                ...state,
                errorMessage: action.payload.errorMessage,
                status: 'danger',
            };
        }
        case USER_LIST: {
            return {
                ...state,
                listData: action.payload.data,
            };
        }
        default: {
            return {
              ...state,
            };
          }
    }
};

export default userReducer;
