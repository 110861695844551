/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
 ButtonToolbar, Col, Row, Container, 
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { useHistory } from 'react-router-dom';
import renderFileInputField from '@/shared/components/form/FileInput';
import axios from 'axios';
import { PARTNER_URL } from '../../../../utils/endPoint';
import { LOCALSTORAGE_TOKEN } from '../../../../redux/types';

const DetailPartners = (stateOri) => {
  const history = useHistory();
  const [isEditable, setEditable] = useState(false);
  const { dispatch } = stateOri;
  const { location } = history;
  const { state } = location;
  const { data } = state;
  const { initialize } = stateOri;
  const HandleSubmit = (e) => {
    e.preventDefault();
    const updatedData = new FormData();
    updatedData.append('name', [e.target.name][0].value);
    updatedData.append('order_display', [e.target.order_display][0].value);
    if ([e.target.image][0].files[0]) {
      updatedData.append('image', [e.target.image][0].files[0]);
    } else if (data.image && data.image.file) {
      updatedData.append('image', data.image.file);
    }
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios
      .patch(`${PARTNER_URL + data.id}/`, updatedData, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => {
        history.push('/partnership/index');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    initialize(data);
  }, []);

  return (
    <Container>
      <form className="form form--horizontal" onSubmit={HandleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Upload icon</span>
          <div className="form__form-group-field">
            <Field name="image" required component={renderFileInputField} />
            <span>
              {data.image ? (
                <a className="ml-2" target="blank" href={data.image}>
                  {data.image.split('?')[0]}
                </a>
              ) : (
                'Tidak Ada'
              )}
            </span>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Nama Mitra</span>
          <div className="form__form-group-field">
            {/* <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div> */}
            <Field
              name="name"
              component="input"
              type="text"
              required
              placeholder="Nama Mitra"
              disabled={!isEditable}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Urutan</span>
          <div className="form__form-group-field">
            <Field
              name="order_display"
              component="input"
              type="text"
              required
              placeholder="Urutan"
              disabled={!isEditable}
            />
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar">
          <Row className="mt-4">
            <Col md={12}>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setEditable(!isEditable)}
              >
                {' '}
                {!isEditable ? 'Edit' : 'Cancel'}
              </button>
              {isEditable && (
                <button type="submit" className="btn btn-primary">
                  {' '}
                  Update
                </button>
              )}
              {isEditable === false && (
                <button
                  type="button"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                    axios.delete(`${PARTNER_URL + data.id}/`, { headers: { Authorization: `Token ${token}` } })
                    .then(() => {
                        history.push('/partnership/index');
                    })
                    .catch((err) => {
                       console.log(err);
                    });
                  }}
                  className="btn btn-danger"
                >
                  {' '}
                  Delete
                </button>
              )}
            </Col>
          </Row>
        </ButtonToolbar>
      </form>
    </Container>
  );
};


export default (
  reduxForm({
    form: 'partner_edit_form', // a unique identifier for this form
    enableReinitialize: true,
  })(DetailPartners));
