/* eslint-disable no-sequences */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
 Button, ButtonToolbar, Card, CardBody, Col, 
//  Alert
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '@/shared/components/form/Select';
import { connect } from 'react-redux';
import axios from 'axios';
import { getUser } from '../../../../redux/actions/userActions';
import { getProduct } from '../../../../redux/actions/productActions';
import { LOCALSTORAGE_TOKEN } from '../../../../redux/types';



const VisitingAddForm = ({
  // errorMessage,
  handleSubmit,
  // status,
  dispatch,
}) => {
  const [product, setProduct] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    dispatch(getUser());
    dispatch(getProduct());
    axios.get('https://api.ghaidas.com/api-ghaidas/availableproduct/', 
    { headers: { Authorization: `Token ${token}` } }) 
    .then((res) => {
      const data = res.data.map(e => ({ value: `${e.id}`, label: e.name, pax: e.price_idr }));
      // console.log(res);
      // console.log(res.data);
      setProduct(data);
      // setIdrProduct(res.data.map(item => item));
      // console.log(data);
      // console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
  }, [dispatch]);

  return (
    <Col xs={12} md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form product-edit" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">Date</span>
              <div className="form__form-group-field">
                <Field
                  name="date"
                  component="input"
                  type="date"
                  placeholder="Date"
                  required
                />
              </div>
            </div>
            <div className="form__form-group-id-category">
              <div className="form__form-group">
                <span className="form__form-group-label">Produk</span>
                <div className="form__form-group-field">
                  <Field
                    name="product"
                    component={renderSelectField}
                    type="text"
                    options={product || [{ value: 'other', label: 'Other' }]}
                    placeholder="Pilih Produk"
                    // onChange={(e) => {
                    //   setPaxx(e.pax);
                    //   setPilihProduct(e.label);
                    // }}
                    required
                  />
                </div>
              </div>
            </div>
            {/* <div className="form__form-group">
              <span className="form__form-group-label">Product ID</span>
              <div className="form__form-group-field">
                <Field
                  name="product_id"
                  component="input"
                  type="text"
                  placeholder="Product ID"
                  required
                />
              </div>
            </div> */}
            <div className="form__form-group">
              <span className="form__form-group-label">Seat Available</span>
              <div className="form__form-group-field">
                <Field
                  name="seat_available"
                  component="input"
                  type="text"
                  placeholder="Seat Available"
                  required
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Seat Sale</span>
              <div className="form__form-group-field">
                <Field
                  name="seat_sale"
                  component="input"
                  type="text"
                  placeholder="Seat Sale"
                  defaultValue={0}
                />
              </div>
            </div>
           
            {/* <div className="mb-3">
              <Alert
                color={status === 'success' ? 'success' : 'danger'}
                isOpen={!!errorMessage || status === 'success'}
              >
                <p className="p-2 pl-3">
                  {status === 'success'
                    ? 'Visiting Successfully Added'
                    : errorMessage}
                </p>
              </Alert>
            </div> */}
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" size="sm" type="submit">
                Submit
              </Button>
              <Button type="button" size="sm" href="/visiting/index">
                Cancel
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

// const mapStateToProps = (state) => {
//   const user = state.user.listData
//     ? state.user.listData.map(e => ({ value: `${e.id}`, label: e.fullname }))
//     : [];
//     const product = state.product.listData
//     ? state.product.listData.map(e => ({ value: `${e.id}`, label: e.name }))
//     : [];
//   return {
//     listUser: user,
//     listProduct: product,
//   };
// };
VisitingAddForm.propTypes = {
  // errorMessage: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  // status: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

VisitingAddForm.defaultProps = {
  // errorMessage: '',
};

export default connect(
  // mapStateToProps,
  null,
)(
  reduxForm({
    form: 'visiting_add_form', // a unique identifier for this form
  })(VisitingAddForm),
);
