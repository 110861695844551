import React from 'react';
import { Switch } from 'react-router-dom';
import CommunityList from '../../../Community/CommunityList';
import AddCommunity from '../../../Community/AddCommunity';
import DetailCommunity from '../../../Community/Detail';
import PrivateRoute from '../../../../shared/components/PrivateRoute';

export default () => (
  <Switch>
    <PrivateRoute path="/community/index" component={CommunityList} />
    <PrivateRoute path="/community/add-community" component={AddCommunity} />
    <PrivateRoute path="/community/detail/:id" component={DetailCommunity} />
  </Switch>
);
