import React from 'react';
import { Switch } from 'react-router-dom';
import CageList from '../../../Cage/CageList';
// import DetailCategories from '../../../ServiceCategory/DetailCategory';
import PrivateRoute from '../../../../shared/components/PrivateRoute';
import AddCage from '../../../Cage/AddCage';
import CageDetail from '../../../Cage/Detail';

export default () => (
  <Switch>
    <PrivateRoute path="/cage/index" component={CageList} />
    <PrivateRoute path="/cage/add-cage" component={AddCage} />
    <PrivateRoute path="/cage/detail/:id" component={CageDetail} />
    {/* <PrivateRoute
      path="/cage/detail-cage/:id"
      component={DetailCage}
    /> */}
  </Switch>
);
