import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_REQUEST,
  AUTH_TIMEOUT_ALERT,
} from '../types';

const defaultState = {
  data: '',
  fakedata: '',
  isAuthenticating: false,
  errorAuth: '',
};

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case AUTH_LOGIN_SUCCESS: {
      return {
       ...state,
        isAuthenticating: false,
       token: action.payload.token,
        data: 'success',
      };
    }
    case AUTH_LOGIN_FAILURE: {
      return {
        ...state,
        isAuthenticating: false,
        errorAuth: action.payload.error,
      };
    }
    case AUTH_LOGIN_REQUEST: {
      return {
        ...state,
        isAuthenticating: true,
        errorAuth: '',
        data: '',
      };
    }
    case AUTH_TIMEOUT_ALERT: {
      return {
        ...state,
        isAuthenticating: false,
        errorAuth: '',
        data: '',
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default authReducer;
