/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import {
 Button, ButtonToolbar, Card, CardBody, Col, Spinner,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderFileInputField from '@/shared/components/form/FileInput';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import { LOCALSTORAGE_TOKEN } from '../../../../../../../redux/types';
import { PRODUCT_URL } from '../../../../../../../utils/endPoint';

const PartnerAddForm = () => {
  const params = useParams();
  const history = useHistory();
  const [newData, setNewData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const res = async () => {
      const dataReal = await axios.get(
        PRODUCT_URL + params.id,
      );
      setNewData(dataReal.data);
      console.log(dataReal.data);
    };
    res();
  }, []);

  const handleSubmit = (e) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    e.preventDefault();
    const data = new FormData();
    data.append('image', [e.target.icon][0].files[0]);
    data.append('product', params.id);
    setLoading(true);
    axios.post('https://api.ghaidas.com/api-ghaidas/imageproduct/', data, 
    { headers: { Authorization: `Token ${token}` } }).then((res) => {
      console.log(res);
      setLoading(false);
      history.push({
        pathname: `/product/detail-product/${newData.id}`,
        state: { data: newData },
      });
    }).catch((err) => {
      console.log(err);
    });
  };

  return (

    <Col xs={12} md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form form--horizontal" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">Upload Gambar</span>
              <div className="form__form-group-field">
                <Field
                  name="icon"
                  required
                  component={renderFileInputField}
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit" disabled={!!loading}>{loading ? <Spinner size="sm" color="primary" /> : 'Submit'}</Button>
              <Button type="button" href="/product/facility-product-list">
                Cancel
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
); 
};

export default reduxForm({
  form: 'facility_add_form', // a unique identifier for this form
})(PartnerAddForm);
