/* eslint-disable arrow-parens */
import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import CustomerAddForm from './CustomerAddForm';
import { CUSTOMER_URL } from '../../../../utils/endPoint';
import { LOCALSTORAGE_TOKEN } from '../../../../redux/types';




const CustomerCard = () => {
  const history = useHistory();
  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('fullname', [e.target.name][0].value);
    data.append('email', [e.target.email][0].value);
    data.append('phone', [e.target.phone][0].value);
    data.append('role', [e.target.role][0].value);
    data.append('password', [e.target.password][0].value);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios
    .post(CUSTOMER_URL, data, { headers: { Authorization: `Token ${token}` } })
    .then((res) => {
      console.log(res);
      history.push('/customer/index');
    })
    .catch((error) => {
      console.log(error);
    });
  };
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Informasi Pelanggan</h5>
          </div>
          <CustomerAddForm
            handleSubmit={(e) => onSubmit(e)}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

export default (CustomerCard);
