/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const productDetailHandler = (history, info) => {
  history.push({
    pathname: `/product/detail-product/${info.id}`, 
    state: { data: info },
  });
};

const CreateDataProductListTable = (dataAPI) => {
  console.log(dataAPI);
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 80,
        disableGlobalFilter: true,
        disableSortBy: true,
        
      },
      {
        Header: 'Tanggal',
        accessor: 'date',
        disableSortBy: true,
      },
      {
        Header: 'Nama',
        accessor: 'name',
        disableSortBy: true,
      },
      {
        Header: 'Mitra',
        accessor: 'partner_detail',
        disableSortBy: false,
      },
      {
        Header: 'Kategori',
        accessor: 'service_category_id',
        disableSortBy: false,
      },
      {
        Header: 'Unit',
        accessor: 'unit',
        disableSortBy: false,
      },
      {
        Header: 'Harga',
        accessor: 'price_idr',
      },
      {
        Header: 'Kesediaan',
        accessor: 'available',
      },
      {
        accessor: 'status',
        Header: 'Status',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        id: '-',
        name: '-',
        partner_detail: '_',
        service_category_id: '_',
        unit: '_',
        price_idr: '',     
        status: '_',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
            name: '-',
            partner_detail: '_',
            service_category_id: '_',
            unit: '_',
            price_idr: '',     
            status: '_',
          });
        } else {
          data.push({
            id: dataAPI[i].id,
            number: `${i + 1}`,
            name: dataAPI[i].name,
            date: dataAPI[i].created_at,
            partner_detail: `${dataAPI[i]?.partner_detail?.name ?? ''}`,
            service_category_id: `${dataAPI[i].service_category_detail.name}`,
            description: dataAPI[i].description,
            price_idr: `${dataAPI[i].price_idr}`,
            available: dataAPI[i].is_available ? 'Tersedia' : 'Tidak tersedia',
            unit: dataAPI[i].unit,
            location: dataAPI[i].location,
            valid_until: dataAPI[i].valid_until,
            thumbnail: dataAPI[i].thumbnail,
            image_product_detail: dataAPI[i].image_product_detail,
            facility_detail: dataAPI[i].facility_detail,
            status: [
              // eslint-disable-next-line no-loop-func
              // eslint-disable-next-line max-len
              (<Button key={i} size="sm" color="success" onClick={() => productDetailHandler(history, dataAPI[i])}>Detail</Button>),
            ],
          });
        }
      }
    }
  };

  rows();
  const productListTableData = { tableHeaderData: columns, tableRowsData: data };
  return productListTableData;
};

export default CreateDataProductListTable;
