import { createAction } from 'redux-actions';
import axios from 'axios';
import {
    LOCALSTORAGE_TOKEN,
    ADD_PRODUCT_SUCCESS, ADD_PRODUCT_FAILED, ADD_PRODUCT_ONGOING, ADD_PRODUCT_TIMEOUT,
    PRODUCT_LIST,
    UPDATE_PRODUCT_SUCCESS, UPDATE_PRODUCT_FAILED,
    DELETE_PRODUCT_SUCCCES, DELETE_PRODUCT_FAILED,
} from '../types';
import { PRODUCT_URL } from '../../utils/endPoint';

export const addProductSuccess = createAction(ADD_PRODUCT_SUCCESS);
export const addProductFailed = createAction(ADD_PRODUCT_FAILED);
export const addProductOngoing = createAction(ADD_PRODUCT_ONGOING);
export const addProductTimeout = createAction(ADD_PRODUCT_TIMEOUT);
export const getProductSuccess = createAction(PRODUCT_LIST);
export const updateProductSuccess = createAction(UPDATE_PRODUCT_SUCCESS);
export const updateProductFailed = createAction(UPDATE_PRODUCT_FAILED);
export const deleteProductSuccess = createAction(DELETE_PRODUCT_SUCCCES);
export const deleteProductFailed = createAction(DELETE_PRODUCT_FAILED);

const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
export const addProduct = (data, history) => async (dispatch) => {
    dispatch(addProductOngoing());
    axios.post(PRODUCT_URL, data, { headers: { Authorization: `Token ${token}` } })
        .then((res) => {
            dispatch(addProductSuccess({ data: res.data }));
            setTimeout(() => dispatch(addProductTimeout()), 3000);
            history.push('/product/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addProductTimeout()), 3000);
        dispatch(addProductFailed({ error }));
    });
};

export const getProduct = () => async (dispatch) => {
    axios.get(PRODUCT_URL, { headers: { Authorization: `Token ${token}` } }) 
        .then((res) => {
        dispatch(getProductSuccess({ data: res.data }));
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addProductTimeout()), 3000);
        dispatch(addProductFailed({ error }));
    });
};

export const updateProduct = (data, id, history) => async (dispatch) => {
    dispatch(addProductOngoing());

    console.log('dari action Product 3', token, id, data);
    axios.patch(`${PRODUCT_URL + id}/`, data, { headers: { Authorization: `Token ${token}` } })
    .then((res) => {
        setTimeout(() => {
            dispatch(addProductTimeout());
        }, 3000);
        dispatch(updateProductSuccess({ data: res.data }));
        history.push('/product/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addProductTimeout()), 3000);
        dispatch(updateProductFailed({ error }));
    });
};

export const deleteProduct = (id, history) => async (dispatch) => {
    axios.delete(`${PRODUCT_URL + id}/`, { headers: { Authorization: `Token ${token}` } })
    .then(() => {
        dispatch(deleteProductSuccess());
        history.push('/Product/index');
    })
    .catch((err) => {
        const error = err.response.data;
        dispatch(deleteProductFailed({ error }));
    });
};

