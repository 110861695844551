/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const customerDetailHandler = (history, info) => {
  history.push({
    pathname: `/customer/detail/${info.id}`, 
    state: { data: info },
  });
};


const CreateDataCustomerListTable = (dataAPI) => {
  console.log(dataAPI);
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        accessor: 'id',
        Header: 'No',
        width: 20,
        // disableGlobalFilter: false,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        accessor: 'name',
        Header: 'Nama',
      },
      {
        accessor: 'email',
        Header: 'Email',
      },
      {
        accessor: 'phone',
        Header: 'No. HP',
      },
      {
        accessor: 'role',
        Header: 'Role',
      },
      // {
      //   accessor: 'password',
      //   Header: 'Password',
      // },
      {
        accessor: 'action',
        Header: 'Action',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ],
    [],
  );


  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
        nama: '-',
        lokasi: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
            nama: '-',
            lokasi: '-',
          });
        } else {
          data.push({
            id: `${i + 1}`,
            name: dataAPI[i].fullname,
            email: dataAPI[i].email,
            phone: dataAPI[i].phone,
            role: dataAPI[i].role,
            // password: dataAPI[i].password,
           

            action: [
              // eslint-disable-next-line no-loop-func
              // eslint-disable-next-line max-len
              (<Button key={i} size="sm" color="success" onClick={() => customerDetailHandler(history, dataAPI[i])}>Detail</Button>),
            ],
          });
        }
      }
    }
  };

  rows();
  const CustomerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return CustomerListTableData;
};

export default CreateDataCustomerListTable;
