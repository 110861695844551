/* eslint-disable default-case */
import {
    ADD_MEMBER_SUCCESS, ADD_MEMBER_FAILED, ADD_MEMBER_ONGOING, ADD_MEMBER_TIMEOUT,
    MEMBER_LIST,
    UPDATE_MEMBER_SUCCESS, UPDATE_MEMBER_FAILED,
} from '../types';

const defaultState = {
    data: '',
    updateData: '',
    isRegistrating: false,
    updateResponse: null,
    errorMessage: '',
    status: null,
    listData: [],
};

const memberReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_MEMBER_SUCCESS: {
            return {
                ...state,
                isRegistrating: false,
                data: 'success', 
            };
        }
        case ADD_MEMBER_FAILED: {
            return {
                ...state, 
                isRegistrating: false,
                errorMessage: action.payload.error,
            };
        }
        case ADD_MEMBER_ONGOING: {
            return {
                ...state,
                isRegistrating: false,
            };
        }
        case ADD_MEMBER_TIMEOUT: {
            return {
                ...state,
                data: '', 
                errorMessage: '',
            }; 
        }
        case UPDATE_MEMBER_SUCCESS: {
            return {
                ...state,
                isRegistrating: false,
                updateData: action.payload.data,
                status: 'success',
                // updateResponse: true,
            };
        }
        case UPDATE_MEMBER_FAILED: {
            return {
                ...state,
                errorMessage: action.payload.errorMessage,
                status: 'danger',
            };
        }
        case MEMBER_LIST: {
            return {
                ...state,
                listData: action.payload.data,
            };
        }
        default: {
            return {
              ...state,
            };
          }
    }
};

export default memberReducer;
