/* eslint-disable arrow-body-style */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
 Container, Col, Row, Spinner, 
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PartnerListTable from './components/PartnerListTable';
import CreateTableData from './components/CreateData';
import { getPartner } from '../../../redux/actions/partnerActions';

const Partners = ({ dispatch, listData, loading }) => {
  useEffect(() => {
    dispatch(getPartner());
  }, []);
  const partnerListTableData = CreateTableData(listData);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Daftar Mitra</h3>
            
        </Col>
      </Row>
      <Row>{loading && <Spinner color="success" size="md" />}</Row>
      <Row>
        <PartnerListTable partnerListTableData={partnerListTableData} />
      </Row>
    </Container>
  );
};

Partners.propTypes = {
  dispatch: PropTypes.func.isRequired,
  listData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    listData: state.partner.data,
    loading: state.partner.loading,
  };
};

export default connect(mapStateToProps, null)(Partners);
