import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Card, Col, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import ProfileSettings from './ProfileSettings';
import { LOCALSTORAGE_MYUSER } from '../../../../redux/types';
import { updateUser } from '../../../../redux/actions/registrationUserActions';

const onSubmit = (e, handleFuction, history) => {
  e.preventDefault();
  const data = new FormData();
  const localdata = JSON.parse(localStorage.getItem(LOCALSTORAGE_MYUSER));
  data.append('email', [e.target.email][0].value);
  data.append('fullname', [e.target.fullname][0].value);
  data.append('phone', [e.target.phone][0].value);
  if ([e.target.photo][0].files[0]) {
    data.append('photo', [e.target.photo][0].files[0]);
  }
  handleFuction(data, localdata.id, history);
};

const ProfileTabs = ({
  updateUserAction, status, errorMessage,
}) => {
  const [activeTab, setActiveTab] = useState('1');
  const [myUser, setMyUser] = useState(null);

  useEffect(() => {
    const localdata = JSON.parse(localStorage.getItem(LOCALSTORAGE_MYUSER));
    setMyUser(localdata);
  }, []);

  const handleToggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <Col md={12} lg={12} xl={6}>
      <Card>
        <div className="profile__card tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => handleToggle('1')}
                >
                  Settings
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <ProfileSettings
                  status={status}
                  errorMessage={errorMessage}
                  initialValues={myUser}
                  dataUser={myUser}
                  handleSubmit={e => onSubmit(e, updateUserAction)}
                />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Card>
    </Col>
  );
};



ProfileTabs.propTypes = {
  status: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  updateUserAction: PropTypes.func.isRequired,
  // history: PropTypes.shape({
  //   push: PropTypes.func,
  // }).isRequired,
};

const mapDispatchToProps = {
  updateUserAction: updateUser,
};

const mapStateToProps = state => ({
  status: state.registrationUser.status,
  errorMessage: state.registrationUser.errorMessage,
});


export default connect(mapStateToProps, mapDispatchToProps)(ProfileTabs);
