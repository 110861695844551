/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
 Container, ButtonToolbar, Row, Col, Spinner,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import renderSelectField from '@/shared/components/form/Select';
import axios from 'axios';
import { getUser } from '../../../../redux/actions/userActions';
import { VISITING_URL } from '../../../../utils/endPoint';
import { LOCALSTORAGE_TOKEN } from '../../../../redux/types';
import { deleteVisiting } from '../../../../redux/actions/visitingActions';

const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
const VisitingForm = (stateOri) => {
    const [isEditable, setEditable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState([]);
    const { id } = useParams();
    const history = useHistory();
    const { dispatch } = stateOri;
    const { location } = history;
    const { state } = location;
    const { data } = state;
    const { initialize } = stateOri;
    // const { listProduct } = stateOri; 

    useEffect(() => {
      initialize({
 ...data, date: data?.date, product_id: data?.product_id, seat_available: data?.seat_available, seat_sale: data?.seat_sale, product: { value: data.product_id, label: data?.product_detail?.name },
});
    }, []);

    useEffect(() => {
      const Token = localStorage.getItem(LOCALSTORAGE_TOKEN);
      dispatch(getUser());
      axios.get('https://api.ghaidas.com/api-ghaidas/availableproduct/', 
    { headers: { Authorization: `Token ${token}` } }) 
    .then((res) => {
      const data1 = res.data.map(e => ({ value: `${e.id}`, label: e.name, pax: e.price_idr }));
      // console.log(res);
      // console.log(res.data);
      setProduct(data1);
      // setIdrProduct(res.data.map(item => item));
      // console.log(data);
      // console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
    }, [dispatch]);
    // console.log(data?.seat_sale);
    // console.log(data);

    return (
      <Container>
        <form 
          className="form product-edit"
          onSubmit={(e) => {
            e.preventDefault();
            const dataUpdate = new FormData();
            dataUpdate.append('date', [e.target.date][0].value);
            dataUpdate.append('product_id', [e.target.product][0].value);
            dataUpdate.append('product', [e.target.product][0].value);
            dataUpdate.append('seat_available', [e.target.seat_available][0].value);
            dataUpdate.append('seat_sale', [e.target.seat_sale][0].value);
            
            const updateDataVisiting = async () => {
              setLoading(true);
              try {
                const res = await axios.patch(`${VISITING_URL}${id}/`, dataUpdate, {
                  headers: {
                    Authorization: `Token ${token}`,
                  },
                });
                console.log(res);
                setTimeout(() => {
                  setLoading(false);
                  history.push('/visiting/index');
                }, 1000);
              } catch (err) {
                console.log(err.response);
              }
            };
            updateDataVisiting();
          }}
        >
          
          <div className="form__form-group">
            <span className="form__form-group-label">Date</span>
            <div className="form__form-group-field">
              <Field
                name="date"
                component="input"
                type="date"
                required
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className="form__form-group-id-category">
            <div className="form__form-group">
              <span className="form__form-group-label">Product</span>
              <div className="form__form-group-field">
                <Field 
                  name="product"
                  component={renderSelectField}
                  type="text"
                  required
                  options={product || [{ value: 'other', label: 'Other' }]}
                  placeholder="Pilih Produk"
                  disabled={!isEditable}
                />
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Seat Available</span>
            <div className="form__form-group-field">
              <Field
                name="seat_available"
                component="input"
                type="text"
                required
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Seat Sale</span>
            <div className="form__form-group-field">
              <Field
                name="seat_sale"
                component="input"
                type="text"
                required
                disabled={!isEditable}
              />
            </div>
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Row className="mt-4">
              <Col md={12}>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setEditable(!isEditable)}
                >
                  {!isEditable ? 'Edit' : 'Cancel'}
                </button>
                {isEditable && (
                <button type="submit" className="btn btn-primary">
                  {
                    loading ? <Spinner size="sm">Loading...</Spinner> : 'Update'
}
                </button>
              )}
                {isEditable === false && (
                <button
                  type="button"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(deleteVisiting(data.id, history));
                  }}
                  className="btn btn-danger"
                >
                  {' '}
                  Delete
                </button>
              )}
              </Col>
            </Row>
          </ButtonToolbar>
        </form>       
      </Container>
    );
};
 

export default connect(
// bind account loading action creator
)(reduxForm({
form: 'visiting_edit_form', // a unique identifier for this form
enableReinitialize: true,
})(VisitingForm));
