import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';
import { renderComponentField } from '@/shared/components/form/FormField';

const DateTimePickerField = ({
 onChange, defaultValue = null, disabled = false, placeholder = '', 
}) => {
  const [startDate, setStartDate] = useState(defaultValue);
  const handleChange = (date) => {
    setStartDate(date);
    onChange(date);
  };

  return (
    <div className="date-picker">
      <DatePicker
        timeFormat="HH:mm"
        disabled={disabled}
        className="form__form-group-datepicker"
        selected={startDate}
        onChange={handleChange}
        showTimeSelect
        dateFormat="MMMM d, yyyy h:mm aa"
        dropDownMode="select"
        withPortal={isMobileOnly}
        placeholderText={placeholder}
      />
    </div>
  );
};

DateTimePickerField.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  defaultValue: PropTypes.instanceOf(Date).isRequired,
};

export default renderComponentField(DateTimePickerField);
