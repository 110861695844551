import React from 'react';
import { Switch } from 'react-router-dom';
// import DetailCategories from '../../../ServiceCategory/DetailCategory';
import PrivateRoute from '../../../../shared/components/PrivateRoute';
import CustomerList from '../../../Customer/CustomerList';

import AddCustomer from '../../../Customer/AddCustomer';
import CustomerDetail from '../../../Customer/Detail';

export default () => (
  <Switch>
    <PrivateRoute path="/customer/index" component={CustomerList} />
    <PrivateRoute path="/customer/add-customer" component={AddCustomer} />
    <PrivateRoute path="/customer/detail/:id" component={CustomerDetail} />

  </Switch>
);
