/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import TransactionListTable from './components/TransactionListTable';
import CreateTableData from './components/CreateData';
import { getBooking } from '../../../redux/actions/bookingActions';
import { LOCALSTORAGE_MYUSER, LOCALSTORAGE_TOKEN } from '../../../redux/types';
import { ORDER_URL } from '../../../utils/endPoint';

const TransactionList = ({ dispatch, listData }) => {
  const [newData, setNewData] = useState([]);
  const TransactionListTableData = CreateTableData(newData);
  // const []
  // console.log(listData);

  useEffect(() => {
    // const localdata = JSON.parse(localStorage.getItem(LOCALSTORAGE_MYUSER));
    // dispatch(getBooking(localdata.id));
    // const localdata = JSON.parse(localStorage.getItem(LOCALSTORAGE_MYUSER));
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios
      .get(ORDER_URL, { headers: { Authorization: `Token ${token}` } })
      .then((res) => {
        console.log('asdasdsda', res.data);
        setNewData(res.data);
      });
  }, []);
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Daftar Transaksi</h3>
        </Col>
      </Row>
      <Row>
        <TransactionListTable TransactionListTableData={TransactionListTableData} />
      </Row>
    </Container>
  );
};

TransactionList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  listData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = state => ({
    listData: state.booking.listData,
  });

export default connect(mapStateToProps, null)(TransactionList);
