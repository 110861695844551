import React from 'react';
import { Switch } from 'react-router-dom';
import Categories from '../../../ServiceCategory/Category';
import AddCategories from '../../../ServiceCategory/AddCategory';
import DetailCategories from '../../../ServiceCategory/Detail';
import PrivateRoute from '../../../../shared/components/PrivateRoute';

export default () => (
  <Switch>
    <PrivateRoute path="/categories/index" component={Categories} />
    <PrivateRoute path="/categories/add-categories" component={AddCategories} />
    <PrivateRoute path="/categories/detail-categories/:id" component={DetailCategories} />
  </Switch>
);
