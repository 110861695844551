/* eslint-disable arrow-parens */
import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import MemberAddForm from './MemberAddForm';
import { MEMBER_URL, ORDER_URL } from '../../../../utils/endPoint';
import { LOCALSTORAGE_TOKEN } from '../../../../redux/types';

const MemberCard = () => {
  const history = useHistory();
  const onSubmit = (e) => {
    console.log(e);
    e.preventDefault();
    const data = new FormData();
    data.append('product_id', [e.target.product][0].value);
    data.append('user_id', [e.target.customer][0].value);
    // data.append('status', [e.target.status][0].value);
    // data.append('amount', [e.target.amount][0].value);
    data.append('amount', Number([e.target.amount][0].placeholder));
    data.append('tanggal_book', [e.target.date][0].value);
    data.append('jumlah_pax', [e.target.pax][0].value);
    data.append('tanggal_book', [e.target.date][0].value);
    data.append(
      'description_1',
      `BB1~${e.target.weight1.value}`
        + `,BB2~${
          e.target.weight2 !== undefined ? e.target.weight2.value : '0'
        }`
        + `,BB3~${
          e.target.weight3 !== undefined ? e.target.weight3.value : '0'
        }`
        + `,BB4~${
          e.target.weight4 !== undefined ? e.target.weight4.value : '0'
        }`
        + `,BB5~${e.target.weight5 !== undefined ? e.target.weight5.value : '0'}`,
    );
    data.append(
      'description_2',
      `AGE1~${e.target.age1.value}`
        + `,AGE2~${e.target.age2 !== undefined ? e.target.age2.value : '0'}`
        + `,AGE3~${e.target.age3 !== undefined ? e.target.age3.value : '0'}`
        + `,AGE4~${e.target.age4 !== undefined ? e.target.age4.value : '0'}`
        + `,AGE5~${e.target.age5 !== undefined ? e.target.age5.value : '0'}`,
    );
    data.append(
      'description_3',
      `GENDER1~${e.target.gender1.value}`
        + `,GENDER2~${
          e.target.gender2 !== undefined ? e.target.gender2.value : '0'
        }`
        + `,GENDER3~${
          e.target.gender3 !== undefined ? e.target.gender3.value : '0'
        }`
        + `,GENDER4~${
          e.target.gender4 !== undefined ? e.target.gender4.value : '0'
        }`
        + `,GENDER5~${
          e.target.gender5 !== undefined ? e.target.gender5.value : '0'
        }`,
    );
    data.append('transaction_fee', 4000);
    data.append('vat_fee', 440);
    data.append('platform_fee', 1500);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios
      .post(ORDER_URL, data, { headers: { Authorization: `Token ${token}` } })
      .then((res) => {
        console.log(res.data);

        const data2 = new FormData();
        data2.append('type', [e.target.type][0].value);
        data2.append('day_choice', [e.target.day][0].value);
        data2.append('start_date', [e.target.start_date][0].value);
        data2.append('end_date', [e.target.end_date][0].value);
        data2.append('order_id', res.data.id);
        axios
        .post(MEMBER_URL, data2, { headers: { Authorization: `Token ${token}` } })
        .then((res2) => {
          console.log(res2.data);
          history.push('/member/index');
        })
        .catch((error) => {
          console.log(error);
        });
      })
      .catch((error) => {
        console.log(error);
      });
    // console.log(
    //   `AGE1~${e.target.age1.value}`
    //   + `,AGE2~${e.target.age2 !== undefined ? e.target.age2.value : '0'}`
    //   + `,AGE3~${e.target.age3 !== undefined ? e.target.age3.value : '0'}`
    //   + `,AGE4~${e.target.age4 !== undefined ? e.target.age4.value : '0'}`
    //   + `,AGE5~${e.target.age5 !== undefined ? e.target.age5.value : '0'}`,
    // );
    // console.log(
    //   `BB1~${e.target.weight1.value}`
    //     + `,BB2~${e.target.weight2 !== undefined ? e.target.weight2.value : '0'}`
    //     + `,BB3~${e.target.weight3 !== undefined ? e.target.weight3.value : '0'}`
    //     + `,BB4~${e.target.weight4 !== undefined ? e.target.weight4.value : '0'}`
    //     + `,BB5~${e.target.weight5 !== undefined ? e.target.weight5.value : '0'}`,
    // );
    // console.log(
    //   `GENDER1~${e.target.gender1.value}`
    //     + `,GENDER2~${e.target.gender2 !== undefined ? e.target.gender2.value : '0'}`
    //     + `,GENDER3~${e.target.gender3 !== undefined ? e.target.gender3.value : '0'}`
    //     + `,GENDER4~${e.target.gender4 !== undefined ? e.target.gender4.value : '0'}`
    //     + `,GENDER5~${e.target.gender5 !== undefined ? e.target.gender5.value : '0'}`,
    // );
  };
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Informasi Member</h5>
          </div>
          <MemberAddForm handleSubmit={(e) => onSubmit(e)} />
        </CardBody>
      </Card>
    </Col>
  );
};

export default MemberCard;
