/* eslint-disable default-case */
import {
    ADD_SCHEDULE_SUCCESS, ADD_SCHEDULE_FAILED, ADD_SCHEDULE_ONGOING, ADD_SCHEDULE_TIMEOUT,
    SCHEDULE_LIST,
    UPDATE_SCHEDULE_SUCCESS, UPDATE_SCHEDULE_FAILED,
} from '../types';

const defaultState = {
    data: '',
    updateData: '',
    isRegistrating: false,
    updateResponse: null,
    errorMessage: '',
    status: null,
    listData: [],
};

const scheduleReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_SCHEDULE_SUCCESS: {
            return {
                ...state,
                isRegistrating: false,
                data: 'success', 
            };
        }
        case ADD_SCHEDULE_FAILED: {
            return {
                ...state, 
                isRegistrating: false,
                errorMessage: action.payload.error,
            };
        }
        case ADD_SCHEDULE_ONGOING: {
            return {
                ...state,
                isRegistrating: false,
            };
        }
        case ADD_SCHEDULE_TIMEOUT: {
            return {
                ...state,
                data: '', 
                errorMessage: '',
            }; 
        }
        case UPDATE_SCHEDULE_SUCCESS: {
            return {
                ...state,
                isRegistrating: false,
                updateData: action.payload.data,
                status: 'success',
                // updateResponse: true,
            };
        }
        case UPDATE_SCHEDULE_FAILED: {
            return {
                ...state,
                errorMessage: action.payload.errorMessage,
                status: 'danger',
            };
        }
        case SCHEDULE_LIST: {
            return {
                ...state,
                listData: action.payload.data,
            };
        }
        default: {
            return {
              ...state,
            };
          }
    }
};

export default scheduleReducer;
