/* eslint-disable arrow-parens */
import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import PartnerAddForm from './FacilityAddForm';

const PartnerCard = () => ( 
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">Informasi Utama</h5>
        </div>
        <PartnerAddForm />
      </CardBody>
    </Card>
  </Col>
);



export default (PartnerCard);
