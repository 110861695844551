/* eslint-disable arrow-parens */
import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import CommunityAddForm from './CommunityAddForm';

const CommunityCard = () => ( 
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">Main Information</h5>
        </div>
        <CommunityAddForm />
      </CardBody>
    </Card>
  </Col>
);


export default (CommunityCard);
