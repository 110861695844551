/* eslint-disable no-sequences */
/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import {
 Button, ButtonToolbar, Card, CardBody, Col, Alert,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';

const CageAddForm = ({ 
  errorMessage, handleSubmit, status,
}) => (
  <Col xs={12} md={12} lg={12}>
    <Card>
      <CardBody>
        <form className="form product-edit" onSubmit={handleSubmit}>
          <div className="form__form-group">
            <span className="form__form-group-label">Cage Name</span>
            <div className="form__form-group-field">
              <Field
                name="name"
                component="input"
                type="text"
                placeholder="Cage Name"
                required
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Number</span>
            <div className="form__form-group-field">
              <Field
                name="number"
                component="input"
                type="number"
                placeholder="Number"
                required
              />
            </div>
          </div>
          <div className="mb-3">
            <Alert
              color={status === 'success' ? 'success' : 'danger'}
              isOpen={!!errorMessage || status === 'success'}
            >
              <p className="p-2 pl-3">{status === 'success' ? 'Cage Successfully Added' : errorMessage}</p>
            </Alert>
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button color="primary" size="sm" type="submit">Submit</Button>
            <Button type="button" size="sm" href="/cage/index">
              Cancel
            </Button>
          </ButtonToolbar>
        </form>
      </CardBody>
    </Card>
  </Col>
);

CageAddForm.propTypes = {
  errorMessage: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

CageAddForm.defaultProps = {
  errorMessage: '',
};

export default reduxForm({
  form: 'cage_add_form', // a unique identifier for this form
})(CageAddForm);
