import React from 'react';
import { Switch } from 'react-router-dom';
import ProductsLists from '../../../Product/ProductList';
import AddProducts from '../../../Product/AddProduct';
import DetailProduct from '../../../Product/Detail/DetailProduct';
import AddImageProduct from '../../../Product/Detail/AddImageProduct';
import DetailImageProduct from '../../../Product/Detail/DetailImageProduct';
import FacilityProduct from '../../../Product/FacilityProduct/FacilityList';
import AddFacilityProduct from '../../../Product/FacilityProduct/AddFacility';
import DetailFacilityProduct from '../../../Product/FacilityProduct/DetailFacility';
import PrivateRoute from '../../../../shared/components/PrivateRoute';
import NewImageProduct from '../../../Product/Detail/DetailProduct/ImageProduct/NewImage';

export default () => (
  <Switch>
    <PrivateRoute path="/product/index" component={ProductsLists} />
    <PrivateRoute path="/product/add-product" component={AddProducts} />
    <PrivateRoute path="/product/detail-product/:id" component={DetailProduct} />
    <PrivateRoute path="/product/add-images/:id" component={AddImageProduct} />
    <PrivateRoute path="/product/detail-images/:id" component={DetailImageProduct} />
    <PrivateRoute path="/product/facility-product-list" component={FacilityProduct} />
    <PrivateRoute path="/product/add-facility-product" component={AddFacilityProduct} />
    <PrivateRoute path="/product/detail-facility-product/:id" component={DetailFacilityProduct} />
    <PrivateRoute path="/product/image/:id" component={NewImageProduct} />
  </Switch>
);
