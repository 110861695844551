import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainWrapper from '../MainWrapper';
import LogIn from '../../Account/LogIn/index';
// import Register from '../../Account/Register/index';
import ResetPassword from '../../Account/ResetPassword/index';
import ResetPasswordConfirmation from '../../Account/ResetPasswordConfirmation/index';
import WrappedRoutes from './WrappedRoutes';
import PrivateRoute from '../../../shared/components/PrivateRoute';

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        {/* <Route path="/register" component={Register} /> */}
        <Route path="/reset_password" component={ResetPassword} />
        <Route path="/reset/:token" component={ResetPasswordConfirmation} />
        <PrivateRoute path="/" component={WrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
