/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-concat */
/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
  ButtonToolbar,
  Col,
  Row,
  Alert,
  Container,

  //  Spinner,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderFileInputField from '@/shared/components/form/FileInput';
import renderSelectField from '@/shared/components/form/Select';
import { connect } from 'react-redux';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import {
  deleteProduct,
  updateProduct,
} from '../../../../../redux/actions/productActions';
import { getFacility } from '../../../../../redux/actions/facilityActions';
import { getCategories } from '../../../../../redux/actions/categoriesActions';
import { getPartner } from '../../../../../redux/actions/partnerActions';


const DetailProducts = (stateOri) => {
  // console.log('stateOri', stateOri);
  const [content, setContents] = useState('');
  const [dataFacilities, setDataFacilities] = useState([]);
  const handleEditorChange = (contents) => {
    //  console.log("Content was updated:", contents);
    setContents(contents);
  };
  //  const editorRef = useRef(null);
  const history = useHistory();
  const [isEditable, setEditable] = useState(false);
  const { updateResponse, updateData } = stateOri;
  const { dispatch } = stateOri;
  const { location } = history;
  const { state } = location;
  const { data } = state;
  const { initialize } = stateOri;
  const { listProductPartner, listServiceCategory, listFacility } = stateOri;
  console.log('state ori', data.facility_detail);
  useEffect(() => {
    dispatch(getPartner());
    dispatch(getCategories());
    dispatch(getFacility());
  }, [dispatch]);
  if (updateData) {
    initialize(updateData);
  }
  useEffect(() => {
    initialize({
 ...data, partner_id: { value: data?.partner_detail?.id, label: data?.partner_detail?.name }, service_category_id: { value: data?.service_category_detail?.id, label: data?.service_category_detail?.name }, unit: { value: data?.unit, label: data?.unit }, 
});
  }, []);
  // console.log(listFacility);
  const [, setImageProducts] = useState([]);
  // const [loading, setLoading] = useState(false);
  useEffect(() => {
    axios
      .get(`https://api.ghaidas.com/api-ghaidas/imagebyidproduct/${data.id}`)
      .then((res) => {
        setImageProducts(res.data);
        // console.log('dataaaa', res.data);
        // setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [data.id]);

  // image
  // const [currentImagePreview, setCurrentImagePreview] = useState(0);

  // const changeImg = (item) => {
  //   setCurrentImagePreview(item);
  // };

  return (
    <Container>
      <form
        className="form form--horizontal"
        onSubmit={(e) => {
          e.preventDefault();
          const updatedData = new FormData();
          updatedData.append('name', [e.target.name][0].value);
          // updatedData.append('partner_detail', [e.target.partner_detail][0].value);
          updatedData.append('partner_id', [e.target.partner_id][0].value);
          updatedData.append('service_category_id', [e.target.service_category_id][0].value);
          // if ([e.target.service_category_id][0].value) {
          //   updatedData.append(
          //     'service_category_id',
          //     [e.target.service_category_id][0].value,
          //   );
          // } else if (
          //   data.service_category_id
          //   && data.service_category_id.value
          // ) {
          //   updatedData.append(
          //     'service_category_id',
          //     data.service_category_id.value,
          //   );
          // }
          updatedData.append('description', content);
          updatedData.append('price_idr', [e.target.price_idr][0].value);
          updatedData.append('unit', [e.target.unit][0].value);
          updatedData.append('location', [e.target.location][0].value);
          updatedData.append('is_available', [e.target.is_available][0].value);
          if ([e.target.thumbnail][0].files[0]) {
            updatedData.append('thumbnail', [e.target.thumbnail][0].files[0]);
          } else if (data.thumbnail && data.thumbnail.file) {
            updatedData.append('thumbnail', data.thumbnail.file);
          }
          for (let i = 0; i < dataFacilities.length; i += 1) {
            updatedData.append('facilities', dataFacilities[i].value);
            // console.log('fasilitas', [e.target.facilities][0][i].value)
          }
          // updatedData.append('facilities', [e.target.facilities][0].value);
          dispatch(updateProduct(updatedData, data.id, history));
        }}
      >
        <>
          <div className="form__form-group">
            <span className="form__form-group-label">Nama Produk</span>
            <div className="form__form-group-field">
              <Field
                name="name"
                component="input"
                type="text"
                required
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Deskripsi</span>
            <div className="form__form-group-field">
              <Editor
                // onInit={(evt, editor) => (editorRef.current = editor)}
                apiKey="yb64t1vjyri2cansglhq8gaz1tai01e0dc0g3jlm9a4cjvdn"
                initialValue={data.description}
                init={{
                  height: 500,
                  menubar: true,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen textcolor ',
                    'insertdatetime media table paste code help wordcount',
                    'image code',
                  ],
                  toolbar:
                    'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
                  content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  // font_formats: 'Poppins',
                }}
                onEditorChange={handleEditorChange}
                outputFormat="html"
              />
            </div>
          </div>
          <div className="form__form-group-id-category">
            <div className="form__form-group">
              <span className="form__form-group-label">Mitra</span>
              <div className="form__form-group-field">
                <Field
                  name="partner_id"
                  component={renderSelectField}
                  type="text"
                  required
                  options={
                    listProductPartner || [{ value: 'other', label: 'Other' }]
                  }
                  placeholder="Pilih Produk Mitra"
                  disabled={!isEditable}
                />
              </div>
            </div>
          </div>
          <div className="form__form-group-id-category">
            <div className="form__form-group">
              <span className="form__form-group-label">Kategori</span>
              <div className="form__form-group-field">
                <Field
                  name="service_category_id"
                  component={renderSelectField}
                  type="text"
                  required
                  options={
                    listServiceCategory || [{ value: 'other', label: 'Other' }]
                  }
                  placeholder="Pilih Kategori"
                  disabled={!isEditable}
                />
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Fasilitas</span>
            <div className="form__form-group-field">
              <Select
                isMulti
                defaultValue={data?.facility_detail.map((item) => {
                  const foundItem = listFacility.find(
                    dataItem => dataItem.value === item.id.toString(),
                  );
                  if (foundItem) {
                    return {
                      value: foundItem.value,
                      label: foundItem.label,
                    };
                  }
                })}
                options={listFacility}
                name="facilities"
                classNamePrefix="react-select"
                className="react-select"
                placeholder="Fasilitas"
                required
                disabled={!isEditable}
                onChange={e => setDataFacilities(e)}
              />
            </div>
          </div>
          <div className="form__form-group form__form-group-price">
            <span className="form__form-group-label">Harga</span>
            <div className="form__form-group-field">
              <Field
                name="price_idr"
                component="input"
                type="text"
                required
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className="form__form-group-id-category">
            <div className="form__form-group">
              <span className="form__form-group-label">Unit</span>
              <div className="form__form-group-field">
                <Field
                  name="unit"
                  component={renderSelectField}
                  type="text"
                  required
                  options={[
                    { value: 'Room', label: 'Room' },
                    { value: 'Person', label: 'Person' },
                    { value: 'Family', label: 'Family' },
                  ]}
                  placeholder="Unit"
                  disabled={!isEditable}
                />
              </div>
            </div>
          </div>

          <div className="form__form-group-price-discount">
            <div className="form__form-group form__form-group-price">
              <span className="form__form-group-label">Lokasi</span>
              <div className="form__form-group-field">
                <Field
                  name="location"
                  component="input"
                  type="text"
                  required
                  placeholder="Lokasi"
                  disabled={!isEditable}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Tersedia </span>
              <div className="form__form-group-field">
                <Field
                  name="is_available"
                  component="input"
                  type="checkbox"
                  // required
                  // placeholder="YYYY-MM-DD"
                  disabled={!isEditable}
                />
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Upload Thumbnail</span>
            <div className="form__form-group-field">
              {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'right' }}> */}
              <Field
                name="thumbnail"
                required
                component={renderFileInputField}
                disabled={!isEditable}
              />
              <span>
                {data.thumbnail ? (
                  <img
                    className="ml-2"
                    src={data.thumbnail}
                    alt="thumbnail"
                    style={{ maxWidth: '300px', maxHeight: '300px' }}
                  />
                ) : (
                  'Tidak Ada'
                )}
              </span>
            </div>
          </div>
        </>

        <ButtonToolbar className="form__button-toolbar">
          <Row className="mt-4">
            <Col md={12}>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setEditable(!isEditable)}
              >
                {' '}
                {!isEditable ? 'Edit' : 'Cancel'}
              </button>
              {isEditable && (
                <button type="submit" className="btn btn-primary">
                  {' '}
                  Update
                </button>
              )}
              {isEditable === false && (
                <button
                  type="button"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(deleteProduct(data.id, history));
                  }}
                  className="btn btn-danger"
                >
                  {' '}
                  Delete
                </button>
              )}
            </Col>
          </Row>
        </ButtonToolbar>
      </form>
      {updateResponse ? (
        <Row>
          <Col xs={12} md={12} lg={12}>
            <Alert className="container mb-4 p-1" color="info">
              <div>Update Data berhasil</div>
            </Alert>
          </Col>
        </Row>
      ) : null}
    </Container>
  );
};

DetailProducts.propTypes = {
  //   listProductPartner: PropTypes.shape().isRequired,
  //   listServiceCategory: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => {
  const productPartner = state.partner.data
    ? state.partner.data.map(e => ({ value: `${e.id}`, label: e.name }))
    : [];
  const productCategory = state.categories.data
    ? state.categories.data.map(e => ({
        value: `${e.id}`,
        label: e.name,
      }))
    : [];
  const productFacilities = state.facility.data
    ? state.facility.data.map(e => ({ value: `${e.id}`, label: e.name }))
    : [];
  return {
    listProductPartner: productPartner,
    listServiceCategory: productCategory,
    listFacility: productFacilities,
    errorMessage: state.product.errorMessage,
    updateResponse: state.product.updateResponse,
    updateData: state.product.updateData,
  };
};

const mapDispatchToProps = {
  updateProductAction: updateProduct,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'product_detail_form', // a unique identifier for this form
    enableReinitialize: true,
  })(DetailProducts),
);
