import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import HorseCard from './components/HorseCard';
import ImageHorseList from './ImageHorse/ListingImage';

const HorseDetail = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Kuda</h3>
      </Col>
    </Row>
    <Row>
      <HorseCard />
    </Row>
    <Row>
      <ImageHorseList />
    </Row>
  </Container>
);

export default HorseDetail;
