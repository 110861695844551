import React, { useState } from 'react';
import {
 Button, ButtonToolbar, Card, CardBody, Col, 
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { SERVICECATEGORY_URL } from '../../../../utils/endPoint';
import { LOCALSTORAGE_TOKEN } from '../../../../redux/types';

const CategoriesAddForm = () => {
  const history = useHistory();
  const [err, setErr] = useState();
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const data = new FormData();
    data.append('name', [e.target.name][0].value);
    data.append('order_display', [e.target.order_display][0].value);
    axios
      .post(SERVICECATEGORY_URL, data, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => {
        console.log(res);
        history.push('/categories/index');
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Col xs={12} md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form form--horizontal" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">Nama kategori</span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component="input"
                  type="text"
                  required
                  placeholder="Nama Kategori"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Urutan</span>
              <div className="form__form-group-field">
                <Field
                  name="order_display"
                  component="input"
                  type="text"
                  required
                  placeholder="Urutan"
                />
              </div>
            </div>

            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">
                Submit
              </Button>
              <Button type="button" href="/categories/index">
                Cancel
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'categories_add_form', // a unique identifier for this form
})(CategoriesAddForm);
