/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CustomerListTable from './components/Customer';
import CreateTableData from './components/CreateData';
import { getCustomer } from '../../../redux/actions/customerActions';
import { LOCALSTORAGE_MYUSER } from '../../../redux/types';

const CustomerList = ({ dispatch, listData }) => {
  const CustomerListTableData = CreateTableData(listData);

  useEffect(() => {
    const localdata = JSON.parse(localStorage.getItem(LOCALSTORAGE_MYUSER));
    dispatch(getCustomer(localdata.id));
  }, []);
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Daftar Pelanggan</h3>
        </Col>
      </Row>
      <Row>
        <CustomerListTable CustomerListTableData={CustomerListTableData} />
      </Row>
    </Container>
  );
};

CustomerList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  listData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = state => ({
    listData: state.customer.listData,
  });

export default connect(mapStateToProps, null)(CustomerList);
