import {
  UPDATEUSER_SUCCESS,
  UPDATEUSER_FAILED,
} from '../types';

const defaultState = {
  data: '',
  status: '',
  errorMessage: '',
};

const registrationUserReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATEUSER_SUCCESS: {
      return {
        ...state, data: action.payload.data, status: 'success',
      };
    }
    case UPDATEUSER_FAILED: {
      return {
        ...state, errorMessage: action.payload.errorMessage, status: 'danger',
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default registrationUserReducer;
