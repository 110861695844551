import React from 'react';
import { Switch } from 'react-router-dom';
// import DetailCategories from '../../../ServiceCategory/DetailCategory';
import PrivateRoute from '../../../../shared/components/PrivateRoute';
import VisitingList from '../../../Visiting/VisitingList';

import AddVisiting from '../../../Visiting/AddVisiting';
import VisitingDetail from '../../../Visiting/Detail';

export default () => (
  <Switch>
    <PrivateRoute path="/visiting/index" component={VisitingList} />
    <PrivateRoute path="/visiting/add-visiting" component={AddVisiting} />
    <PrivateRoute path="/visiting/detail/:id" component={VisitingDetail} />

  </Switch>
);
