import { CATEGORIES_LIST } from '../types';

const defaultState = {
  data: [],
  loading: true,
};

const categoriesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CATEGORIES_LIST: {
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default categoriesReducer;
