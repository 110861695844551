/* eslint-disable max-len */
/* eslint-disable no-sequences */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
 Button, ButtonToolbar, Card, CardBody, Col, Alert, 
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderFileInputField from '@/shared/components/form/FileInput';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import { LOCALSTORAGE_TOKEN } from '../../../../redux/types';
import { COMMUNITY_URL } from '../../../../utils/endPoint';

const CommunityAddForm = () => {
  const [content, setContents] = useState('');
  const handleEditorChange = (contents) => {
    //  console.log("Content was updated:", contents);
    setContents(contents);
  };
  const handleSubmit = (e, handleFunction, history) => {
    e.preventDefault();
    const data = new FormData();
    data.append('nama', [e.target.nama][0].value);
    data.append('lokasi', [e.target.lokasi][0].value);
    data.append('keterangan', content);
    data.append('image', [e.target.image][0].files[0]);
    handleFunction(data, history);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    console.log('dari action Community 1', token);
    axios.post(COMMUNITY_URL, data, { headers: { Authorization: `Token ${token}` } })
        .then((res) => {
            console.log('Community data', res.data);
    
            history.push('/community/index');
    })
    .catch((err) => {
        console.log(err);
    });
  };

  return (
    <Col xs={12} md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form product-edit" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">Community Name</span>
              <div className="form__form-group-field">
                <Field
                  name="nama"
                  component="input"
                  type="text"
                  placeholder="Community Name"
                  required
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Location</span>
              <div className="form__form-group-field">
                <Field
                  name="lokasi"
                  component="input"
                  type="text"
                  placeholder="Location"
                  required
                />
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Description</span>
                <div className="form__form-group-field">
                  <Editor
            // onInit={(evt, editor) => (editorRef.current = editor)}
                    apiKey="yb64t1vjyri2cansglhq8gaz1tai01e0dc0g3jlm9a4cjvdn"
            // initialValue="<p>This is the initial content of the editor.</p>"
                    init={{
              height: 500,
              menubar: true,
              plugins: ['advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks code fullscreen textcolor ', 'insertdatetime media table paste code help wordcount', 'image code'],
              toolbar: 'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              // font_formats: 'Poppins',
            }}
                    onEditorChange={handleEditorChange}
                    outputFormat="html"
                  />
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Upload photo</span>
              <div className="form__form-group-field">
                <Field name="image" component={renderFileInputField} />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" size="sm" type="submit">
                Submit
              </Button>
              <Button type="button" size="sm" href="/community/index">
                Cancel
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'community_add_form', // a unique identifier for this form
})(CommunityAddForm);
