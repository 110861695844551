/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import {
 Button, ButtonToolbar, Card, CardBody, Col, Alert, 
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderFileInputField from '@/shared/components/form/FileInput';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { LOCALSTORAGE_TOKEN } from '../../../../../../redux/types';

const PartnerAddForm = () => {
  const params = useParams();
  const history = useHistory();
  const handleSubmit = (e) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    e.preventDefault();
    const data = new FormData();
    data.append('image', [e.target.icon][0].files[0]);
    data.append('horseId', params.id);
    axios
      .post('https://api.ghaidas.com/api-ghaidas/horseimage/', data, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => {
        console.log(res);
        history.push('/horse/index');
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Col xs={12} md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form form--horizontal" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">Upload Image</span>
              <div className="form__form-group-field">
                <Field name="icon" required component={renderFileInputField} />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" size="sm" type="submit">
                Submit
              </Button>
              <Button
                type="button"
                size="sm"
                href="/product/facility-product-list"
              >
                Cancel
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'facility_add_form', // a unique identifier for this form
})(PartnerAddForm);
