import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import KbmCard from '../AddKbm/components/KbmAddCard';

const KbmDetail = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Kuota Kunjungan</h3>
      </Col>
    </Row>
    <Row>
      <KbmCard />
    </Row>
  </Container>
);

export default KbmDetail;
