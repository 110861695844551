/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
 Button, ButtonToolbar, Card, CardBody, Col, Alert,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
// import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import renderFileInputField from '@/shared/components/form/FileInput';
// import renderDropZoneMultipleField from '@/shared/components/form/DropZoneMultiple';
import renderSelectField from '@/shared/components/form/Select';
// import Select from 'react-select';

const PartnerAddForm = ({ 
  errorMessage, handleSubmit, status,
}) => {
  const params = useParams();
  const [products, setProducts] = useState([]);
  // const [loading, setLoading] = useState(false);
  useEffect(() => {
    axios
      .get(`https://api.ghaidas.com/api-ghaidas/product/${params.id}`)
      .then((res) => {
          setProducts(res.data);
          // setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.id]);
  return (
    <Col xs={12} md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form form--horizontal" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">Nama</span>
              <div className="form__form-group-field">
                {/* <div className="form__form-group-icon">
                  <AccountOutlineIcon />
                </div> */}
                <Field
                  name="nama"
                  component="input"
                  type="text"
                  placeholder="Nama"
                  // required
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Produk</span>
              <div className="form__form-group-field">
                {/* <div className="form__form-group-icon">
                  <AccountOutlineIcon />
                </div> */}
                <Field
                  name="product"
                  component={renderSelectField}
                  type="text"
                  required
                  options={[
                    { value: `${products.id}`, label: `${products.name}` },
                    ]}
                  placeholder="Pilih Kategori"
                />
                {/* <Select 
                  defaultValue={products.id}
                  name="product" 
                  classNamePrefix="react-select"
                  className="react-select"
                  placeholder={products.name}
                  disable
                /> */}
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Upload Gambar</span>
              <div className="form__form-group-field">
                <Field
                  name="image"
                  required
                  component={renderFileInputField}
                />
                {/* <Field
                  name="image"
                  required
                  component={renderDropZoneMultipleField}
                /> */}
              </div>
            </div>
            <Alert
              color={status === 'success' ? 'success' : 'danger'}
              isOpen={!!errorMessage || status === 'success'}
            >
              <p><span className="bold-text">Congratulations!</span> 
                {status === 'success' ? 'Image Product Successfully Added' : errorMessage}
              </p>
            </Alert>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" size="sm" type="submit">Submit</Button>
              <Button type="button" size="sm" href="/product/index">
                Cancel
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

PartnerAddForm.propTypes = {
  errorMessage: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

PartnerAddForm.defaultProps = {
  errorMessage: '',
};

export default reduxForm({
  form: 'partner_add_form', // a unique identifier for this form
  enableReinitialize: true,
})(PartnerAddForm);
