/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
 ButtonToolbar, Col, Row, Alert, Container, 
} from 'reactstrap';
import {
 Field, reduxForm, 
} from 'redux-form';
import { connect } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import renderSelectField from '@/shared/components/form/Select';
import renderDateTimePickerField from '@/shared/components/form/DateTimePicker';
import axios from 'axios';
import moment from 'moment';
// import { useHistory } from 'react-router';
import { WhatsappShareButton, WhatsappIcon } from 'react-share';
// import { useHistory } from 'react-router';
import { addScheduleTimeout, updateSchedule, updateScheduleFailed } from '../../../../../../redux/actions/scheduleActions';
import { getUser } from '../../../../../../redux/actions/userActions';
// import { getTrainer } from '../../../../redux/actions/trainerActions';
import { getHorse } from '../../../../../../redux/actions/horseActions';
import { LOCALSTORAGE_TOKEN } from '../../../../../../redux/types';
import { SCHEDULE_URL } from '../../../../../../utils/endPoint';



const EditScheduleForm = (stateOri) => {
  // const history = useHistory();
  const [isEditable, setEditable] = useState(false);
  const [selectedSch, setSelectedSch] = useState();
  const [selectedStatus, setSelectedStatus] = useState('');
  const { updateResponse } = stateOri;
  const { dispatch } = stateOri;
  const { initialize } = stateOri;
  const { SchId } = stateOri;
  const { listUser, listHorse } = stateOri;
  const getUserLocal = JSON.parse(localStorage.getItem('LOCALSTORAGE_MYUSER'));
  const [pelatih, setPelatih] = useState([]);
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN);

  console.log('hello', stateOri);

  useEffect(() => {
      axios.get(`${SCHEDULE_URL}${SchId}`, { headers: { Authorization: `Token ${token}` } }).then((res) => {
        setSelectedSch(res.data);
        console.log(res.data);
        initialize({ 
          start: new Date(`${res.data.date} ${res.data.start_time.replace(/-/g, '/')}`),
          end: new Date(`${res.data.date} ${res.data.end_time.replace(/-/g, '/')}`),
          status: { value: res.data.status, label: res.data.status },
          type: { value: res.data.type, label: res.data.type },
          user_id: { value: res.data.userId, label: res.data.user_detail?.fullname },
          trainer_id: {
            value: res.data.trainerId,
            label: res.data.trainer_detail?.fullname,
          },
          horse_id: { value: res.data.horseId, label: res.data.horse_detail?.name },
        });
        setSelectedStatus(res.data.status);
      }).catch((err) => {
        console.log(err);
      });
}, [initialize]);

  useEffect(() => {
    if (getUserLocal.role !== 'Kasie Pelatih') {
      const dataPelatih = async () => {
        const res = await axios.get(
          'https://api.ghaidas.com/api-ghaidas/userbyrole/Kasie Pelatih',
          { headers: { Authorization: `Token ${token}` } },
        );
        // console.log(res.data);
        setPelatih(res.data);
      };
      dataPelatih();
    } else {
      const dataPelatih = async () => {
        const res = await axios.get(
          'https://api.ghaidas.com/api-ghaidas/userbyrole/Pelatih',
          { headers: { Authorization: `Token ${token}` } },
        );
        // console.log(res.data);
        setPelatih(res.data);
      };
      dataPelatih();
    }
    dispatch(getUser());
    // dispatch(getTrainer());
    dispatch(getHorse());
  }, [dispatch]);

  const listPelatih = pelatih.length > 0
    && pelatih.map(item => ({
      value: item.id,
      label: item.fullname,
    }));
  const roles = getUserLocal.role;
  const options = [
    { value: 'Pending', label: 'Pending' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Reschedule', label: 'Reschedule' },
  ];
  const options2 = [
    { value: 'Approved', label: 'Approved' },
    { value: 'Reschedule', label: 'Reschedule' },
  ];
  const options3 = [
    { value: 'Reschedule', label: 'Reschedule' },
    { value: 'Approved', label: 'Approved' },
  ];
  let result;
  if (roles === 'Kepala Stable') {
    result = options2;
  } else if (roles === 'Administrasi Dan Keungan') {
    result = options;
  } else {
    result = options3;
  }  
  
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(e.target.status.value);
    // console.log(e.target[3]).format('YYYY-MM-DD');
    const updatedData = new FormData();
      // updatedData.append('date', moment(e.target[3].value).format('YYYY-MM-DD'));
      updatedData.append('start_time', moment(e.target[2].value || e.target[3].value).format('HH:mm'));
      updatedData.append('end_time', moment(e.target[4].value || e.target[3].value).format('HH:mm'));
      updatedData.append('status', e.target.status.value);
    axios.patch(`${SCHEDULE_URL}${SchId}/`, updatedData, { headers: { Authorization: `Token ${token}` } })
    .then((res) => {
      console.log(res);
      window.location.reload();
        // history.push('/schedule/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addScheduleTimeout()), 3000);
        dispatch(updateScheduleFailed({ error }));
    });
    };

    const startDate = moment()
    .startOf('week')
    .add(-1, 'weeks')
    .add(6, 'days')
    .format('DD MMMM YYYY');
    const endDate = moment()
    .endOf('week')
    .add(-1, 'weeks')
    .add(6, 'days')
    .format('DD MMMM YYYY');
  // console.log('asdadadsadasdadwdad', startDate, endDate);

  const shareUrl = ' https://www.ecosunnah.com/schedule';
  const title = `Assalamualaikum. Berikut jadwal Reschedule Berkuda dan Memanah dari tanggal ${startDate} - ${endDate}. Untuk lebih lanjut silahkan kunjungi link di bawah ini👇:`;
  
  const handleUpdate = () => {
    if (selectedStatus === 'Reschedule') {
      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(title + shareUrl)}`;
      window.open(whatsappUrl, '_blank');
    }
  };
  
  
  return (
    <Container>
      <form className="form product-edit" onSubmit={handleSubmit}>
        <div className="form__form-group-id-category">
          <div className="form__form-group">
            <span className="form__form-group-label">Pelanggan</span>
            <div className="form__form-group-field">
              <Field
                name="user_id"
                component={renderSelectField}
                type="text"
                required
                options={listUser || [{ value: 'other', label: 'Other' }]}
                placeholder="Choose User"
                disabled
              />
            </div>
          </div>
        </div>
        {selectedSch !== undefined && selectedSch?.type === 'Berkuda' && (
        <div className="form__form-group-id-category">
          <div className="form__form-group">
            <span className="form__form-group-label">Horse</span>
            <div className="form__form-group-field">
              <Field
                name="horse_id"
                component={renderSelectField}
                type="text"
                required
                options={
                        listHorse || [{ value: 'other', label: 'Other' }]
                      }
                placeholder="Choose Horse"
                disabled
              />
            </div>
          </div>
        </div>
            )}
        <div className="form__form-group-id-category">
          <div className="form__form-group">
            <span className="form__form-group-label">Type</span>
            <div className="form__form-group-field">
              <Field
                name="type"
                component={renderSelectField}
                type="text"
                options={[
                  { value: 'Memanah', label: 'Memanah' },
                  { value: 'Berkuda', label: 'Berkuda' },
                ]}
                placeholder="Choose Type"
                // disabled={
                //   getUserLocal.role === 'Kepala Stable'
                //   || getUserLocal.role === 'Kasie Pelatih'
                //     ? true
                //     : !isEditable
                // }
                required
                disabled
              />
            </div>
          </div>
        </div>
        {
          getUserLocal.role !== 'Administrasi Dan Keuangan' ? (
            <div className="form__form-group-id-category">
              <div className="form__form-group">
                <span className="form__form-group-label">Pelatih</span>
                <div className="form__form-group-field">
                  <Field
                    name="trainer_id"
                    component={renderSelectField}
                    type="text"
                    options={listPelatih}
                    placeholder="Choose Pelatih"
                    disabled={
                  getUserLocal.role === 'Kepala Stable' ? true : !isEditable
                }
                  />
                </div>
              </div>
            </div>
          ) : null
        }
        {
          selectedSch && new Date(`${selectedSch?.date} ${ selectedSch?.start_time.replace(/-/g, '/')}`)
          && (
            <div className="form__form-group">
              <span className="form__form-group-label">Start</span>
              <div className="form__form-group-field">
                <Field
                  name="start_time"
              // defaultValue={
              //   new Date(`${selectedSch?.date} ${selectedSch?.start_time.replace(/-/g, '/')}`)
              // }
              // initialValue={
              //   selectedSch && new Date(`${selectedSch.date} ${selectedSch.start_time.replace(/-/g, '/')}`)
              // }   
                  defaultValue={
                selectedSch && new Date(`${selectedSch.date} ${selectedSch.start_time.replace(/-/g, '/')}`) 
              }           
                  component={renderDateTimePickerField}
                  disabled={
                getUserLocal.role === 'Kepala Stable'
                || getUserLocal.role === 'Kasie Pelatih'
                  ? true
                  : !isEditable
              }
                  placeholder="Masukan waktu mulai"
                />
                
              </div>
            </div>
          )
        }

        {
          selectedSch && new Date(`${selectedSch?.date} ${ selectedSch?.end_time.replace(/-/g, '/')}`)
          && (
            <div className="form__form-group">
              <span className="form__form-group-label">End</span>
              <div className="form__form-group-field">
                <Field
                  name="end_time"
                  defaultValue={
                selectedSch && new Date(`${selectedSch?.date} ${selectedSch?.end_time.replace(/-/g, '/')}`)
              }
                  disabled={
                getUserLocal.role === 'Kepala Stable'
                || getUserLocal.role === 'Kasie Pelatih'
                  ? true
                  : !isEditable
              }
                  component={renderDateTimePickerField}
                  placeholder="Masukan waktu selesai"
                />
              </div>
            </div>
          )
        }
        <div className="form__form-group-id-category">
          <div className="form__form-group">
            <span className="form__form-group-label">Status</span>
            <div className="form__form-group-field">
              <Field
                name="status"
                component={renderSelectField}
                type="text"
                required
                options={
                  result
                }
                onChange={(e) => {
                 setSelectedStatus(e.value);
                }}
                placeholder="Choose Status"
                disabled={
                  !isEditable
                }
              />
            </div>
          </div>
        </div>
        {selectedStatus === 'Reschedule' ? (
          <div className="form__form-group-id-category">
            <div className="form__form-group">
              <div className="form__form-group-field">
                <WhatsappShareButton url={shareUrl} title={title} className="mx-auto">
                  <WhatsappIcon size={30} round={20} />
                </WhatsappShareButton>
              </div>
            </div>
          </div>
) : null}

        
        <Col md={12} className="d-flex justify-content-center">
          <ButtonToolbar className="form__button-toolbar">
            <Row className="mt-4">
              <Col>
                {/* {selectedStatus !== 'Reschedule' && (
                <> */}
                <button
                  type="button"
                  className="btn btn-secondary btn-sm "
                  onClick={() => setEditable(!isEditable)}
                  style={{ marginLeft: '14px' }}
                >
                  {' '}
                  {!isEditable ? 'Edit' : 'Cancel'}
                </button>
                {isEditable && (
                  <button type="submit" onClick={handleUpdate} className="btn btn-primary btn-sm mx-2">
                    {' '}
                    Update
                  </button>
                )}
                {/* </>
                )} */}
                {/* {isEditable === false && (
                <button
                  type="button"
                  size="sm"
                  onClick={(e) => {
              e.preventDefault();
              dispatch(deleteSchedule(data.id, history));
            }}
                  className="btn btn-danger"
                >
                  {' '}
                  Delete
                </button>
        )} */}
              </Col>
            </Row>
          </ButtonToolbar>
        </Col>
      </form>
      {updateResponse ? (
        <Row>
          <Col md={12}>
            <Alert className="container mb-4 p-1" color="info">
              <p>Update Data berhasil</p>
            </Alert>
          </Col>
        </Row>
      ) : null}
    </Container>
  );
};

const mapStateToProps = state => ({
  errorMessage: state.partner.errorMessage,
  updateResponse: state.partner.updateResponse,
  updateData: state.partner.updateData,
  // formValues2: getFormValues('schedule_edit_form')(state),
  listUser: state.user.listData
    ? state.user.listData.map(e => ({ value: `${e.id}`, label: e.fullname }))
    : [],
  // listTrainer: state.trainer.listData ? state.trainer.listData.map(e => ({ value: `${e.id}`, label: e.user_detail?.fullname })) : [],
  listHorse: state.horse.listData
    ? state.horse.listData.map(e => ({ value: `${e.id}`, label: e.name }))
    : [],
});
const mapDispatchToProps = {
  updateScheduleAction: updateSchedule,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps, // bind account loading action creator
)(
  reduxForm({
    form: 'schedule_edit_form', // a unique identifier for this form
    enableReinitialize: true,
  })(EditScheduleForm),
);
