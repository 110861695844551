import { createAction } from 'redux-actions';
import axios from 'axios';

import {
    LOCALSTORAGE_TOKEN,
    ADD_FACILITY_FAILED, ADD_FACILITY_TIMEOUT,
    FACILITY_LIST,
} from '../types';
import { FACILITY_URL } from '../../utils/endPoint';

export const addFacilityFailed = createAction(ADD_FACILITY_FAILED);
export const addFacilityTimeout = createAction(ADD_FACILITY_TIMEOUT);
export const getFacilitySuccess = createAction(FACILITY_LIST);

const token = localStorage.getItem(LOCALSTORAGE_TOKEN);

export const getFacility = () => async (dispatch) => {
    // console.log('dari action Facility 2', token);
    axios.get(FACILITY_URL, { headers: { Authorization: `Token ${token}` } }) 
        .then((res) => {
        dispatch(getFacilitySuccess({ data: res.data }));
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addFacilityTimeout()), 3000);
        dispatch(addFacilityFailed({ error }));
    });
};


