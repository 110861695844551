import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import CageCard from './components/CageCard';

const CageDetail = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Cage</h3>
      </Col>
    </Row>
    <Row>
      <CageCard />
    </Row>
  </Container>
);

export default CageDetail;
