/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
 ButtonToolbar, Col, Row, Alert, Container, 
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteCage, updateCage } from '../../../../redux/actions/cageActions';

const CageForm = (stateOri) => {
  const history = useHistory();
  const [isEditable, setEditable] = useState(false);
  const { updateResponse, updateData } = stateOri;
  const { dispatch } = stateOri;
  const { location } = history;
  const { state } = location;
  const { data } = state;
  const { initialize } = stateOri;

  if (updateData) {
    initialize(updateData);
  }
  useEffect(() => {
    initialize(data);
  }, []);

  return (
    <Container>
      <form
        className="form product-edit"
        onSubmit={(e) => {
          e.preventDefault();
          const updatedData = new FormData();
          updatedData.append('name', [e.target.name][0].value);
          updatedData.append('number', [e.target.number][0].value);
          dispatch(updateCage(updatedData, data.id, history));
        }}
      >
        <div className="form__form-group">
          <span className="form__form-group-label">Name</span>
          <div className="form__form-group-field">
            <Field
              name="name"
              component="input"
              type="text"
              placeholder="Name"
              disabled={!isEditable}
              required
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Number</span>
          <div className="form__form-group-field">
            <Field
              name="number"
              component="input"
              type="number"
              placeholder="Number"
              disabled={!isEditable}
              required
            />
          </div>
        </div>

        <ButtonToolbar className="form__button-toolbar">
          <Row className="mt-4">
            <Col md={12}>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setEditable(!isEditable)}
              >
                {' '}
                {!isEditable ? 'Edit' : 'Cancel'}
              </button>
              {isEditable && (
                <button type="submit" className="btn btn-primary">
                  {' '}
                  Update
                </button>
              )}
              {isEditable === false && (
                <button
                  type="button"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(deleteCage(data.id, history));
                  }}
                  className="btn btn-danger"
                >
                  {' '}
                  Delete
                </button>
              )}
            </Col>
          </Row>
        </ButtonToolbar>
      </form>
      {updateResponse ? (
        <Row>
          <Col md={12}>
            <Alert className="container mb-4 p-1" color="info">
              <p>Update Data berhasil</p>
            </Alert>
          </Col>
        </Row>
      ) : null}
    </Container>
  );
};

const mapStateToProps = state => ({
  errorMessage: state.partner.errorMessage,
  updateResponse: state.partner.updateResponse,
  updateData: state.partner.updateData,
});
const mapDispatchToProps = {
  updateCageAction: updateCage,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps, // bind account loading action creator
)(
  reduxForm({
    form: 'cage_edit_form', // a unique identifier for this form
    enableReinitialize: true,
  })(CageForm),
);
