/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, CardBody, Col } from 'reactstrap';
import PartnerAddForm from './ImageAddForm';
import { addImageProduct } from '../../../../../redux/actions/imageproductActions';

const onSubmit = (e, handleFunction, history, dataproduct) => {
  e.preventDefault();
  const data = new FormData();
  data.append('nama', [e.target.nama][0].value);
  data.append('product', [e.target.product][0].value);
  data.append('image', [e.target.image][0].files[0]);
  // for (let i = 0; i < e.target.image.length; i += 1) {
  //   data.append('image', [e.target.image][0][i].value); 
  // }
  handleFunction(data, dataproduct, history);
};

const PartnerCard = ({
  AddImageProductAction, history, errorMessage, status,
}) => { 
  const historyid = useHistory();
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Informasi Utama</h5>
          </div>
          <PartnerAddForm
            status={status}
            errorMessage={errorMessage}
            handleSubmit={(e) => onSubmit(e, AddImageProductAction, history, historyid.location.state.data)}
          />
        </CardBody>
      </Card>
    </Col>
  );
  };

PartnerCard.propTypes = {
  AddImageProductAction: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapDispatchToProps = {
  AddImageProductAction: addImageProduct,
};

const mapStateToProps = (state) => ({ 
  data: state.imageProduct.data,
  isRegistrating: state.imageProduct.isRegistrating,
  errorMessage: state.imageProduct.errorMessage,
  status: state.imageProduct.data,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PartnerCard),
);
