/* eslint-disable react/react-in-jsx-scope */
import axios from 'axios';
import { useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { LOCALSTORAGE_TOKEN } from '../../../../../../redux/types';

const PhotoFormatter = value => (
  <div className="products-list__img-wrap">
    <img src={value} alt="" />
  </div>
);


const CreateDataHorseListTable = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        accessor: 'id',
        Header: 'No',
        width: 20,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Photo',
        accessor: 'photo',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        accessor: 'status',
        Header: 'Status',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );


  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
        nama: '-',
        lokasi: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
            nama: '-',
            lokasi: '-',
          });
        } else {
          data.push({
            id: `${i + 1}`,
            photo: PhotoFormatter(`${dataAPI[i].image}`),
            status: [

              (
                <Button
                  key={i}
                  size="sm"
                  color="danger"
                  onClick={() => {
                    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                axios.delete(`https://api.ghaidas.com/api-ghaidas/horseimage/${dataAPI[i].id}`,
                { headers: { Authorization: `Token ${token}` } }).then(() => {
                  alert('hapus berhasil');
                  history.push('/horse/index');
                }).catch((err) => {
                  console.log(err);
                });
              }}
                >
                  Hapus
                </Button>
               ),
            ],
          });
        }
      }
    }
  };

  rows();
  const HorseListTableData = { tableHeaderData: columns, tableRowsData: data };
  return HorseListTableData;
};

export default CreateDataHorseListTable;
