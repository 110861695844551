export const LOCALSTORAGE_TOKEN = 'LOCALSTORAGE_TOKEN';
export const LOCALSTORAGE_MYUSER = 'LOCALSTORAGE_MYUSER';

export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';
export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_TIMEOUT_ALERT = 'AUTH_TIMEOUT_ALERT';

export const UPDATEUSER_SUCCESS = 'UPDATEUSER_SUCCESS';
export const UPDATEUSER_FAILED = 'UPDATEUSER_FAILED';

export const RESETPASSWORD_SUCCESS = 'RESETPASSWORD_SUCCESS';
export const RESETPASSWORD_FAILED = 'RESETPASSWORD_FAILED';
export const RESETPASSWORDCONFIRM_SUCCESS = 'RESETPASSWORDCONFIRM_SUCCESS';
export const RESETPASSWORDCONFIRM_FAILED = 'RESETPASSWORDCONFIRM_FAILED';

export const ADD_PARTNER_FAILED = 'ADD_PARTNER_FAILED';
export const ADD_PARTNER_TIMEOUT = 'ADD_PARTNER_TIMEOUT';
export const PARTNER_LIST = 'PARTNER_LIST';

export const ADD_CATEGORIES_FAILED = 'ADD_CATEGORIES_FAILED';
export const ADD_CATEGORIES_TIMEOUT = 'ADD_CATEGORIES_TIMEOUT';
export const CATEGORIES_LIST = 'CATEGORIES_LIST';


export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAILED = 'ADD_PRODUCT_FAILED';
export const ADD_PRODUCT_ONGOING = 'ADD_PRODUCT_ONGOING';
export const ADD_PRODUCT_TIMEOUT = 'ADD_PRODUCT_TIMEOUT';
export const PRODUCT_LIST = 'PRODUCT_LIST';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILED = 'UPDATE_PRODUCT_FAILED';
export const DELETE_PRODUCT_SUCCCES = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED';

export const ADD_COMMUNITY_SUCCESS = 'ADD_COMMUNITY_SUCCESS';
export const ADD_COMMUNITY_FAILED = 'ADD_COMMUNITY_FAILED';
export const ADD_COMMUNITY_ONGOING = 'ADD_COMMUNITY_ONGOING';
export const ADD_COMMUNITY_TIMEOUT = 'ADD_COMMUNITY_TIMEOUT';
export const COMMUNITY_LIST = 'COMMUNITY_LIST';
export const UPDATE_COMMUNITY_SUCCESS = 'UPDATE_COMMUNITY_SUCCESS';
export const UPDATE_COMMUNITY_FAILED = 'UPDATE_COMMUNITY_FAILED';
export const DELETE_COMMUNITY_SUCCCES = 'DELETE_COMMUNITY_SUCCESS';
export const DELETE_COMMUNITY_FAILED = 'DELETE_COMMUNITY_FAILED';

export const ADD_IMAGEPRODUCT_SUCCESS = 'ADD_IMAGEPRODUCT_SUCCESS';
export const ADD_IMAGEPRODUCT_FAILED = 'ADD_IMAGEPRODUCT_FAILED';
export const ADD_IMAGEPRODUCT_ONGOING = 'ADD_IMAGEPRODUCT_ONGOING';
export const ADD_IMAGEPRODUCT_TIMEOUT = 'ADD_IMAGEPRODUCT_TIMEOUT';
export const IMAGEPRODUCT_LIST = 'IMAGEPRODUCT_LIST';
export const UPDATE_IMAGEPRODUCT_SUCCESS = 'UPDATE_IMAGEPRODUCT_SUCCESS';
export const UPDATE_IMAGEPRODUCT_FAILED = 'UPDATE_IMAGEPRODUCT_FAILED';
export const DELETE_IMAGEPRODUCT_SUCCCES = 'DELETE_IMAGEPRODUCT_SUCCESS';
export const DELETE_IMAGEPRODUCT_FAILED = 'DELETE_IMAGEPRODUCT_FAILED';

export const ADD_FACILITY_SUCCESS = 'ADD_FACILITY_SUCCESS';
export const ADD_FACILITY_FAILED = 'ADD_FACILITY_FAILED';
export const ADD_FACILITY_ONGOING = 'ADD_FACILITY_ONGOING';
export const ADD_FACILITY_TIMEOUT = 'ADD_FACILITY_TIMEOUT';
export const FACILITY_LIST = 'FACILITY_LIST';
export const UPDATE_FACILITY_SUCCESS = 'UPDATE_FACILITY_SUCCESS';
export const UPDATE_FACILITY_FAILED = 'UPDATE_FACILITY_FAILED';
export const DELETE_FACILITY_SUCCCES = 'DELETE_FACILITY_SUCCESS';
export const DELETE_FACILITY_FAILED = 'DELETE_FACILITY_FAILED';

export const ADD_HORSE_SUCCESS = 'ADD_HORSE_SUCCESS';
export const ADD_HORSE_FAILED = 'ADD_HORSE_FAILED';
export const ADD_HORSE_ONGOING = 'ADD_HORSE_ONGOING';
export const ADD_HORSE_TIMEOUT = 'ADD_HORSE_TIMEOUT';
export const HORSE_LIST = 'HORSE_LIST';
export const UPDATE_HORSE_SUCCESS = 'UPDATE_HORSE_SUCCESS';
export const UPDATE_HORSE_FAILED = 'UPDATE_HORSE_FAILED';
export const DELETE_HORSE_SUCCCES = 'DELETE_HORSE_SUCCESS';
export const DELETE_HORSE_FAILED = 'DELETE_HORSE_FAILED';

export const ADD_CAGE_SUCCESS = 'ADD_CAGE_SUCCESS';
export const ADD_CAGE_FAILED = 'ADD_CAGE_FAILED';
export const ADD_CAGE_ONGOING = 'ADD_CAGE_ONGOING';
export const ADD_CAGE_TIMEOUT = 'ADD_CAGE_TIMEOUT';
export const CAGE_LIST = 'CAGE_LIST';
export const UPDATE_CAGE_SUCCESS = 'UPDATE_CAGE_SUCCESS';
export const UPDATE_CAGE_FAILED = 'UPDATE_CAGE_FAILED';
export const DELETE_CAGE_SUCCCES = 'DELETE_CAGE_SUCCESS';
export const DELETE_CAGE_FAILED = 'DELETE_CAGE_FAILED';

export const ADD_BOOKING_SUCCESS = 'ADD_BOOKING_SUCCESS';
export const ADD_BOOKING_FAILED = 'ADD_BOOKING_FAILED';
export const ADD_BOOKING_ONGOING = 'ADD_BOOKING_ONGOING';
export const ADD_BOOKING_TIMEOUT = 'ADD_BOOKING_TIMEOUT';
export const BOOKING_LIST = 'BOOKING_LIST';
export const UPDATE_BOOKING_SUCCESS = 'UPDATE_BOOKING_SUCCESS';
export const UPDATE_BOOKING_FAILED = 'UPDATE_BOOKING_FAILED';
export const DELETE_BOOKING_SUCCCES = 'DELETE_BOOKING_SUCCESS';
export const DELETE_BOOKING_FAILED = 'DELETE_BOOKING_FAILED';

export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS';
export const ADD_CUSTOMER_FAILED = 'ADD_CUSTOMER_FAILED';
export const ADD_CUSTOMER_ONGOING = 'ADD_CUSTOMER_ONGOING';
export const ADD_CUSTOMER_TIMEOUT = 'ADD_CUSTOMER_TIMEOUT';
export const CUSTOMER_LIST = 'CUSTOMER_LIST';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILED = 'UPDATE_CUSTOMER_FAILED';
export const DELETE_CUSTOMER_SUCCCES = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILED = 'DELETE_CUSTOMER_FAILED';

export const ADD_VISITING_SUCCESS = 'ADD_VISITING_SUCCESS';
export const ADD_VISITING_FAILED = 'ADD_VISITING_FAILED';
export const ADD_VISITING_ONGOING = 'ADD_VISITING_ONGOING';
export const ADD_VISITING_TIMEOUT = 'ADD_VISITING_TIMEOUT';
export const VISITING_LIST = 'VISITING_LIST';
export const UPDATE_VISITING_SUCCESS = 'UPDATE_VISITING_SUCCESS';
export const UPDATE_VISITING_FAILED = 'UPDATE_VISITING_FAILED';
export const DELETE_VISITING_SUCCCES = 'DELETE_VISITING_SUCCESS';
export const DELETE_VISITING_FAILED = 'DELETE_VISITING_FAILED';

export const ADD_MEMBER_SUCCESS = 'ADD_MEMBER_SUCCESS';
export const ADD_MEMBER_FAILED = 'ADD_MEMBER_FAILED';
export const ADD_MEMBER_ONGOING = 'ADD_MEMBER_ONGOING';
export const ADD_MEMBER_TIMEOUT = 'ADD_MEMBER_TIMEOUT';
export const MEMBER_LIST = 'MEMBER_LIST';
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_FAILED = 'UPDATE_MEMBER_FAILED';
export const DELETE_MEMBER_SUCCCES = 'DELETE_MEMBER_SUCCESS';
export const DELETE_MEMBER_FAILED = 'DELETE_MEMBER_FAILED';

export const ADD_KBM_SUCCESS = 'ADD_KBM_SUCCESS';
export const ADD_KBM_FAILED = 'ADD_KBM_FAILED';
export const ADD_KBM_ONGOING = 'ADD_KBM_ONGOING';
export const ADD_KBM_TIMEOUT = 'ADD_KBM_TIMEOUT';
export const KBM_LIST = 'KBM_LIST';
export const UPDATE_KBM_SUCCESS = 'UPDATE_KBM_SUCCESS';
export const UPDATE_KBM_FAILED = 'UPDATE_KBM_FAILED';
export const DELETE_KBM_SUCCCES = 'DELETE_KBM_SUCCESS';
export const DELETE_KBM_FAILED = 'DELETE_KBM_FAILED';

export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';
export const ADD_USER_ONGOING = 'ADD_USER_ONGOING';
export const ADD_USER_TIMEOUT = 'ADD_USER_TIMEOUT';
export const USER_LIST = 'USER_LIST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';
export const DELETE_USER_SUCCCES = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';

export const ADD_SCHEDULE_SUCCESS = 'ADD_SCHEDULE_SUCCESS';
export const ADD_SCHEDULE_FAILED = 'ADD_SCHEDULE_FAILED';
export const ADD_SCHEDULE_ONGOING = 'ADD_SCHEDULE_ONGOING';
export const ADD_SCHEDULE_TIMEOUT = 'ADD_SCHEDULE_TIMEOUT';
export const SCHEDULE_LIST = 'SCHEDULE_LIST';
export const UPDATE_SCHEDULE_SUCCESS = 'UPDATE_SCHEDULE_SUCCESS';
export const UPDATE_SCHEDULE_FAILED = 'UPDATE_SCHEDULE_FAILED';
export const DELETE_SCHEDULE_SUCCCES = 'DELETE_SCHEDULE_SUCCESS';
export const DELETE_SCHEDULE_FAILED = 'DELETE_SCHEDULE_FAILED';
