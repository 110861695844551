import React from 'react';
import PropTypes from 'prop-types';
// import { get } from 'react-scroll/modules/mixins/scroller';
import { get } from 'react-scroll/modules/mixins/scroller';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

const SidebarContent = ({ onClick, sidebarCollapse }) => {
  const hideSidebar = () => {
    onClick();
  };
  const getUser = JSON.parse(localStorage.getItem('LOCALSTORAGE_MYUSER'));
  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <SidebarLink
          title="Dashboard"
          icon="home"
          route="/dashboard"
          onClick={hideSidebar}
        />
        {/* <SidebarLink
          title="E-commerce Dashboard"
          icon="store"
          route="/e_commerce_dashboard"
          onClick={hideSidebar}
        /> */}
        {/* <SidebarLink
          title="App Dashboard"
          icon="smartphone"
          route="/app_dashboard"
          onClick={hideSidebar}
        />
        <SidebarLink
          title="Finance Dashboard"
          icon="rocket"
          route="/finance_dashboard"
          onClick={hideSidebar}
        /> */}
        {getUser.role === 'Kepala Stable'
        || getUser.role === 'Pelatih'
        || getUser.role === 'Kasie Pelatih' ? null : (
          <SidebarCategory
            title="Kemitraan"
            icon="user"
            sidebarCollapse={sidebarCollapse}
          >
            <SidebarLink title="Mitra" route="/partnership/index" />
            <SidebarLink title="Kategori" route="/categories/index" />
            <SidebarCategory title="Produk" sidebarCollapse={sidebarCollapse}>
              <SidebarLink
                title="Fasilitas Produk"
                route="/product/facility-product-list"
              />
              <SidebarLink title="Ghaidas Produk" route="/product/index" />
            </SidebarCategory>
          </SidebarCategory>
        )}

        {/* {getUser.role === 'Kepala Stable'
        || getUser.role === 'Pelatih'
        || getUser.role === 'Kasie Pelatih' ? null : (
          <SidebarLink
            title="Community"
            icon="store"
            route="/community/index"
            onClick={hideSidebar}
          />
        )} */}

        {/* {getUser.role !== 'Kepala Stable' ? (
          <SidebarLink
            title="Community"
            icon="store"
            route="/community/index"
            onClick={hideSidebar}
          />
        ) : null} */}

        {getUser.role === 'Kepala Stable'
        || getUser.role === 'Pelatih'
        || getUser.role === 'Kasie Pelatih' ? null : (
          <SidebarLink
            title="Kuda"
            icon="paw"
            route="/horse/index"
            onClick={hideSidebar}
          />
        )}
        {/* <SidebarLink
          title="Cage"
          icon="construction"
          route="/cage/index"
          onClick={hideSidebar}
        /> */}
        {/* <SidebarLink
          title="Penanggung Jawab"
          icon="license"
          route="/trainer/index"
          onClick={hideSidebar}
        /> */}
        <SidebarLink
          title="Schedule"
          icon="calendar-full"
          route="/schedule/index"
          onClick={hideSidebar}
        />
        <SidebarLink
          title="Transaksi"
          icon="book"
          route="/transaction/index"
          onClick={hideSidebar}
        />
        {getUser.role === 'Kepala Stable'
        || getUser.role === 'Kasie Pelatih' || getUser.role === 'Pelatih' ? null : (
          <SidebarLink
            title="Pelanggan"
            icon="dice"
            route="/customer/index"
            onClick={hideSidebar}
          />
        )}

        {getUser.role === 'Kepala Stable'
        || getUser.role === 'Pelatih'
        || getUser.role === 'Kasie Pelatih' ? null : (
          <SidebarLink
            title="Pesanan"
            icon="license"
            route="/booking/index"
            onClick={hideSidebar}
          />
        )}

        {getUser.role === 'Kepala Stable'
        || getUser.role === 'Pelatih'
        || getUser.role === 'Kasie Pelatih' ? null : (
          <SidebarLink
            title="Kuota Kunjungan"
            icon="chart-bars"
            route="/visiting/index"
            onClick={hideSidebar}
          />
        )}

        {getUser.role === 'Kepala Stable'
        || getUser.role === 'Pelatih'
        || getUser.role === 'Kasie Pelatih' ? null : (
          <SidebarLink
            title="Member"
            icon="list"
            route="/member/index"
            onClick={hideSidebar}
          />
        )}

        {/* {getUser.role === 'Kepala Stable'
        || getUser.role === 'Pelatih'
        || getUser.role === 'Kasie Pelatih' ? null : (
          <SidebarLink
            title="KBM"
            icon="screen"
            route="/kbm/index"
            onClick={hideSidebar}
          />
        )} */}
      </ul>

      {/* <ul className="sidebar__block">
        <SidebarLink
          title="Documentation"
          icon="text-align-justify"
          route="/documentation/introduction"
          onClick={hideSidebar}
        />
      </ul> */}
    </div>
  );
};

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
