/* eslint-disable no-useless-concat */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Alert } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderFileInputField from '@/shared/components/form/FileInput';
import renderSelectField from '@/shared/components/form/Select';
import { connect } from 'react-redux';
import Select from 'react-select';
import axios from 'axios';
import moment from 'moment';
import { Editor } from '@tinymce/tinymce-react';
import { useHistory } from 'react-router';
import { getFacility } from '../../../../redux/actions/facilityActions';
import { getCategories } from '../../../../redux/actions/categoriesActions';
import { getPartner } from '../../../../redux/actions/partnerActions';
// import renderDatePickerField from '@/shared/components/form/DatePicker';
// import { addProduct } from '../../../../redux/actions/productActions';
import { LOCALSTORAGE_TOKEN } from '../../../../redux/types';
import { PRODUCT_URL } from '../../../../utils/endPoint';

const ProductAddForm = ({
 errorMessage, status, dispatch, listProductPartner, listServiceCategory, listFacility, 
}) => {
  const history = useHistory();
  const [content, setContents] = useState('');
  const handleEditorChange = (contents) => {
    //  console.log("Content was updated:", contents);
    setContents(contents);
  };
  //  const editorRef = useRef(null);
  useEffect(() => {
    dispatch(getPartner());
    dispatch(getCategories());
    dispatch(getFacility());
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('name', [e.target.name][0].value);
    data.append('partner_id', [e.target.partner_id][0].value);
    data.append('service_category_id', [e.target.service_category_id][0].value);
    data.append('description', content);
    data.append('price_idr', [e.target.price_idr][0].value);
    data.append('unit', [e.target.unit][0].value);
    data.append('location', [e.target.location][0].value);
    if (e.target.is_available.checked) {
      data.append('is_available', true);
    } else {
      data.append('is_available', false);
    }
    // data.append('is_available', moment([e.target.is_available][0].checked));
    data.append('thumbnail', [e.target.thumbnail][0].files[0]);
    // data.append('facilities', [e.target.facilities][0].value);
    for (let i = 0; i < e.target.facilities.length; i += 1) {
      data.append('facilities', [e.target.facilities][0][i].value);
    }

    // addProduct(data, history);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios
      .post(PRODUCT_URL, data, { headers: { Authorization: `Token ${token}` } })
      .then(() => {
        // dispatch(addProductSuccess({ data: res.data }));
        // setTimeout(() => dispatch(addProductTimeout()), 3000);
        history.push('/product/index');
      })
      .catch((err) => {
        const error = err.response.data;
        // setTimeout(() => dispatch(addProductTimeout()), 3000);
        // dispatch(addProductFailed({ error }));
        console.log(error);
      });
  };
  // const [dataDescription, setDataDescription] = useState('');
  return (
    <form className="form form--horizontal" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form__form-group-label">Nama Produk</span>
        <div className="form__form-group-field">
          <Field 
            name="name"
            component="input"
            type="text"
            required
            placeholder="Nama Produk"
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Deskripsi</span>
        <div className="form__form-group-field">
          <Editor
            // onInit={(evt, editor) => (editorRef.current = editor)}
            apiKey="yb64t1vjyri2cansglhq8gaz1tai01e0dc0g3jlm9a4cjvdn"
            // initialValue="<p>This is the initial content of the editor.</p>"
            init={{
              height: 500,
              menubar: true,
              plugins: ['advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks code fullscreen textcolor ', 'insertdatetime media table paste code help wordcount', 'image code'],
              toolbar: 'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              // font_formats: 'Poppins',
            }}
            onEditorChange={handleEditorChange}
            outputFormat="html"
          />
        </div>
      </div>
      <div className="form__form-group-id-category">
        <div className="form__form-group">
          <span className="form__form-group-label">Mitra</span>
          <div className="form__form-group-field">
            <Field
              name="partner_id" 
              component={renderSelectField}
              type="text"
              required
              options={listProductPartner || [{ value: 'other', label: 'Other' }]}
              placeholder="Pilih Mitra Produk"
            />
          </div>
        </div>
      </div>
      <div className="form__form-group-id-category">
        <div className="form__form-group">
          <span className="form__form-group-label">Kategori</span>
          <div className="form__form-group-field">
            <Field 
              name="service_category_id"
              component={renderSelectField}
              type="text"
              required
              options={listServiceCategory || [{ value: 'other', label: 'Other' }]}
              placeholder="Pilih Kategori"
            />
          </div>
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">Fasilitas</span>
        <div className="form__form-group-field">
          <Select 
            isMulti
            required
            options={listFacility}
            name="facilities"
            classNamePrefix="react-select"
            className="react-select"
            placeholder="Fasilitas"
          />
        </div>
      </div>
      <div className="form__form-group-id-category">
        <div className="form__form-group">
          <span className="form__form-group-label">Unit</span>
          <div className="form__form-group-field">
            <Field
              name="unit"
              required
              component={renderSelectField}
              type="text"
              options={[
                { value: 'Room', label: 'Room' },
                { value: 'Person', label: 'Person' },
                { value: 'Family', label: 'Family' },
              ]}
              placeholder="Unit"
            />
          </div>
        </div>
      </div>
      <div className="form__form-group form__form-group-price">
        <span className="form__form-group-label">Harga</span>
        <div className="form__form-group-field">
          <Field
            name="price_idr" 
            component="input"
            type="number"
            required
            placeholder="12345"
          />
        </div>
      </div>
      <div className="form__form-group-price-discount">
        <div className="form__form-group form__form-group-price">
          <span className="form__form-group-label">Lokasi</span>
          <div className="form__form-group-field">
            <Field 
              name="location"
              component="input"
              type="text"
              required
              placeholder="Lokasi"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Tersedia </span>
          <div className="form__form-group-field">
            <Field
              name="is_available"
              component="input"
              type="checkbox"
              // defaultChecked={false}
              // type="datetime-local"
              // placeholder="YYYY-MM-DD"
            />
          </div>
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Upload Thumbnail</span>
        <div className="form__form-group-field">
          <Field 
            name="thumbnail"
            required
            component={renderFileInputField}
          />
        </div>
      </div>

      <Alert color={status === 'success' ? 'success' : 'danger'} isOpen={!!errorMessage || status === 'success'}>
        <p>
          <span className="bold-text">Congratulations!</span>
          {status === 'success' ? 'Product Successfully Added' : errorMessage}
        </p>
      </Alert>
      <ButtonToolbar className="form__button-toolbar">
        <Button color="primary" size="sm" type="submit">
          Submit
        </Button>
        {/* <Button type="button" size="sm" href="/product/index">Cancel</Button> */}
      </ButtonToolbar>
    </form>
  );
};

ProductAddForm.propTypes = {
  errorMessage: PropTypes.string,
  status: PropTypes.string.isRequired,
  listProductPartner: PropTypes.shape().isRequired,
  listServiceCategory: PropTypes.shape().isRequired,
  listFacility: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  // console.log('state add product', state.facility.listData);
  // console.log('state add product 2', state.categories.listData);
  const productPartner = state.partner.data ? state.partner.data.map(e => ({ value: `${e.id}`, label: e.name })) : [];
  const productCategory = state.categories.data ? state.categories.data.map(e => ({ value: `${e.id}`, label: e.name })) : [];
  const productFacilities = state.facility.data ? state.facility.data.map(e => ({ value: `${e.id}`, label: e.name })) : [];
  return {
    listProductPartner: productPartner,
    listServiceCategory: productCategory,
    listFacility: productFacilities,
  };
};
ProductAddForm.defaultProps = {
  errorMessage: '',
};

export default connect(
  mapStateToProps,
  null,
)(
  reduxForm({
    form: 'product_add_form', // a unique identifier for this form
  })(ProductAddForm),
);
