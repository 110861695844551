import React, { useState, useEffect } from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import { LOCALSTORAGE_MYUSER } from '../../../../redux/types';

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;


const ProfileMain = () => {
  const [myUser, setMyUser] = useState(null);

  useEffect(() => {
    const localdata = JSON.parse(localStorage.getItem(LOCALSTORAGE_MYUSER));
    setMyUser(localdata);
  }, []);

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody className="profile__card">
          <div className="profile__information">
            <div className="profile__avatar">
              <img src={myUser ? myUser.photo : Ava} alt="avatar" />
            </div>
            <div className="profile__data">
              <p className="profile__name">{myUser ? myUser.fullname : 'No Available Name'}</p>
              <p className="profile__work">{myUser ? myUser.role : 'No Available Role'}</p>
              <p className="profile__contact">{myUser ? myUser.email : 'No Available Email'}</p>
              <p className="profile__contact" dir="ltr">{myUser ? myUser.phone : 'No Available Phone'}</p>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProfileMain;
