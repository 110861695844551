/* eslint-disable no-sequences */
/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
 Button, ButtonToolbar, Card, CardBody, Col, 
//  Alert
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '@/shared/components/form/Select';
import { connect } from 'react-redux';
import { getUser } from '../../../../redux/actions/userActions';
import { getProduct } from '../../../../redux/actions/productActions';

const CustomerAddForm = ({
  // errorMessage,
  handleSubmit,
  // status,
  dispatch,
}) => {
  useEffect(() => {
    dispatch(getUser());
    dispatch(getProduct());
  }, [dispatch]);

  return (
    <Col xs={12} md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form product-edit" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">Nama</span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component="input"
                  type="text"
                  placeholder="Nama"
                  required
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Email</span>
              <div className="form__form-group-field">
                <Field
                  name="email"
                  component="input"
                  type="email"
                  placeholder="Email"
                  required
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Password</span>
              <div className="form__form-group-field">
                <Field
                  name="password"
                  component="input"
                  type="text"
                  placeholder="Password"
                  required
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">No. HP</span>
              <div className="form__form-group-field">
                <Field
                  name="phone"
                  component="input"
                  type="number"
                  placeholder="No. HP"
                  required
                />
              </div>
            </div>
            <div className="form__form-group-id-category">
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Role
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="role"
                    component={renderSelectField}
                    type="text"
                    required
                    options={[{ value: 'Pengunjung', label: 'Pengunjung' },
                    { value: 'Admin', label: 'Admin' },
                    { value: 'Kasie Pelatih', label: 'Kasie Pelatih' },
                    { value: 'Kepala Stable', label: 'Kepala Stable' },
                    { value: 'Pelatih', label: 'Pelatih' },
                    { value: 'Penanggung Jawab Kuda', label: 'Penanggung Jawab Kuda' },
                    { value: 'Administrasi Dan Keuangan', label: 'Administrasi Dan Keuangan' },
                    { value: 'Administrasi Dan Lapangan', label: 'Administrasi Dan Lapangan' },
                    { value: 'Pegawai', label: 'Pegawai' }]}
                    placeholder="Pilih Role"
                  />
                </div>
              </div>
            </div>
            {/* <div className="form__form-group">
              <span className="form__form-group-label">Role</span>
              <div className="form__form-group-field">
                <Field
                  name="role"
                  component="input"
                  type="text"
                  placeholder="Role"
                  required
                />
              </div>
            </div> */}
            
           
            {/* <div className="mb-3">
              <Alert
                color={status === 'success' ? 'success' : 'danger'}
                isOpen={!!errorMessage || status === 'success'}
              >
                <p className="p-2 pl-3">
                  {status === 'success'
                    ? 'Customer Successfully Added'
                    : errorMessage}
                </p>
              </Alert>
            </div> */}
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" size="sm" type="submit">
                Submit
              </Button>
              <Button type="button" size="sm" href="/customer/index">
                Cancel
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

const mapStateToProps = (state) => {
  const user = state.user.listData
    ? state.user.listData.map(e => ({ value: `${e.id}`, label: e.fullname }))
    : [];
    const product = state.product.listData
    ? state.product.listData.map(e => ({ value: `${e.id}`, label: e.name }))
    : [];
  return {
    listUser: user,
    listProduct: product,
  };
};
CustomerAddForm.propTypes = {
  // errorMessage: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  // status: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

CustomerAddForm.defaultProps = {
  // errorMessage: '',
};

export default connect(
  mapStateToProps,
  null,
)(
  reduxForm({
    form: 'customer_add_form', // a unique identifier for this form
  })(CustomerAddForm),
);
