import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import CageAddCard from './components/CageAddCard';

const AddCage = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Add Cage</h3>
      </Col>
    </Row>
    <Row>
      <CageAddCard />
    </Row>
  </Container>
);

export default AddCage;
