/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BookingListTable from './components/BookingListTable';
import CreateTableData from './components/CreateData';
import { getBooking } from '../../../redux/actions/bookingActions';
import { LOCALSTORAGE_MYUSER } from '../../../redux/types';

const BookingList = ({ dispatch, listData }) => {
  const BookingListTableData = CreateTableData(listData);
  // console.log(listData);

  useEffect(() => {
    // console.log('asd', listData);
    const localdata = JSON.parse(localStorage.getItem(LOCALSTORAGE_MYUSER));
    dispatch(getBooking(localdata.id));
  }, []);
  console.log('BookingListTableData', BookingListTableData);
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Daftar Pesanan</h3>
        </Col>
      </Row>
      <Row>
        <BookingListTable BookingListTableData={BookingListTableData} />
      </Row>
    </Container>
  );
};

BookingList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  listData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = state => ({
    listData: state.booking.listData,
  });

export default connect(mapStateToProps, null)(BookingList);
