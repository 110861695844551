import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import HorseAddCard from './components/HorseAddCard';

const AddHorse = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Kuda Baru</h3>
      </Col>
    </Row>
    <Row>
      <HorseAddCard />
    </Row>
  </Container>
);

export default AddHorse;
