import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import KbmAddCard from './components/KbmAddCard';

const AddKbm = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">KBM</h3>
      </Col>
    </Row>
    <Row>
      <KbmAddCard />
    </Row>
  </Container>
);

export default AddKbm;
