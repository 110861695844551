import { createAction } from 'redux-actions';
import axios from 'axios';

import {
    LOCALSTORAGE_TOKEN,
    ADD_CUSTOMER_SUCCESS, ADD_CUSTOMER_FAILED, ADD_CUSTOMER_ONGOING, ADD_CUSTOMER_TIMEOUT,
    CUSTOMER_LIST,
    UPDATE_CUSTOMER_SUCCESS, UPDATE_CUSTOMER_FAILED,
    DELETE_CUSTOMER_SUCCCES, DELETE_CUSTOMER_FAILED,
} from '../types';
import { CUSTOMER_URL } from '../../utils/endPoint';

export const addCustomerSuccess = createAction(ADD_CUSTOMER_SUCCESS);
export const addCustomerFailed = createAction(ADD_CUSTOMER_FAILED);
export const addCustomerOngoing = createAction(ADD_CUSTOMER_ONGOING);
export const addCustomerTimeout = createAction(ADD_CUSTOMER_TIMEOUT);
export const getCustomerSuccess = createAction(CUSTOMER_LIST);
export const updateCustomerSuccess = createAction(UPDATE_CUSTOMER_SUCCESS);
export const updateCustomerFailed = createAction(UPDATE_CUSTOMER_FAILED);
export const deleteCustomerSuccess = createAction(DELETE_CUSTOMER_SUCCCES);
export const deleteCustomerFailed = createAction(DELETE_CUSTOMER_FAILED);


export const addCustomer = (data, history) => async (dispatch) => {
    dispatch(addCustomerOngoing());
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    console.log('dari action Customer 1', token);
    axios.post(CUSTOMER_URL, data, { headers: { Authorization: `Token ${token}` } })
        .then((res) => {
            console.log('Customer data', res.data);
            dispatch(addCustomerSuccess({ data: res.data }));
            setTimeout(() => dispatch(addCustomerTimeout()), 3000);
            history.push('/customer/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addCustomerTimeout()), 3000);
        dispatch(addCustomerFailed({ error }));
    });
};

export const getCustomer = () => async (dispatch) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios.get(CUSTOMER_URL, { headers: { Authorization: `Token ${token}` } }) 
        .then((res) => {
        dispatch(
          getCustomerSuccess({ data: res.data.sort((a, b) => b.id - a.id) }),
        );
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addCustomerTimeout()), 3000);
        dispatch(addCustomerFailed({ error }));
    });
};

export const updateCustomer = (data, id, history) => async (dispatch) => {
    dispatch(addCustomerOngoing());
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios.patch(`${CUSTOMER_URL}${id}/`, data, { headers: { Authorization: `Token ${token}` } })
    .then((res) => {
        setTimeout(() => {
            dispatch(addCustomerTimeout());
        }, 3000);
        dispatch(updateCustomerSuccess({ data: res.data }));
        history.push('/customer/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addCustomerTimeout()), 3000);
        dispatch(updateCustomerFailed({ error }));
    });
};

export const deleteCustomer = (id, history) => async (dispatch) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    console.log('dari action Customer 4', token);
    axios.delete(`${CUSTOMER_URL}${id}/`, { headers: { Authorization: `Token ${token}` } })
    .then(() => {
        dispatch(deleteCustomerSuccess());
        history.push('/customer/index');
    })
    .catch((err) => {
        const error = err.response.data;
        dispatch(deleteCustomerFailed({ error }));
    });
};
