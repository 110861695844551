import { createAction } from 'redux-actions';
import axios from 'axios';

import {
    LOCALSTORAGE_TOKEN,
    ADD_COMMUNITY_SUCCESS, ADD_COMMUNITY_FAILED, ADD_COMMUNITY_ONGOING, ADD_COMMUNITY_TIMEOUT,
    COMMUNITY_LIST,
    UPDATE_COMMUNITY_SUCCESS, UPDATE_COMMUNITY_FAILED,
    DELETE_COMMUNITY_SUCCCES, DELETE_COMMUNITY_FAILED,
} from '../types';
import { COMMUNITY_URL } from '../../utils/endPoint';

export const addCommunitySuccess = createAction(ADD_COMMUNITY_SUCCESS);
export const addCommunityFailed = createAction(ADD_COMMUNITY_FAILED);
export const addCommunityOngoing = createAction(ADD_COMMUNITY_ONGOING);
export const addCommunityTimeout = createAction(ADD_COMMUNITY_TIMEOUT);
export const getCommunitySuccess = createAction(COMMUNITY_LIST);
export const updateCommunitySuccess = createAction(UPDATE_COMMUNITY_SUCCESS);
export const updateCommunityFailed = createAction(UPDATE_COMMUNITY_FAILED);
export const deleteCommunitySuccess = createAction(DELETE_COMMUNITY_SUCCCES);
export const deleteCommunityFailed = createAction(DELETE_COMMUNITY_FAILED);


export const addCommunity = (data, history) => async (dispatch) => {
    dispatch(addCommunityOngoing());
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    console.log('dari action Community 1', token);
    axios.post(COMMUNITY_URL, data, { headers: { Authorization: `Token ${token}` } })
        .then((res) => {
            console.log('Community data', res.data);
            dispatch(addCommunitySuccess({ data: res.data }));
            setTimeout(() => dispatch(addCommunityTimeout()), 3000);
            history.push('/community/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addCommunityTimeout()), 3000);
        dispatch(addCommunityFailed({ error }));
    });
};

export const getCommunity = () => async (dispatch) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios.get(COMMUNITY_URL, { headers: { Authorization: `Token ${token}` } }) 
        .then((res) => {
        dispatch(getCommunitySuccess({ data: res.data }));
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addCommunityTimeout()), 3000);
        dispatch(addCommunityFailed({ error }));
    });
};

export const updateCommunity = (data, id, history) => async (dispatch) => {
    dispatch(addCommunityOngoing());
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios.patch(`${COMMUNITY_URL}${id}/`, data, { headers: { Authorization: `Token ${token}` } })
    .then((res) => {
        setTimeout(() => {
            dispatch(addCommunityTimeout());
        }, 3000);
        dispatch(updateCommunitySuccess({ data: res.data }));
        history.push('/community/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addCommunityTimeout()), 3000);
        dispatch(updateCommunityFailed({ error }));
    });
};

export const deleteCommunity = (id, history) => async (dispatch) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    console.log('dari action Community 4', token);
    axios.delete(`${COMMUNITY_URL}${id}/`, { headers: { Authorization: `Token ${token}` } })
    .then(() => {
        dispatch(deleteCommunitySuccess());
        history.push('/community/index');
    })
    .catch((err) => {
        const error = err.response.data;
        dispatch(deleteCommunityFailed({ error }));
    });
};
