/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const kbmDetailHandler = (history, info) => {
  history.push({
    pathname: `/kbm/detail/${info.id}`, 
    state: { data: info },
  });
};


const CreateDataKbmListTable = (dataAPI) => {
  // console.log(dataAPI[0]?.product_detail?.id);
  console.log(dataAPI);
  // console.log(dataAPI[0]?.product_id);
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        accessor: 'id',
        Header: 'No',
        width: 20,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        accessor: 'date',
        Header: 'Tanggal Kegiatan',
      },
      {
        accessor: 'institusi',
        Header: 'Institusi',
      },
      {
        accessor: 'peserta',
        Header: 'Peserta',
      },
      {
        accessor: 'price',
        Header: 'Harga',
      },
      {
        accessor: 'action',
        Header: 'Action',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );


  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
        nama: '-',
        lokasi: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
            nama: '-',
            lokasi: '-',
          });
        } else {
          data.push({
            id: `${i + 1}`,
            date: dataAPI[i]?.date,
            product: `${dataAPI[i]?.product_detail?.name}`,
            seat_available: `${dataAPI[i]?.seat_available}`,
            seat_sale: `${dataAPI[i].seat_sale}`,
            

            action: [
              // eslint-disable-next-line no-loop-func
              // eslint-disable-next-line max-len
              (<Button key={i} size="sm" color="success" onClick={() => kbmDetailHandler(history, dataAPI[i])}>Detail</Button>),
            ],
          });
        }
      }
    }
  };

  rows();
  const KbmListTableData = { tableHeaderData: columns, tableRowsData: data };
  return KbmListTableData;
};

export default CreateDataKbmListTable;
