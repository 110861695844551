/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
 ButtonToolbar, Col, Row, Alert, Container, 
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import renderSelectField from '@/shared/components/form/Select';
import TimetableIcon from 'mdi-react/TimetableIcon';
import renderDateTimePickerField from '@/shared/components/form/DateTimePicker';
import axios from 'axios';
import {
  deleteSchedule,
  updateSchedule,
} from '../../../../redux/actions/scheduleActions';
import { getUser } from '../../../../redux/actions/userActions';
// import { getTrainer } from '../../../../redux/actions/trainerActions';
import { getHorse } from '../../../../redux/actions/horseActions';
import { LOCALSTORAGE_TOKEN } from '../../../../redux/types';

const ScheduleForm = (stateOri) => {
  const history = useHistory();
  const [isEditable, setEditable] = useState(false);
  const { updateResponse } = stateOri;
  const { dispatch } = stateOri;
  const { location } = history;
  const { state } = location;
  const { data } = state;
  const { initialize } = stateOri;
  const { listUser, listHorse } = stateOri;
  const { handleSubmit } = stateOri;
  const getUserLocal = JSON.parse(localStorage.getItem('LOCALSTORAGE_MYUSER'));
  const [pelatih, setPelatih] = useState([]);
  // const [valuePelatih, setValuePelatih] = useState([{ value: data?.trainerId, label: data?.trainer_detail?.fullname }]);
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN);

  useEffect(() => {
    initialize({
      ...data,
      start: new Date(`${data.date} ${data.start_time.replace(/-/g, '/')}`),
      end: new Date(`${data.date} ${data.end_time.replace(/-/g, '/')}`),
      status: { value: data.status, label: data.status },
      type: { value: data.type, label: data.type },
      user_id: { value: data.userId, label: data.user_detail?.fullname },
      trainer_id: {
        value: data.trainerId,
        label: data.trainer_detail?.fullname,
      },
      horse_id: { value: data.horseId, label: data.horse_detail?.name },
    });
  }, []);

  useEffect(() => {
    if (getUserLocal.role !== 'Kasie Pelatih') {
      const dataPelatih = async () => {
        const res = await axios.get(
          'https://api.ghaidas.com/api-ghaidas/userbyrole/Kasie Pelatih',
          { headers: { Authorization: `Token ${token}` } },
        );
        // console.log(res.data);
        setPelatih(res.data);
      };
      dataPelatih();
    } else {
      const dataPelatih = async () => {
        const res = await axios.get(
          'https://api.ghaidas.com/api-ghaidas/userbyrole/Pelatih',
          { headers: { Authorization: `Token ${token}` } },
        );
        // console.log(res.data);
        setPelatih(res.data);
      };
      dataPelatih();
    }
    dispatch(getUser());
    // dispatch(getTrainer());
    dispatch(getHorse());
  }, [dispatch]);

  const listPelatih = pelatih.length > 0
    && pelatih.map(item => ({
      value: item.id,
      label: item.fullname,
    }));
  console.log(data);
  const roles = getUserLocal.role;
  const options = [
    { value: 'Pending', label: 'Pending' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Reschedule', label: 'Reshedule' },
  ];
  const options2 = [
    { value: 'Approved', label: 'Approved' },
    { value: 'Reschedule', label: 'Reshedule' },
  ];
  const options3 = [
    { value: 'Reschedule', label: 'Reshedule' },
  ];
  let result;
  if (roles === 'Kepala Stable') {
    result = options2;
  } else if (roles === 'Administrasi Dan Keungan') {
    result = options;
  } else {
    result = options3;
  }

  return (
    <Container>
      <form className="form product-edit" onSubmit={handleSubmit}>
        <div className="form__form-group-id-category">
          <div className="form__form-group">
            <span className="form__form-group-label">Pelanggan</span>
            <div className="form__form-group-field">
              <Field
                name="user_id"
                component={renderSelectField}
                type="text"
                required
                options={listUser || [{ value: 'other', label: 'Other' }]}
                placeholder="Choose User"
                disabled={
                  getUserLocal.role === 'Kepala Stable'
                  || getUserLocal.role === 'Kasie Pelatih'
                    ? true
                    : !isEditable
                }
              />
            </div>
          </div>
        </div>
        {data.type === 'Berkuda' && (
          <div className="form__form-group-id-category">
            <div className="form__form-group">
              <span className="form__form-group-label">Horse</span>
              <div className="form__form-group-field">
                <Field
                  name="horse_id"
                  component={renderSelectField}
                  type="text"
                  required
                  options={
                        listHorse || [{ value: 'other', label: 'Other' }]
                      }
                  placeholder="Choose Horse"
                  disabled={
                        getUserLocal.role === 'Kepala Stable' || getUserLocal.role === 'Administrasi Dan Keuangan'
                          ? true
                          : !isEditable
                      }
                />
              </div>
            </div>
          </div>
            )}
        <div className="form__form-group-id-category">
          <div className="form__form-group">
            <span className="form__form-group-label">Type</span>
            <div className="form__form-group-field">
              <Field
                name="type"
                component={renderSelectField}
                type="text"
                options={[
                  { value: 'Memanah', label: 'Memanah' },
                  { value: 'Berkuda', label: 'Berkuda' },
                ]}
                placeholder="Choose Type"
                disabled={
                  getUserLocal.role === 'Kepala Stable'
                  || getUserLocal.role === 'Kasie Pelatih'
                    ? true
                    : !isEditable
                }
                required
              />
            </div>
          </div>
        </div>
        {
          getUserLocal.role === 'Administrasi Dan Keuangan' ? (
            <div className="form__form-group-id-category">
              <div className="form__form-group">
                <span className="form__form-group-label">Pelatih</span>
                <div className="form__form-group-field">
                  <Field
                    name="trainer_id"
                    component={renderSelectField}
                    type="text"
                    options={listPelatih}
                    placeholder="Choose Pelatih"
                    disabled={
                  getUserLocal.role === 'Kepala Stable' ? true : !isEditable
                }
                  />
                </div>
              </div>
            </div>
          ) : null
        }
        
        <div className="form__form-group">
          <span className="form__form-group-label">Start</span>
          <div className="form__form-group-field">
            <Field
              name="start"
              defaultValue={
                new Date(`${data.date} ${data.start_time.replace(/-/g, '/')}`)
              }
              component={renderDateTimePickerField}
              disabled={
                getUserLocal.role === 'Kepala Stable'
                || getUserLocal.role === 'Kasie Pelatih'
                  ? true
                  : !isEditable
              }
              placeholder="Masukan waktu mulai"
            />
            <div className="form__form-group-icon">
              <TimetableIcon />
            </div>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">End</span>
          <div className="form__form-group-field">
            <Field
              name="end"
              defaultValue={
                new Date(`${data.date} ${data.end_time.replace(/-/g, '/')}`)
              }
              disabled={
                getUserLocal.role === 'Kepala Stable'
                || getUserLocal.role === 'Kasie Pelatih'
                  ? true
                  : !isEditable
              }
              component={renderDateTimePickerField}
              placeholder="Masukan waktu mulai"
            />
            <div className="form__form-group-icon">
              <TimetableIcon />
            </div>
          </div>
        </div>
        <div className="form__form-group-id-category">
          <div className="form__form-group">
            <span className="form__form-group-label">Status</span>
            <div className="form__form-group-field">
              <Field
                name="status"
                component={renderSelectField}
                type="text"
                required
                options={
                  result
                }
                placeholder="Choose Status"
                disabled={
                  !isEditable
                }
              />
            </div>
          </div>
        </div>

        <ButtonToolbar className="form__button-toolbar">
          <Row className="mt-4">
            <Col md={12}>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setEditable(!isEditable)}
              >
                {' '}
                {!isEditable ? 'Edit' : 'Cancel'}
              </button>
              {isEditable && (
                <button type="submit" className="btn btn-primary">
                  {' '}
                  Update
                </button>
              )}
              {isEditable === false && (
                <button
                  type="button"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(deleteSchedule(data.id, history));
                  }}
                  className="btn btn-danger"
                >
                  {' '}
                  Delete
                </button>
              )}
            </Col>
          </Row>
        </ButtonToolbar>
      </form>
      {updateResponse ? (
        <Row>
          <Col md={12}>
            <Alert className="container mb-4 p-1" color="info">
              <p>Update Data berhasil</p>
            </Alert>
          </Col>
        </Row>
      ) : null}
    </Container>
  );
};

const mapStateToProps = state => ({
  errorMessage: state.partner.errorMessage,
  updateResponse: state.partner.updateResponse,
  updateData: state.partner.updateData,
  listUser: state.user.listData
    ? state.user.listData.map(e => ({ value: `${e.id}`, label: e.fullname }))
    : [],
  // listTrainer: state.trainer.listData ? state.trainer.listData.map(e => ({ value: `${e.id}`, label: e.user_detail?.fullname })) : [],
  listHorse: state.horse.listData
    ? state.horse.listData.map(e => ({ value: `${e.id}`, label: e.name }))
    : [],
});
const mapDispatchToProps = {
  updateScheduleAction: updateSchedule,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps, // bind account loading action creator
)(
  reduxForm({
    form: 'schedule_edit_form', // a unique identifier for this form
    enableReinitialize: false,
  })(ScheduleForm),
);
