/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart, Bar, Line, LabelList,
} from 'recharts';
import Panel from '@/shared/components/Panel';
import getTooltipStyles from '@/shared/helpers';

import axios from 'axios';
import { LOCALSTORAGE_TOKEN } from '../../../../redux/types';


// const data = [{ name: 'Mon', a: 590, b: 1400 },
//   { name: 'Tue', a: 868, b: 1506 },
//   { name: 'Wed', a: 1397, b: 989 },
//   { name: 'Thu', a: 1480, b: 1228 },
//   { name: 'Fri', a: 1520, b: 1100 },
//   { name: 'Sat', a: 1520, b: 1100 },
//   { name: 'Sun', a: 1400, b: 1700 }];

const ABTestingAnalytics = ({ dir, themeName }) => {
  const [data, setData] = useState([]);
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
  useEffect(() => {
    const getData = async () => {
      const res = await axios.get(
        'https://api.ghaidas.com/api-ghaidas/orderstats/',
        { headers: { Authorization: `Token ${token}` } },
      );
      setData(res.data);
    };
    getData();
  }, []);
  return (
    <Panel md={12} lg={12} xl={12} title="Revenue">
      <div dir="ltr">
        <ResponsiveContainer height={260}>
          <ComposedChart data={data} margin={{ top: 20, left: 15 }}>
            <XAxis dataKey="month" tickLine={false} padding={{ left: 20 }} />
            <YAxis tickLine={false} />
            <Tooltip />
            <CartesianGrid vertical={false} />
            <Bar
              dataKey="amount"
              name="Total"
              fill="#1C5EF5"
              barSize={20}
            >
              <LabelList dataKey="amount" position="top" />
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </Panel>
  );
  };
ABTestingAnalytics.propTypes = {
  dir: PropTypes.string.isRequired,
  themeName: PropTypes.string.isRequired,
};

export default connect(state => ({ themeName: state.theme.className }))(ABTestingAnalytics);
