/* eslint-disable default-case */
import {
    ADD_CUSTOMER_SUCCESS, ADD_CUSTOMER_FAILED, ADD_CUSTOMER_ONGOING, ADD_CUSTOMER_TIMEOUT,
    CUSTOMER_LIST,
    UPDATE_CUSTOMER_SUCCESS, UPDATE_CUSTOMER_FAILED,
} from '../types';

const defaultState = {
    data: '',
    updateData: '',
    isRegistrating: false,
    updateResponse: null,
    errorMessage: '',
    status: null,
    listData: [],
};

const customerReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_CUSTOMER_SUCCESS: {
            return {
                ...state,
                isRegistrating: false,
                data: 'success', 
            };
        }
        case ADD_CUSTOMER_FAILED: {
            return {
                ...state, 
                isRegistrating: false,
                errorMessage: action.payload.error,
            };
        }
        case ADD_CUSTOMER_ONGOING: {
            return {
                ...state,
                isRegistrating: false,
            };
        }
        case ADD_CUSTOMER_TIMEOUT: {
            return {
                ...state,
                data: '', 
                errorMessage: '',
            }; 
        }
        case UPDATE_CUSTOMER_SUCCESS: {
            return {
                ...state,
                isRegistrating: false,
                updateData: action.payload.data,
                status: 'success',
                // updateResponse: true,
            };
        }
        case UPDATE_CUSTOMER_FAILED: {
            return {
                ...state,
                errorMessage: action.payload.errorMessage,
                status: 'danger',
            };
        }
        case CUSTOMER_LIST: {
            return {
                ...state,
                listData: action.payload.data,
            };
        }
        default: {
            return {
              ...state,
            };
          }
    }
};

export default customerReducer;
