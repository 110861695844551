import { createAction } from 'redux-actions';
import axios from 'axios';

import {
    LOCALSTORAGE_TOKEN,
    ADD_HORSE_SUCCESS, ADD_HORSE_FAILED, ADD_HORSE_ONGOING, ADD_HORSE_TIMEOUT,
    HORSE_LIST,
    UPDATE_HORSE_SUCCESS, UPDATE_HORSE_FAILED,
    DELETE_HORSE_SUCCCES, DELETE_HORSE_FAILED,
} from '../types';
import { HORSE_URL } from '../../utils/endPoint';

export const addHorseSuccess = createAction(ADD_HORSE_SUCCESS);
export const addHorseFailed = createAction(ADD_HORSE_FAILED);
export const addHorseOngoing = createAction(ADD_HORSE_ONGOING);
export const addHorseTimeout = createAction(ADD_HORSE_TIMEOUT);
export const getHorseSuccess = createAction(HORSE_LIST);
export const updateHorseSuccess = createAction(UPDATE_HORSE_SUCCESS);
export const updateHorseFailed = createAction(UPDATE_HORSE_FAILED);
export const deleteHorseSuccess = createAction(DELETE_HORSE_SUCCCES);
export const deleteHorseFailed = createAction(DELETE_HORSE_FAILED);


export const addHorse = (data, history) => async (dispatch) => {
    dispatch(addHorseOngoing());
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    console.log('dari action Horse 1', token);
    axios.post(HORSE_URL, data, { headers: { Authorization: `Token ${token}` } })
        .then((res) => {
            console.log('Horse data', res.data);
            dispatch(addHorseSuccess({ data: res.data }));
            setTimeout(() => dispatch(addHorseTimeout()), 3000);
            history.push('/horse/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addHorseTimeout()), 3000);
        dispatch(addHorseFailed({ error }));
    });
};

export const getHorse = () => async (dispatch) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios.get(HORSE_URL, { headers: { Authorization: `Token ${token}` } }) 
        .then((res) => {
        dispatch(getHorseSuccess({ data: res.data }));
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addHorseTimeout()), 3000);
        dispatch(addHorseFailed({ error }));
    });
};

export const updateHorse = (data, id, history) => async (dispatch) => {
    dispatch(addHorseOngoing());
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios.patch(`${HORSE_URL}${id}/`, data, { headers: { Authorization: `Token ${token}` } })
    .then((res) => {
        setTimeout(() => {
            dispatch(addHorseTimeout());
        }, 3000);
        dispatch(updateHorseSuccess({ data: res.data }));
        history.push('/horse/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addHorseTimeout()), 3000);
        dispatch(updateHorseFailed({ error }));
    });
};

export const deleteHorse = (id, history) => async (dispatch) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    console.log('dari action Horse 4', token);
    axios.delete(`${HORSE_URL}${id}/`, { headers: { Authorization: `Token ${token}` } })
    .then(() => {
        dispatch(deleteHorseSuccess());
        history.push('/horse/index');
    })
    .catch((err) => {
        const error = err.response.data;
        dispatch(deleteHorseFailed({ error }));
    });
};
