/* eslint-disable arrow-parens */
import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import VisitingAddForm from './VisitingAddForm';
import { VISITING_URL } from '../../../../utils/endPoint';
import { LOCALSTORAGE_TOKEN } from '../../../../redux/types';




const VisitingCard = () => {
  const history = useHistory();
  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('date', [e.target.date][0].value);
    data.append('product_id', [e.target.product][0].value);
    data.append('seat_available', [e.target.seat_available][0].value);
    data.append('seat_sale', [e.target.seat_sale][0].value);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios
    .post(VISITING_URL, data, { headers: { Authorization: `Token ${token}` } })
    .then((res) => {
      console.log(res);
      history.push('/visiting/index');
    })
    .catch((error) => {
      console.log(error);
    });
  };
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Informasi Kuota Kunjungan</h5>
          </div>
          <VisitingAddForm
            handleSubmit={(e) => onSubmit(e)}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

export default (VisitingCard);
