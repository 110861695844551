/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, CardBody, Col } from 'reactstrap';
// import moment from 'moment';
import ScheduleAddForm from './ScheduleAddForm';
import { addSchedule } from '../../../../redux/actions/scheduleActions';
// import { getUser } from '../../../../redux/actions/userActions';


const ScheduleCard = ({
  addScheduleAction, errorMessage, status,
}) => (
  <Col md={12} lg={6}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">Main Information</h5>
        </div>
        <ScheduleAddForm
          status={status}
          errorMessage={errorMessage}
          addScheduleAction={addScheduleAction}
        />
      </CardBody>
    </Card>
  </Col>
);

ScheduleCard.propTypes = {
  addScheduleAction: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapDispatchToProps = {
  addScheduleAction: addSchedule,
};

const mapStateToProps = (state) => ({ 
  data: state.schedule.data,
  isRegistrating: state.schedule.isRegistrating,
  errorMessage: state.schedule.errorMessage,
  status: state.schedule.data,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ScheduleCard),
);
