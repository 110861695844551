/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, Form } from 'redux-form';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import { Button, Alert } from 'reactstrap';

const ResetPasswordForm = ({
  handleSubmit, errorMessage, status,
}) => (
  <Form className="form reset-password-form" onSubmit={handleSubmit}>
    {status && (
      <div className="form__form-group">
        <div className="mb-3">
          <Alert
            color={status === 'success' ? 'success' : 'danger'}
            isOpen={errorMessage !== '' || status === 'success'}
          >
            <div className="p-2">
              <p>{status === 'success' ? 'Permintaan reset password dikirim ke email, silahkan cek kontak masuk atau spam' : errorMessage}</p>
            </div>
          </Alert>
        </div>
      </div>
    )}
    <div className="form__form-group">
      <span className="form__form-group-label">Email</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <AlternateEmailIcon />
        </div>
        <Field
          name="email"
          id="email"
          component="input"
          type="email"
          placeholder="Email"
          className="input-without-border-radius"
        />
      </div>
    </div>
    <Button className="account__btn" color="primary">
      Reset Password
    </Button>
  </Form>
);

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  status: PropTypes.string.isRequired,
};

ResetPasswordForm.defaultProps = {
  errorMessage: '',
};

export default reduxForm({
  form: 'resetpassword_form',
})(ResetPasswordForm);
