import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import MemberCard from './components/MemberCard';

const MemberDetail = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Member</h3>
      </Col>
    </Row>
    <Row>
      <MemberCard />
    </Row>
  </Container>
);

export default MemberDetail;
