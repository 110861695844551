/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
 Container, ButtonToolbar, Row, Col, Spinner,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import renderSelectField from '@/shared/components/form/Select';
import axios from 'axios';
import { getUser } from '../../../../redux/actions/userActions';
import { MEMBER_URL, ORDER_URL } from '../../../../utils/endPoint';
import { LOCALSTORAGE_TOKEN } from '../../../../redux/types';
import { deleteMember } from '../../../../redux/actions/memberActions';

const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
const MemberForm = (stateOri) => {
    const [isEditable, setEditable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState([]);
    const { id } = useParams();
    const history = useHistory();
    const { dispatch } = stateOri;
    const { location } = history;
    const { state } = location;
    const { data } = state;
    const { initialize } = stateOri;
    // const { listProduct } = stateOri; 

    useEffect(() => {
      initialize({
 ...data, product: { value: data.product_id, label: data?.order_detail?.product_detail?.name }, status: data?.order_detail.status, payment_url: data?.order_detail.payment_url, customer: data?.order_detail?.user_detail?.fullname, type: { value: data.type, label: data.type }, day: { value: data.day_choice, label: data.day_choice },
});
    }, []);

    useEffect(() => {
      const Token = localStorage.getItem(LOCALSTORAGE_TOKEN);
      dispatch(getUser());
      axios.get('https://api.ghaidas.com/api-ghaidas/availableproduct/', 
    { headers: { Authorization: `Token ${token}` } }) 
    .then((res) => {
      const data1 = res.data.map(e => ({ value: `${e.id}`, label: e.name, pax: e.price_idr }));
      // console.log(res);
      // console.log(res.data);
      setProduct(data1);
      // setIdrProduct(res.data.map(item => item));
      // console.log(data);
      // console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
    }, [dispatch]);
    // console.log(data?.seat_sale);
    // console.log(data);

    return (
      <Container>
        <form 
          className="form product-edit"
          onSubmit={(e) => {
            e.preventDefault();     
                const data2Update = new FormData();
                data2Update.append('type', [e.target.type][0].value);
                data2Update.append('day_choice', [e.target.day][0].value);
                data2Update.append('start_date', [e.target.start_date][0].value);
                data2Update.append('end_date', [e.target.end_date][0].value);
                const updateDataMember2 = async () => {
                  setLoading(true);
                  try {
                    const res = await axios.patch(`${MEMBER_URL}${id}/`, data2Update, {
                      headers: {
                        Authorization: `Token ${token}`,
                      },
                    });
                    console.log(res);
                    setTimeout(() => {
                      setLoading(false);
                      history.push('/member/index');
                    }, 1000);
                  } catch (err) {
                    console.log(err.response);
                  }
                };
                updateDataMember2();
          }}
        >
          
          <div className="form__form-group-id-category">
            <div className="form__form-group">
              <span className="form__form-group-label">Produk</span>
              <div className="form__form-group-field">
                <Field 
                  name="product"
                  component={renderSelectField}
                  type="text"
                  required
                  options={product || [{ value: 'other', label: 'Other' }]}
                  placeholder="Pilih Produk"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Status</span>
            <div className="form__form-group-field">
              <Field
                name="status"
                component="input"
                type="text"
                required
                disabled
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Payment Url</span>
            <div className="form__form-group-field">
              <Field
                name="payment_url"
                component="input"
                type="text"
                required
                disabled
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Pelanggan</span>
            <div className="form__form-group-field">
              <Field
                name="customer"
                component="input"
                type="text"
                required
                disabled
              />
            </div>
          </div>
          <div className="form__form-group-id-category">
            <div className="form__form-group">
              <span className="form__form-group-label">Tipe</span>
              <div className="form__form-group-field">
                <Field
                  name="type"
                  component={renderSelectField}
                  type="text"
                  options={[
                      { value: 'internal', label: 'Internal' },
                      { value: 'external', label: 'Eksternal' },
                    ]}
                  placeholder="Pilih Tipe"
                  required
                  disabled={!isEditable}
                />
              </div>
            </div>
          </div>
          <div className="form__form-group-id-category">
            <div className="form__form-group">
              <span className="form__form-group-label">Hari</span>
              <div className="form__form-group-field">
                <Field
                  name="day"
                  component={renderSelectField}
                  type="text"
                  options={[
                      { value: 'Senin', label: 'Senin' },
                      { value: 'Selasa', label: 'Selasa' },
                      { value: 'Rabu', label: 'Rabu' },
                      { value: 'Kamis', label: 'Kamis' },
                      { value: 'Jumat', label: 'Jumat' },
                      { value: 'Sabtu', label: 'Sabtu' },
                      { value: 'Minggu', label: 'Minggu' },
                    ]}
                  placeholder="Pilih Hari"
                  required
                  disabled={!isEditable}
                />
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Tanggal Mulai</span>
            <div className="form__form-group-field">
              <Field
                name="start_date"
                component="input"
                type="date"
                placeholder="Tanggal Awal"
                required
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Tanggal Selesai</span>
            <div className="form__form-group-field">
              <Field
                name="end_date"
                component="input"
                type="date"
                placeholder="Tanggal Selesai"
                required
                disabled={!isEditable}
              />
            </div>
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Row className="mt-4">
              <Col md={12}>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setEditable(!isEditable)}
                >
                  {!isEditable ? 'Edit' : 'Cancel'}
                </button>
                {isEditable && (
                <button type="submit" className="btn btn-primary">
                  {
                    loading ? <Spinner size="sm">Loading...</Spinner> : 'Update'
}
                </button>
              )}
                {isEditable === false && (
                <button
                  type="button"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(deleteMember(data.id, history));
                  }}
                  className="btn btn-danger"
                >
                  {' '}
                  Delete
                </button>
              )}
              </Col>
            </Row>
          </ButtonToolbar>
        </form>       
      </Container>
    );
};
 

export default connect(
// bind account loading action creator
)(reduxForm({
form: 'member_edit_form', // a unique identifier for this form
enableReinitialize: true,
})(MemberForm));
