/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
 Button, ButtonToolbar, Card, CardBody, Col, Alert, 
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
// import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import renderFileInputField from '@/shared/components/form/FileInput';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { FACILITY_URL } from '../../../../../utils/endPoint';
import { LOCALSTORAGE_TOKEN } from '../../../../../redux/types';

const PartnerAddForm = () => {
  const history = useHistory();
  const [err, setErr] = useState();
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const data = new FormData();
    data.append('name', [e.target.name][0].value);
    data.append('icon', [e.target.icon][0].files[0]);
    axios
      .post(FACILITY_URL, data, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => {
        console.log(res.data);
        history.push('/product/facility-product-list');
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Col xs={12} md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form form--horizontal" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">Nama Fasilitas</span>
              <div className="form__form-group-field">
                {/* <div className="form__form-group-icon">
                <AccountOutlineIcon />
              </div> */}
                <Field
                  name="name"
                  component="input"
                  type="text"
                  placeholder="Nama Fasilitas"
                  required
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Upload Ikon</span>
              <div className="form__form-group-field">
                <Field name="icon" required component={renderFileInputField} />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">
                Submit
              </Button>
              <Button
                type="button"
                href="/product/facility-product-list"
              >
                Cancel
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};
export default reduxForm({
  form: 'facility_add_form', // a unique identifier for this form
})(PartnerAddForm);
