/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
 Container, Col, Row, Spinner, 
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CommunityListTable from './components/CommunityListTable';
import CreateTableData from './components/CreateData';
import { getCommunity } from '../../../redux/actions/communityActions';

const CommunityList = ({ dispatch, listData, loading }) => {
  const CommunityListTableData = CreateTableData(listData);

  useEffect(() => {
    dispatch(getCommunity());
  }, []);
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Komunitas</h3>
        </Col>
      </Row>
      <Row>{loading && <Spinner color="success" size="md" />}</Row>
      <Row>
        <CommunityListTable CommunityListTableData={CommunityListTableData} />
      </Row>
    </Container>
  );
};

CommunityList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  listData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    listData: state.community.data,
    loading: state.community.loading,
  });

export default connect(mapStateToProps, null)(CommunityList);
