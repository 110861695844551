import { createAction } from 'redux-actions';
import axios from 'axios';

import {
    LOCALSTORAGE_TOKEN,
    ADD_KBM_SUCCESS, ADD_KBM_FAILED, ADD_KBM_ONGOING, ADD_KBM_TIMEOUT,
    KBM_LIST,
    UPDATE_KBM_SUCCESS, UPDATE_KBM_FAILED,
    DELETE_KBM_SUCCCES, DELETE_KBM_FAILED,
} from '../types';
import { KBM_URL } from '../../utils/endPoint';

export const addKbmSuccess = createAction(ADD_KBM_SUCCESS);
export const addKbmFailed = createAction(ADD_KBM_FAILED);
export const addKbmOngoing = createAction(ADD_KBM_ONGOING);
export const addKbmTimeout = createAction(ADD_KBM_TIMEOUT);
export const getKbmSuccess = createAction(KBM_LIST);
export const updateKbmSuccess = createAction(UPDATE_KBM_SUCCESS);
export const updateKbmFailed = createAction(UPDATE_KBM_FAILED);
export const deleteKbmSuccess = createAction(DELETE_KBM_SUCCCES);
export const deleteKbmFailed = createAction(DELETE_KBM_FAILED);


export const addKbm = (data, history) => async (dispatch) => {
    dispatch(addKbmOngoing());
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    console.log('dari action Kbm 1', token);
    axios.post(KBM_URL, data, { headers: { Authorization: `Token ${token}` } })
        .then((res) => {
            console.log('Kbm data', res.data);
            dispatch(addKbmSuccess({ data: res.data }));
            setTimeout(() => dispatch(addKbmTimeout()), 3000);
            history.push('/kbm/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addKbmTimeout()), 3000);
        dispatch(addKbmFailed({ error }));
    });
};

export const getKbm = () => async (dispatch) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios.get(KBM_URL, { headers: { Authorization: `Token ${token}` } }) 
        .then((res) => {
        dispatch(getKbmSuccess({ data: res.data }));
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addKbmTimeout()), 3000);
        dispatch(addKbmFailed({ error }));
    });
};

export const updateKbm = (data, id, history) => async (dispatch) => {
    dispatch(addKbmOngoing());
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios.patch(`${KBM_URL}${id}/`, data, { headers: { Authorization: `Token ${token}` } })
    .then((res) => {
        setTimeout(() => {
            dispatch(addKbmTimeout());
        }, 3000);
        dispatch(updateKbmSuccess({ data: res.data }));
        history.push('/kbm/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addKbmTimeout()), 3000);
        dispatch(updateKbmFailed({ error }));
    });
};

export const deleteKbm = (id, history) => async (dispatch) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    console.log('dari action Kbm 4', token);
    axios.delete(`${KBM_URL}${id}/`, { headers: { Authorization: `Token ${token}` } })
    .then(() => {
        dispatch(deleteKbmSuccess());
        history.push('/kbm/index');
    })
    .catch((err) => {
        const error = err.response.data;
        dispatch(deleteKbmFailed({ error }));
    });
};
