import { createAction } from 'redux-actions';
import axios from 'axios';

import {
    LOCALSTORAGE_TOKEN,
    ADD_BOOKING_SUCCESS, ADD_BOOKING_FAILED, ADD_BOOKING_ONGOING, ADD_BOOKING_TIMEOUT,
    BOOKING_LIST,
    UPDATE_BOOKING_SUCCESS, UPDATE_BOOKING_FAILED,
    DELETE_BOOKING_SUCCCES, DELETE_BOOKING_FAILED,
} from '../types';
import { BOOKING_URL } from '../../utils/endPoint';

export const addBookingSuccess = createAction(ADD_BOOKING_SUCCESS);
export const addBookingFailed = createAction(ADD_BOOKING_FAILED);
export const addBookingOngoing = createAction(ADD_BOOKING_ONGOING);
export const addBookingTimeout = createAction(ADD_BOOKING_TIMEOUT);
export const getBookingSuccess = createAction(BOOKING_LIST);
export const updateBookingSuccess = createAction(UPDATE_BOOKING_SUCCESS);
export const updateBookingFailed = createAction(UPDATE_BOOKING_FAILED);
export const deleteBookingSuccess = createAction(DELETE_BOOKING_SUCCCES);
export const deleteBookingFailed = createAction(DELETE_BOOKING_FAILED);


export const addBooking = (data, history) => async (dispatch) => {
    dispatch(addBookingOngoing());
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    console.log('dari action Booking 1', token);
    axios.post(BOOKING_URL, data, { headers: { Authorization: `Token ${token}` } })
        .then((res) => {
            console.log('Booking data', res.data);
            dispatch(addBookingSuccess({ data: res.data }));
            setTimeout(() => dispatch(addBookingTimeout()), 3000);
            history.push('/booking/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addBookingTimeout()), 3000);
        dispatch(addBookingFailed({ error }));
    });
};

export const getBooking = () => async (dispatch) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios.get(BOOKING_URL, { headers: { Authorization: `Token ${token}` } }) 
        .then((res) => {
        dispatch(
          getBookingSuccess({ data: res.data.sort((a, b) => b.id - a.id) }),
        );
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addBookingTimeout()), 3000);
        dispatch(addBookingFailed({ error }));
    });
};

export const updateBooking = (data, id, history) => async (dispatch) => {
    dispatch(addBookingOngoing());
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios.patch(`${BOOKING_URL}${id}/`, data, { headers: { Authorization: `Token ${token}` } })
    .then((res) => {
        setTimeout(() => {
            dispatch(addBookingTimeout());
        }, 3000);
        dispatch(updateBookingSuccess({ data: res.data }));
        history.push('/booking/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addBookingTimeout()), 3000);
        dispatch(updateBookingFailed({ error }));
    });
};

export const deleteBooking = (id, history) => async (dispatch) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    console.log('dari action Booking 4', token);
    axios.delete(`${BOOKING_URL}${id}/`, { headers: { Authorization: `Token ${token}` } })
    .then(() => {
        dispatch(deleteBookingSuccess());
        history.push('/booking/index');
    })
    .catch((err) => {
        const error = err.response.data;
        dispatch(deleteBookingFailed({ error }));
    });
};
