/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
 ButtonToolbar, Col, Row, Alert, Container, 
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import MapMarkerMultipleIcon from 'mdi-react/MapMarkerMultipleIcon';
import renderFileInputField from '@/shared/components/form/FileInput';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactHtmlParser from 'react-html-parser';
import {
  deleteCommunity,
  updateCommunity,
} from '../../../../redux/actions/communityActions';

const DetailCommunity = (stateOri) => {
  const history = useHistory();
  const [isEditable, setEditable] = useState(false);
  const { updateResponse, updateData } = stateOri;
  const { dispatch } = stateOri;
  const { location } = history;
  const { state } = location;
  const { data } = state;
  const { initialize } = stateOri;

  if (updateData) {
    initialize(updateData);
  }
  useEffect(() => {
    initialize(data);
  }, []);

  return (
    <Container>
      <form
        className="form product-edit"
        onSubmit={(e) => {
          e.preventDefault();
          const updatedData = new FormData();
          updatedData.append('nama', [e.target.nama][0].value);
          updatedData.append('lokasi', [e.target.lokasi][0].value);
          updatedData.append('keterangan', [e.target.keterangan][0].value);
          // updatedData.append('image', [e.target.image][0].files[0]);
          if ([e.target.image][0].files[0]) {
            updatedData.append('image', [e.target.image][0].files[0]);
          } else if (data.image && data.image.file) {
            updatedData.append('image', data.image.file);
          }
          dispatch(updateCommunity(updatedData, data.id, history));
        }}
      >
        {isEditable ? (
          <>
            <div className="form__form-group">
              <span className="form__form-group-label">Community Name</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <AccountOutlineIcon />
                </div>
                <Field
                  name="nama"
                  component="input"
                  type="text"
                  placeholder="Community Name"
                  disabled={!isEditable}
                  required
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Location</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <MapMarkerMultipleIcon />
                </div>
                <Field
                  name="lokasi"
                  component="input"
                  type="text"
                  placeholder="Location"
                  disabled={!isEditable}
                  required
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Description</span>
              <Field name="keterangan" component="input" type="hidden" />
              <div className="form__form-group-field">
                <CKEditor
                  editor={ClassicEditor}
                  data={data.keterangan}
                  // onReady={(editor) => {
                  //         // You can store the "editor" and use when it is needed.
                  //         console.log('Editor is ready to use!', editor);
                  //     }}
                  onChange={(event, editor) => {
                    const dataKeterangan = editor.getData();
                    initialize({ keterangan: dataKeterangan });
                  }}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Upload photo</span>
              <div className="form__form-group-field">
                <Field name="image" component={renderFileInputField} />
                <span>
                  {data.image ? (
                    <a className="ml-2" target="blank" href={data.image}>
                      {data.image.split('?')[0]}
                    </a>
                  ) : (
                    'Tidak Ada'
                  )}
                </span>
              </div>
            </div>
          </>
        ) : (
          <div className="product-card">
            {/* start image */}
            <div className="product-gallery">
              <button type="button" className="product-gallery__current-img">
                <img src={data.image} alt="product-img" />
              </button>
            </div>
            {/* batas image */}
            <div className="product-card__info">
              <h3 className="product-card__title">{data.nama}</h3>
              {/* <h1 className="product-card__price"><span>Rp</span>{data.price_idr}</h1> */}
              <div>
                <span>
                  <h4 className="product-card__title">
                    <MapMarkerMultipleIcon /> {data.lokasi}
                  </h4>
                </span>
              </div>
              <p className="typography-message">
                {ReactHtmlParser(data.keterangan)}
              </p>
            </div>
          </div>
        )}

        <ButtonToolbar className="form__button-toolbar">
          <Row className="mt-4">
            <Col md={12}>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setEditable(!isEditable)}
              >
                {' '}
                {!isEditable ? 'Edit' : 'Cancel'}
              </button>
              {isEditable && (
                <button type="submit" className="btn btn-primary">
                  {' '}
                  Update
                </button>
              )}
              {isEditable === false && (
                <button
                  type="button"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(deleteCommunity(data.id, history));
                  }}
                  className="btn btn-danger"
                >
                  {' '}
                  Delete
                </button>
              )}
            </Col>
          </Row>
        </ButtonToolbar>
      </form>
      {updateResponse ? (
        <Row>
          <Col md={12}>
            <Alert className="container mb-4 p-1" color="info">
              <p>Update Data berhasil</p>
            </Alert>
          </Col>
        </Row>
      ) : null}
    </Container>
  );
};

const mapStateToProps = state => ({
  errorMessage: state.partner.errorMessage,
  updateResponse: state.partner.updateResponse,
  updateData: state.partner.updateData,
});
const mapDispatchToProps = {
  updateCommunityAction: updateCommunity,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps, // bind account loading action creator
)(
  reduxForm({
    form: 'commnuty_edit_form', // a unique identifier for this form
    enableReinitialize: true,
  })(DetailCommunity),
);
