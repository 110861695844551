import React from 'react';
// import { Route } from 'react-router-dom';
import Layout from '../../../Layout/index';
import Account from './Account';
import OnLineMarketingDashboard from '../../../Dashboards/OnLineMarketing/index';
import AppDashboard from '../../../Dashboards/App/index';
import Partnership from './Partnership';
import Categories from './Categories';
import Product from './Product';
import Community from './Community';
import PrivateRoute from '../../../../shared/components/PrivateRoute';
import Horse from './Horse';
import Cage from './Cage';
import Booking from './Booking';
import Schedule from './Schedule';
import Customer from './Customer';
import Visiting from './Visiting';
import Member from './Member';
import Kbm from './Kbm';
import Transaction from './Transaction';

export default () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <PrivateRoute path="/dashboard" component={OnLineMarketingDashboard} />
      <PrivateRoute exact path="/app_dashboard" component={AppDashboard} />
      <PrivateRoute path="/partnership" component={Partnership} />
      <PrivateRoute path="/categories" component={Categories} />
      <PrivateRoute path="/product" component={Product} />
      <PrivateRoute path="/community" component={Community} />
      <PrivateRoute path="/account" component={Account} />
      <PrivateRoute path="/horse" component={Horse} />
      <PrivateRoute path="/cage" component={Cage} />
      <PrivateRoute path="/booking" component={Booking} />
      <PrivateRoute path="/customer" component={Customer} />
      <PrivateRoute path="/visiting" component={Visiting} />
      <PrivateRoute path="/member" component={Member} />
      <PrivateRoute path="/kbm" component={Kbm} />
      <PrivateRoute path="/schedule" component={Schedule} />
      <PrivateRoute path="/transaction" component={Transaction} />

    </div>
  </div>
);
