/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Alert } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderFileInputField from '../../../../shared/components/form/FileInput';

const ProfileSettings = ({
  handleSubmit, status, errorMessage, reset, dataUser,
}) => {
  console.log('ANDA BERADA DI HP', dataUser);
  return (
    <form className="form form--vertical wizard__form" onSubmit={handleSubmit}>
      <Alert
        color={status === 'success' ? 'success' : 'danger'}
        isOpen={errorMessage !== '' || status === 'success'}
      >
        <p className="p-2 pl-3">{status === 'success' ? 'Berhasil Update Akun' : errorMessage}</p>
      </Alert>
      <div className="form__form-group">
        <span className="form__form-group-label"><b>Email*</b></span>
        <div className="form__form-group-field">
          <Field
            name="email"
            component="input"
            type="email"
            placeholder="example@email.com"
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label"><b>Nama Lengkap*</b></span>
        <div className="form__form-group-field">
          <Field
            name="fullname"
            component="input"
            type="text"
            placeholder="Nama Lengkap"
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label"><b>No. Telepon Aktif*</b></span>
        <div className="form__form-group-field">
          <Field
            name="phone"
            component="input"
            type="number"
            placeholder="No. Whatsapp"
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label"><b>Photo</b></span>
        <div className="form__form-group-field">
          <Field
            name="photo"
            component={renderFileInputField}
          />
        </div>
        <div>
          {dataUser && dataUser.photo ? (
            <a
              className="ml-2"
              target="blank"
              href={dataUser.photo}
            >
              {dataUser.photo.split('?')[0]}
            </a>
                        ) : 'Tidak Ada'}
        </div>
      </div>
      <ButtonToolbar className="form__button-toolbar">
        <Button color="primary" type="submit">Simpan</Button>
        <Button type="button" onClick={reset}>
          Batal
        </Button>
      </ButtonToolbar>
    </form>
);
};

ProfileSettings.propTypes = {
  dataUser: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'profile_settings_form', // a unique identifier for this form
})(ProfileSettings);
