/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, CardBody, Col } from 'reactstrap';
import CageAddForm from './CageAddForm';
import { addCage } from '../../../../redux/actions/cageActions';

const onSubmit = (e, handleFunction, history) => {
  e.preventDefault();
  const data = new FormData();
  data.append('name', [e.target.name][0].value);
  data.append('number', [e.target.number][0].value);
  handleFunction(data, history);
};

const CageCard = ({
  addCageAction, history, errorMessage, status,
}) => ( 
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">Main Information</h5>
        </div>
        <CageAddForm
          status={status}
          errorMessage={errorMessage}
          handleSubmit={(e) => onSubmit(e, addCageAction, history)}
        />
      </CardBody>
    </Card>
  </Col>
);

CageCard.propTypes = {
  addCageAction: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapDispatchToProps = {
  addCageAction: addCage,
};

const mapStateToProps = (state) => ({ 
  data: state.cage.data,
  isRegistrating: state.cage.isRegistrating,
  errorMessage: state.cage.errorMessage,
  status: state.cage.data,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CageCard),
);
