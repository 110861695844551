import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import PartnerAddCard from './components/imageAddCard';

const AddImageProduct = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Gambar Produk</h3>
        {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
          information
        </h3> */}
      </Col>
    </Row>
    <Row>
      <PartnerAddCard />
    </Row>
  </Container>
);

export default AddImageProduct;
