/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
 ButtonToolbar, Col, Row, Alert, Container, Spinner,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import Select from 'react-select';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import {
  deleteHorse,
  updateHorse,
} from '../../../../redux/actions/horseActions';
import { getCage } from '../../../../redux/actions/cageActions';

import { LOCALSTORAGE_TOKEN } from '../../../../redux/types';
import { HORSE_URL } from '../../../../utils/endPoint';

const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
const HorseForm = (stateOri) => {
  const [penanggungJawab, setPenanggungJawab] = useState([]);
  const [detailHorse, setDetailHorse] = useState({});
  const [content, setContents] = useState('');
  // const [optionHorse, setOptionHorse] = ({ value: detailHorse?.pic_detail?.id, label: detailHorse?.pic_detail?.fullname });
  const handleEditorChange = (contents) => {
    setContents(contents);
  };
  const { id } = useParams();
  const history = useHistory();
  const [isEditable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const { updateResponse, updateData } = stateOri;
  const { dispatch } = stateOri;
  const { location } = history;
  const { state } = location;
  const { data } = state;

  const { initialize } = stateOri;

  if (updateData) {
    initialize(updateData);
  }
  useEffect(() => {
    initialize(data);
  }, []);

  useEffect(() => {
    const getPenanggungJawabKuda = async () => {
      const res = await axios.get(
        'https://api.ghaidas.com/api-ghaidas/userbyrole/Penanggung Jawab Kuda',
        { headers: { Authorization: `Token ${token}` } },
      );
      // console.log(res.data);
      setPenanggungJawab(res.data);
    };
    getPenanggungJawabKuda();
    dispatch(getCage());
    // dispatch(getTrainer());
  }, [dispatch]);

  useEffect(() => {
    const getDetailHorse = async () => {
      const res = await axios.get(HORSE_URL + id, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      console.log(res.data);
      setDetailHorse(res.data);
    };
    getDetailHorse();
  }, []);
  const listPj = penanggungJawab.length > 0
    && penanggungJawab.map(item => ({
      value: item.id,
      label: item.fullname,
    }));

  const indexPj = penanggungJawab.map(item => item.id).indexOf(data.picId);
  console.log(indexPj);
  return (
    <Container>
      <form
        className="form product-edit"
        onSubmit={(e) => {
          e.preventDefault();
          const dataUpdate = new FormData();
          dataUpdate.append('name', [e.target.name][0].value);
          dataUpdate.append('description', content);
          dataUpdate.append('status', detailHorse.status);
          dataUpdate.append('picId', detailHorse.picId);
          // console.log([e.target.status][0].value);
          // console.log(detailHorse.status, detailHorse.picId);
          const updateDataHorse = async () => {
            setLoading(true);
            try {
              await axios.patch(`${HORSE_URL}${id}/`, dataUpdate, {
                headers: {
                  Authorization: `Token ${token}`,
                },
              });
              // console.log(res);
              setTimeout(() => {
                setLoading(false);
                history.push('/horse/index');
              }, 1000);
            } catch (err) {
              console.log(err.response);
            }
          };
          updateDataHorse();
          // dispatch(updateHorse(updatedData, data.id, history));
        }}
      >
        <div className="form__form-group">
          <span className="form__form-group-label">Nama Kuda</span>
          <div className="form__form-group-field">
            <Field
              name="name"
              component="input"
              type="text"
              placeholder="Nama Kuda"
              required
              disabled={!isEditable}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Deskripsi</span>
          <div className="form__form-group-field">
            <Editor
              // onInit={(evt, editor) => (editorRef.current = editor)}
              apiKey="yb64t1vjyri2cansglhq8gaz1tai01e0dc0g3jlm9a4cjvdn"
              // initialValue="<p>This is the initial content of the editor.</p>"
              init={{
                height: 500,
                // width: 1,
                menubar: true,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen textcolor ',
                  'insertdatetime media table paste code help wordcount',
                  'image code',
                ],
                toolbar:
                  'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                // font_formats: 'Poppins',
              }}
              onEditorChange={handleEditorChange}
              outputFormat="html"
              value={detailHorse.description}
              onChange={e => setDetailHorse(prev => ({
                  ...prev,
                  description: e.target.value,
                }))}
              disabled={!isEditable}
            />
          </div>
        </div>
        <div className="form__form-group-id-category">
          <div className="form__form-group">
            <span className="form__form-group-label">Status</span>
            <div className="form__form-group-field">
              <Select
                options={[
                  { value: 'Available Horse', label: 'Available Horse' },
                  {
                    value: 'Unavailable Horse',
                    label: 'Unavailable Horse',
                  },
                ]}
                value={{ value: detailHorse.status, label: detailHorse.status }}
                onChange={(selectedOption) => {
                  setDetailHorse(prev => ({
                    ...prev,
                    status: selectedOption.value,
                  }));
                }}
                isDisabled={!isEditable}
                styles={{
                  control: provided => ({
                    ...provided,
                    width: 400, // ganti 200 dengan nilai lebar yang diinginkan
                  }),
                }}
                // value={ value: detailHorse.status, label: }
              />
            </div>
          </div>
        </div>
        {indexPj !== -1 ? (
          <div className="form__form-group-id-category">
            <div className="form__form-group">
              <span className="form__form-group-label">Penanggung Jawab</span>
              <div className="form__form-group-field">
                <Select
                  options={listPj}
                  defaultValue={listPj[indexPj]}
                  onChange={(e) => {
                    setDetailHorse(prev => ({
                      ...prev,
                      picId: e.value,
                    }));
                  }}
                  isDisabled={!isEditable}
                  styles={{
                    control: provided => ({
                      ...provided,
                      width: 400, // ganti 200 dengan nilai lebar yang diinginkan
                    }),
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
        {indexPj === -1 ? (
          <div className="form__form-group-id-category">
            <div className="form__form-group">
              <span className="form__form-group-label">Penanggung Jawab</span>
              <div className="form__form-group-field">
                <Select
                  options={listPj}
                  defaultValue={listPj[indexPj]}
                  onChange={(e) => {
                    setDetailHorse(prev => ({
                      ...prev,
                      picId: e.value,
                    }));
                  }}
                  isDisabled={!isEditable}
                  styles={{
                    control: provided => ({
                      ...provided,
                      width: 400, // ganti 200 dengan nilai lebar yang diinginkan
                    }),
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}

        <ButtonToolbar className="form__button-toolbar">
          <Row className="mt-4">
            <Col md={12}>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setEditable(!isEditable)}
              >
                {!isEditable ? 'Edit' : 'Cancel'}
              </button>
              {isEditable && (
                <button type="submit" className="btn btn-primary">
                  {
                    loading ? <Spinner size="sm">Loading...</Spinner> : 'Update'
}
                </button>
              )}
              {isEditable === false && (
                <button
                  type="button"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(deleteHorse(data.id, history));
                  }}
                  className="btn btn-danger"
                >
                  {' '}
                  Delete
                </button>
              )}
            </Col>
          </Row>
        </ButtonToolbar>
      </form>
      {updateResponse ? (
        <Row>
          <Col md={12}>
            <Alert className="container mb-4 p-1" color="info">
              <p>Update Data berhasil</p>
            </Alert>
          </Col>
        </Row>
      ) : null}
    </Container>
  );
};

const mapStateToProps = state => ({
  errorMessage: state.partner.errorMessage,
  updateResponse: state.partner.updateResponse,
  updateData: state.partner.updateData,
  listCage: state.cage.listData
    ? state.cage.listData.map(e => ({ value: `${e.id}`, label: e.name }))
    : [],
  // listTrainer: state.trainer.listData
  //   ? state.trainer.listData.map(e => ({
  //       value: `${e.id}`,
  //       label: e.user_detail?.fullname,
  //     }))
  //   : [],
});
const mapDispatchToProps = {
  updateHorseAction: updateHorse,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps, // bind account loading action creator
)(
  reduxForm({
    form: 'horse_edit_form', // a unique identifier for this form
    enableReinitialize: true,
  })(HorseForm),
);
