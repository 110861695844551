/* eslint-disable no-sequences */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
 Button, ButtonToolbar, Card, CardBody, Col, Alert, 
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '@/shared/components/form/Select';
import { connect } from 'react-redux';
import moment from 'moment';
import { getUser } from '../../../../redux/actions/userActions';
import { getProduct } from '../../../../redux/actions/productActions';
import { LOCALSTORAGE_TOKEN } from '../../../../redux/types';

const BookingAddForm = ({
  errorMessage,
  handleSubmit,
  status,
  dispatch,
  listUser,
  listProduct,
}) => {
  const [product, setProduct] = useState([]);
  const [list, setList] = useState('');
  const [countPax, setCountPax] = useState('');
  const [paxx, setPaxx] = useState(0);
  // Product
  const [pilihProduct, setPilihProduct] = useState('');
  const [quotaProduct, setQuotaProduct] = useState([]);
  const [idProduct, setIdProduct] = useState('');
  // Seat
  const [ketSeat, setKetSeat] = useState('');
  const [activeSeat, setActiveSeat] = useState(false);

  const [dateBooking, setDateBooking] = useState('');

  const timeoutRef = useRef(null);
  const [errorPax, setErrorPax] = useState(false);
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
  useEffect(() => {
    dispatch(getUser());
    dispatch(getProduct());
    axios
      .get('https://api.ghaidas.com/api-ghaidas/availableproduct/', {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => {
        const data = res.data.map(e => ({
          value: `${e.id}`,
          label: e.name,
          pax: e.price_idr,
          category: e.service_category_detail.name,
        }));
        // console.log(res);
        console.log(res.data);
        setProduct(data);
        // setIdrProduct(res.data.map(item => item));
        // console.log(data);
        // console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dispatch]);

  useEffect(() => {
    // console.log(product);
    const getQuota = async () => {
      const data = await axios.get(
        'https://api.ghaidas.com/api-ghaidas/quotaproduct/',
        { headers: { Authorization: `Token ${token}` } },
      );
      setQuotaProduct(data.data);
    };
    getQuota();
  }, []);
  const pax = () => Number(countPax) * paxx;
  // console.log(countPax);
  const otherForms = Array.from({ length: countPax }, (_, i) => (
    <>
      {/* {countPax.forEach(() => ( */}
      <div className="form__form-group">
        <span className="form__form-group-label">Umur {i + 1}</span>
        <div className="form__form-group-field">
          <Field
            name={`age${i + 1}`}
            component="input"
            type="number"
            placeholder="Umur"
            required
          />
        </div>
      </div>
      {/* ))} */}
      <div className="form__form-group">
        <span className="form__form-group-label">Berat Badan {i + 1}</span>
        <div className="form__form-group-field">
          <Field
            name={`weight${i + 1}`}
            component="input"
            type="number"
            placeholder="Berat Badan"
            required
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Gender {i + 1}</span>
        <div className="form__form-group-field">
          <Field
            name={`gender${i + 1}`}
            component={renderSelectField}
            type="text"
            required
            options={[
              { value: 'Laki-laki', label: 'Laki-laki' },
              { value: 'Wanita', label: 'Wanita' },
            ]}
            placeholder="Gender"
          />
        </div>
        <hr />
      </div>
    </>
  ));
  useEffect(() => {
    const checkSeatAvailability = async (seatData, id) => {
      // eslint-disable-next-line no-shadow, radix
      const seat = seatData.find(
        // eslint-disable-next-line radix, no-shadow
        seat => seat?.product_detail?.id === parseInt(id),
      );
      // console.log(seatData);
      if (idProduct === '') {
        setActiveSeat(false);
        return;
      }

      console.log(seat?.date);
      if (seat?.date === dateBooking) {
        console.log('masukkk');
        if (seat.seat_available === seat.seat_sale) {
          // console.log('Sudah full');
          setActiveSeat(true);
          setKetSeat('Maaf Seat Sudah Full');
          if (timeoutRef.current) clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(() => {
            setActiveSeat(false);
          }, 3000);
          return;
        }
        // console.log(`Seat Masih Tersedia ${seat.seat_available - seat.seat_sale}`);
        setActiveSeat(true);
        setKetSeat(
          `Seat Masih Tersedia ${seat.seat_available - seat.seat_sale}`,
        );
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          setActiveSeat(false);
        }, 3000);
      } else {
          // console.log('Maaf Seat Sementara Masih Kosong');
          setActiveSeat(true);
          setKetSeat(`Maaf Seat Untuk Produk Ini Tidak Tersedia Pada Tanggal ${dateBooking}`);
          if (timeoutRef.current) clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(() => {
            setActiveSeat(false);
          }, 3000);
      }
    };
    checkSeatAvailability(quotaProduct, idProduct);
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [idProduct]);
  return (
    <Col xs={12} md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form product-edit" onSubmit={handleSubmit}>
            {/* <div className="form__form-group">
              <span className="form__form-group-label">Level</span>
              <div className="form__form-group-field">
                <Field
                  name="level"
                  component="input"
                  type="text"
                  placeholder="level"
                  required
                />
              </div>
            </div> */}
            {
              // quotaProduct.filter(item => (item?.product_detail?.id === idProduct ? 'yuhu' : 'tes'))
            }
            {activeSeat ? (
              <Alert
                color={
                  ketSeat === 'Maaf Seat Sudah Full'
                  || ketSeat
                    === `Maaf Seat Untuk Produk Ini Tidak Tersedia Pada Tanggal ${dateBooking}`
                    ? 'warning'
                    : 'success'
                }
                className="p-3 col-12 rounded-md"
              >
                {ketSeat}
              </Alert>
            ) : null}
            {/* <Alert color="warning" className="p-3 col-12">
              This is a warning alert — check it out!
            </Alert> */}
            <div className="form__form-group">
              <span className="form__form-group-label">Tanggal Booking</span>
              <div className="form__form-group-field">
                <Field
                  name="date"
                  component="input"
                  type="date"
                  placeholder="Tanggal Booking"
                  onChange={e => setDateBooking(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="form__form-group-id-category">
              <div className="form__form-group">
                <span className="form__form-group-label">Produk</span>
                <div className="form__form-group-field">
                  <Field
                    name="product"
                    component={renderSelectField}
                    type="text"
                    options={product || [{ value: 'other', label: 'Other' }]}
                    placeholder="Pilih Produk"
                    onChange={(e) => {
                      setPaxx(e.pax);
                      setPilihProduct(e.category);
                      setIdProduct(e.value);
                      // console.log(e.pax);
                    }}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="form__form-group-id-category">
              <div className="form__form-group">
                <span className="form__form-group-label">Pelanggan</span>
                <div className="form__form-group-field">
                  <Field
                    name="customer"
                    component={renderSelectField}
                    type="text"
                    options={listUser || [{ value: 'other', label: 'Other' }]}
                    placeholder="Pilih Pelanggan"
                    required
                    // onChange={e => console.log(e.value)}
                  />
                </div>
              </div>
            </div>
            <div className="form__form-group-id-category">
              <div className="form__form-group">
                <span className="form__form-group-label">Status</span>
                <div className="form__form-group-field">
                  <Field
                    name="status"
                    component={renderSelectField}
                    type="text"
                    options={[
                      { value: 'Belum Lunas', label: 'Belum Lunas' },
                      { value: 'Lunas', label: 'Lunas' },
                      { value: 'Pending', label: 'Pending' },
                      { value: 'Expired', label: 'Expired' },
                      { value: 'Failure', label: 'Failure' },
                    ]}
                    placeholder="Status"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Jumlah Pax</span>
              <div className="form__form-group-field">
                <Field
                  min={0}
                  name="pax"
                  component="input"
                  type="number"
                  // placeholder={
                  required
                  onChange={(e) => {
                    if (Number(e.target.value) > 5) {
                      setErrorPax(true);
                      setCountPax(5);
                    } else {
                      setErrorPax(false);
                      setCountPax(Number(e.target.value));
                    }
                    // console.log(pax());
                  }}
                  disabled={!paxx}
                />
              </div>
              {errorPax ? <p style={{ color: 'red' }}>Maksikum 5</p> : null}
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">Jumlah</span>
              <div className="form__form-group-field">
                <Field
                  min={0}
                  name="amount"
                  component="input"
                  type="number"
                  placeholder={pax()}
                  // required
                  disabled
                />
              </div>
            </div>

            {pilihProduct === 'Archery' || pilihProduct === 'Horse Riding'
              ? otherForms
              : null}

            <div className="form__form-group-id-category">
              <div className="mb-3">
                <span className="form__form-group-label">Manual</span>
                <div className="form__form-group-field">
                  <Field
                    name="is_manual_payment"
                    component="input"
                    type="checkbox"
                  />
                </div>
              </div>
            </div>

            {/* <div className="form__form-group-id-category">
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Nama Penanggung Jawab
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="user_id"
                    component={renderSelectField}
                    type="text"
                    required
                    options={listUser || [{ value: 'other', label: 'Other' }]}
                    placeholder="Pilih Nama Penanggung Jawab"
                  />
                </div>
              </div>
            </div> */}
            <div className="mb-3">
              <Alert
                color={status === 'success' ? 'success' : 'danger'}
                isOpen={!!errorMessage || status === 'success'}
              >
                <p className="p-2 pl-3">
                  {status === 'success'
                    ? 'Booking Successfully Added'
                    : errorMessage}
                </p>
              </Alert>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                size="sm"
                type="submit"
                disabled={
                  !!(
                    ketSeat === 'Maaf Seat Sudah Full'
                    || ketSeat === 'Maaf Seat Untuk Produk Ini Tidak Tersedia'
                  )
                }
              >
                Submit
              </Button>
              <Button type="button" size="sm" href="/booking/index">
                Cancel
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

const mapStateToProps = (state) => {
  // console.log(state);
  const user = state.user.listData
    ? state.user.listData.map(e => ({ value: `${e.id}`, label: e.fullname }))
    : [];
  const product = state.product.listData
    ? state.product.listData.map(e => ({ value: `${e.id}`, label: e.name }))
    : [];
  return {
    listUser: user,
    listProduct: product,
  };
};
BookingAddForm.propTypes = {
  errorMessage: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  listUser: PropTypes.shape().isRequired,
  listProduct: PropTypes.shape().isRequired,
};

BookingAddForm.defaultProps = {
  errorMessage: '',
};

export default connect(
  mapStateToProps,
  null,
)(
  reduxForm({
    form: 'booking_add_form', // a unique identifier for this form
  })(BookingAddForm),
);
