/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
import { useMemo, useState } from 'react';
import { Badge, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';

const bookingDetailHandler = (history, info) => {
  history.push({
    pathname: `/booking/detail/${info.id}`,
    state: { data: info },
  });
};

const CreateDataBookingListTable = (dataAPI) => {
  const [copy, setCopy] = useState(false);
  const [confirm, setConfirm] = useState();

  console.log(dataAPI);
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        accessor: 'id',
        Header: 'No',
        width: 20,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        accessor: 'date',
        Header: 'Tanggal',
      },
      {
        accessor: 'name',
        Header: 'Produk',
      },
      {
        accessor: 'customer',
        Header: 'Pelanggan',
      },
      {
        accessor: 'code',
        Header: 'Kode Booking',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        accessor: 'status',
        Header: 'Status',
      },
      {
        accessor: 'phone',
        Header: 'No. HP',
      },
      // {
      //   accessor: 'age',
      //   Header: 'Umur',
      // },
      // {
      //   accessor: 'weight',
      //   Header: 'Berat Badan',
      // },
      // {
      //   accessor: 'gender',
      //   Header: 'Gender',
      // },
      // {
      //   accessor: 'payment',
      //   Header: 'Link Pembayaran',
      // },
      {
        accessor: 'action',
        Header: 'Action',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
      // {
      //   accessor: 'status',
      //   Header: 'Status',
      //   disableGlobalFilter: true,
      //   disableSortBy: true,
      //   width: 110,
      // },
    ],
    [],
  );

  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
        nama: '-',
        lokasi: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
            nama: '-',
            lokasi: '-',
          });
        } else {
          data.push({
            id: `${i + 1}`,
            date: dataAPI[i]?.created_at,
            customer: dataAPI[i].order_detail?.user_detail?.fullname,
            name: dataAPI[i].order_detail?.product_detail?.name,
            status: dataAPI[i].order_detail?.status,
            code: dataAPI[i].order_detail?.order_id,
            phone: dataAPI[i].order_detail?.user_detail?.phone,
            weight: dataAPI[i].order_detail?.description_1.slice(3),
            age: dataAPI[i].order_detail?.description_2.slice(4),
            gender: dataAPI[i].order_detail?.description_3.slice(7),
            // is_manual_payment: dataAPI[i].order_detail?.is_manual_payment,
            // payment: dataAPI[i].order_detail?.payment_url,

            action: [
              // eslint-disable-next-line no-loop-func
              // eslint-disable-next-line max-len
              <Button
                key={i}
                size="sm"
                color="success"
                onClick={() => bookingDetailHandler(history, dataAPI[i])}
              >
                Detail
              </Button>,
              // (<CopyToClipboard key={`${i}-payment`} data-clipboard-text={dataAPI[i].order_detail?.payment_url}>Salin Link Pembayaran</CopyToClipboard>),
              dataAPI[i].order_detail?.status === 'Lunas' ? (
                <Button color="warning" size="sm">Lunas</Button>
              ) : (
                <Button size="sm" color="primary">
                  <CopyToClipboard
                    text={dataAPI[i].order_detail?.payment_url}
                    onCopy={() => {
                      setCopy(true);
                      setConfirm(dataAPI[i].id);
                      setTimeout(() => {
                        setCopy(false);
                      }, [5000]);
                    }}
                  >
                    <span>
                      {copy && confirm === dataAPI[i].id ? 'Copied ' : 'Bayar '}
                      <FaCopy />
                    </span>
                  </CopyToClipboard>
                </Button>
              ),
            ],
          });
        }
      }
    }
  };

  rows();
  const BookingListTableData = { tableHeaderData: columns, tableRowsData: data };
  return BookingListTableData;
};

export default CreateDataBookingListTable;
