/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import HorseListTable from './components/ImageHorseListTable';
import CreateTableData from './components/CreateData';

const ImageHorseList = () => {
  const history = useHistory();
  const { location } = history;
  const { state } = location;
  const { data } = state;
  const HorseListTableData = CreateTableData(data?.images);
  console.log(data);
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Daftar Gambar Kuda</h3>
        </Col>
      </Row>
      <Row>
        <HorseListTable HorseListTableData={HorseListTableData} />
      </Row>
    </Container>
  );
};

export default (ImageHorseList);
