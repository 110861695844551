import {
    FACILITY_LIST,
} from '../types';

const defaultState = {
    data: [],
    loading: true,
  };
  


const FacilityReducer = (state = defaultState, action) => {
    switch (action.type) {
        case FACILITY_LIST: {
          return {
            ...state,
            data: action.payload.data,
            loading: false,
          };
        }
        default: {
          return {
            ...state,
          };
        }
      }
};

export default FacilityReducer;
