import React from 'react';
import { Switch } from 'react-router-dom';
// import DetailCategories from '../../../ServiceCategory/DetailCategory';
import PrivateRoute from '../../../../shared/components/PrivateRoute';
import MemberList from '../../../Member/MemberList';

import AddMember from '../../../Member/AddMember';
import MemberDetail from '../../../Member/Detail';

export default () => (
  <Switch>
    <PrivateRoute path="/member/index" component={MemberList} />
    <PrivateRoute path="/member/add-member" component={AddMember} />
    <PrivateRoute path="/member/detail/:id" component={MemberDetail} />

  </Switch>
);
