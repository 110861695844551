/* eslint-disable default-case */
import {
    ADD_CAGE_SUCCESS, ADD_CAGE_FAILED, ADD_CAGE_ONGOING, ADD_CAGE_TIMEOUT,
    CAGE_LIST,
    UPDATE_CAGE_SUCCESS, UPDATE_CAGE_FAILED,
} from '../types';

const defaultState = {
    data: '',
    updateData: '',
    isRegistrating: false,
    updateResponse: null,
    errorMessage: '',
    status: null,
    listData: [],
};

const cageReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_CAGE_SUCCESS: {
            return {
                ...state,
                isRegistrating: false,
                data: 'success', 
            };
        }
        case ADD_CAGE_FAILED: {
            return {
                ...state, 
                isRegistrating: false,
                errorMessage: action.payload.error,
            };
        }
        case ADD_CAGE_ONGOING: {
            return {
                ...state,
                isRegistrating: false,
            };
        }
        case ADD_CAGE_TIMEOUT: {
            return {
                ...state,
                data: '', 
                errorMessage: '',
            }; 
        }
        case UPDATE_CAGE_SUCCESS: {
            return {
                ...state,
                isRegistrating: false,
                updateData: action.payload.data,
                status: 'success',
                // updateResponse: true,
            };
        }
        case UPDATE_CAGE_FAILED: {
            return {
                ...state,
                errorMessage: action.payload.errorMessage,
                status: 'danger',
            };
        }
        case CAGE_LIST: {
            return {
                ...state,
                listData: action.payload.data,
            };
        }
        default: {
            return {
              ...state,
            };
          }
    }
};

export default cageReducer;
