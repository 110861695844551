/* eslint-disable max-len */
/* eslint-disable no-sequences */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
 Button, ButtonToolbar, Card, CardBody, Col, Alert, Spinner,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '@/shared/components/form/Select';
import { Editor } from '@tinymce/tinymce-react';
import { connect } from 'react-redux';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { getCage } from '../../../../redux/actions/cageActions';

import { HORSE_URL } from '../../../../utils/endPoint';
import { LOCALSTORAGE_TOKEN } from '../../../../redux/types';

const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
const HorseAddForm = ({ 
  errorMessage, status, dispatch,  
}) => {
  const [penanggungJawab, setPenanggungJawab] = useState([]);
  const history = useHistory();
  const [content, setContents] = useState('');
  const [loading, setLoading] = useState(false);
  const handleEditorChange = (contents) => {
    setContents(contents);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    // console.log([e.target.status][0].value);
    const data = new FormData();
    data.append('name', [e.target.name][0].value);
    data.append('description', content);
    data.append('status', [e.target.status][0].value);
    data.append('picId', [e.target.listpj][0].value);

    const sendData = async () => {
      setLoading(true);
      try {
       await axios.post(HORSE_URL, data, {
          headers: { Authorization: `Token ${token}` },
        });
        setTimeout(() => {
          setLoading(false);
          history.push('/horse/index');
        }, [3000]);
      } catch (err) {
        console.log(err);
      }
    };
    sendData();
  };

  useEffect(() => {
    const getPenanggungJawabKuda = async () => {
      const res = await axios.get(
        'https://api.ghaidas.com/api-ghaidas/userbyrole/Penanggung Jawab Kuda', { headers: { Authorization: `Token ${token}` } },
      );
      console.log(res.data);
      setPenanggungJawab(res.data);
    };
    getPenanggungJawabKuda();
    dispatch(getCage());
  }, [dispatch]);

  const listPj = penanggungJawab.length > 0
    && penanggungJawab.map(item => ({
      value: item.id,
      label: item.fullname,
    }));
    // console.log(listPj);
  return (
    <Col xs={12} md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form product-edit" onSubmit={onSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">Nama Kuda</span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component="input"
                  type="text"
                  placeholder="Nama Kuda"
                  required
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Deskripsi</span>
              <div className="form__form-group-field">
                <Editor
                  // onInit={(evt, editor) => (editorRef.current = editor)}
                  apiKey="yb64t1vjyri2cansglhq8gaz1tai01e0dc0g3jlm9a4cjvdn"
                  // initialValue="<p>This is the initial content of the editor.</p>"
                  init={{
                    height: 500,
                    // width: 1,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen textcolor ',
                      'insertdatetime media table paste code help wordcount',
                      'image code',
                    ],
                    toolbar:
                      'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    // font_formats: 'Poppins',
                  }}
                  onEditorChange={handleEditorChange}
                  outputFormat="html"
                />
              </div>
            </div>
            <div className="form__form-group-id-category">
              <div className="form__form-group">
                <span className="form__form-group-label">Status</span>
                <div className="form__form-group-field">
                  <Field
                    name="status"
                    component={renderSelectField}
                    type="text"
                    required
                    options={
                      status || [
                        { value: 'Available Horse', label: 'Available Horse' },
                        {
                          value: 'Unavailable Horse',
                          label: 'Unavailable Horse',
                        },
                      ]
                    }
                    // options={[{}]}
                    placeholder="Status"
                  />
                </div>
              </div>
            </div>
            <div className="form__form-group-id-category">
              <div className="form__form-group">
                <span className="form__form-group-label">Penanggung Jawab</span>
                <div className="form__form-group-field">
                  <Field
                    name="listpj"
                    component={renderSelectField}
                    type="text"
                    required
                    options={listPj || [{ value: 'other', label: 'Other' }]}
                    placeholder="Pilih Penanggung Jawab"
                  />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <Alert
                color={status === 'success' ? 'success' : 'danger'}
                isOpen={!!errorMessage || status === 'success'}
              >
                <p className="p-2 pl-3">
                  {status === 'success'
                    ? 'Horse Successfully Added'
                    : errorMessage}
                </p>
              </Alert>
            </div>

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                size="md"
                type="submit"
                className="d-flex justify-content-center align-items-center"
                // disabled={loading}
              >
                {
                  loading ? <Spinner size="sm">Loading...</Spinner> : 'Submit'
                }
                
              </Button>
              <Button type="button" size="md" href="/horse/index">
                Cancel
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

const mapStateToProps = (state) => {
  const cage = state.cage.listData ? state.cage.listData.map(e => ({ value: `${e.id}`, label: e.name })) : [];

  return {
    listCage: cage,
 
  };
};
HorseAddForm.defaultProps = {
  errorMessage: '',
};

HorseAddForm.propTypes = {
  errorMessage: PropTypes.string,
  // handleSubmit: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  // listCage: PropTypes.shape().isRequired,
  // listTrainer: PropTypes.shape().isRequired,
};

HorseAddForm.defaultProps = {
  errorMessage: '',
};

export default connect(
  mapStateToProps,
  null,
)(
  reduxForm({
    form: 'horse_add_form', // a unique identifier for this form
  })(HorseAddForm),
);
