import React from 'react';
import {
 Card, CardBody, Col,
} from 'reactstrap';
import DetailCage from './CageForm';


const CageCard = () => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">Cage</h5>
          <div className="products-list__btn-toolbar-top" />
        </div>
        <DetailCage />
      </CardBody>
    </Card>
  </Col>
  );

export default CageCard;
