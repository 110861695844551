import { createAction } from 'redux-actions';
import axios from 'axios';

import {
    LOCALSTORAGE_TOKEN,
    ADD_CAGE_SUCCESS, ADD_CAGE_FAILED, ADD_CAGE_ONGOING, ADD_CAGE_TIMEOUT,
    CAGE_LIST,
    UPDATE_CAGE_SUCCESS, UPDATE_CAGE_FAILED,
    DELETE_CAGE_SUCCCES, DELETE_CAGE_FAILED,
} from '../types';
import { CAGE_URL } from '../../utils/endPoint';

export const addCageSuccess = createAction(ADD_CAGE_SUCCESS);
export const addCageFailed = createAction(ADD_CAGE_FAILED);
export const addCageOngoing = createAction(ADD_CAGE_ONGOING);
export const addCageTimeout = createAction(ADD_CAGE_TIMEOUT);
export const getCageSuccess = createAction(CAGE_LIST);
export const updateCageSuccess = createAction(UPDATE_CAGE_SUCCESS);
export const updateCageFailed = createAction(UPDATE_CAGE_FAILED);
export const deleteCageSuccess = createAction(DELETE_CAGE_SUCCCES);
export const deleteCageFailed = createAction(DELETE_CAGE_FAILED);


export const addCage = (data, history) => async (dispatch) => {
    dispatch(addCageOngoing());
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    console.log('dari action Cage 1', token);
    axios.post(CAGE_URL, data, { headers: { Authorization: `Token ${token}` } })
        .then((res) => {
            console.log('Cage data', res.data);
            dispatch(addCageSuccess({ data: res.data }));
            setTimeout(() => dispatch(addCageTimeout()), 3000);
            history.push('/cage/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addCageTimeout()), 3000);
        dispatch(addCageFailed({ error }));
    });
};

export const getCage = () => async (dispatch) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios.get(CAGE_URL, { headers: { Authorization: `Token ${token}` } }) 
        .then((res) => {
        dispatch(getCageSuccess({ data: res.data }));
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addCageTimeout()), 3000);
        dispatch(addCageFailed({ error }));
    });
};

export const updateCage = (data, id, history) => async (dispatch) => {
    dispatch(addCageOngoing());
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios.patch(`${CAGE_URL}${id}/`, data, { headers: { Authorization: `Token ${token}` } })
    .then((res) => {
        setTimeout(() => {
            dispatch(addCageTimeout());
        }, 3000);
        dispatch(updateCageSuccess({ data: res.data }));
        history.push('/cage/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addCageTimeout()), 3000);
        dispatch(updateCageFailed({ error }));
    });
};

export const deleteCage = (id, history) => async (dispatch) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    console.log('dari action Cage 4', token);
    axios.delete(`${CAGE_URL}${id}/`, { headers: { Authorization: `Token ${token}` } })
    .then(() => {
        dispatch(deleteCageSuccess());
        history.push('/cage/index');
    })
    .catch((err) => {
        const error = err.response.data;
        dispatch(deleteCageFailed({ error }));
    });
};
