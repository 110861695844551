import React from 'react';
import { Switch } from 'react-router-dom';
import Profile from '../../../Account/Profile/index';
import EmailConfirmation from '../../../Account/EmailConfimation/index';
import PrivateRoute from '../../../../shared/components/PrivateRoute';

export default () => (
  <Switch>
    <PrivateRoute path="/account/profile" component={Profile} />
    <PrivateRoute path="/account/email_confirmation" component={EmailConfirmation} />
  </Switch>
);
