import React from 'react';
import { Switch } from 'react-router-dom';
// import DetailCategories from '../../../ServiceCategory/DetailCategory';
import PrivateRoute from '../../../../shared/components/PrivateRoute';
import BookingList from '../../../Booking/BookingList';

import AddBooking from '../../../Booking/AddBooking';
import TransactionList from '../../../Transaction/TransactionList';

const Transaction = () => (
  <Switch>
    <PrivateRoute path="/transaction/index" component={TransactionList} />
    {/* <PrivateRoute path="/booking/add-booking" component={AddBooking} />
    <PrivateRoute path="/booking/detail/:id" component={BookingDetail} /> */}
  </Switch>
);

export default Transaction;
