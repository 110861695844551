import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';
import { Modal, Button } from 'reactstrap'; 
import EditSchedule from './EditSchedule';


const localizer = momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(Calendar);

const formats = {
  dayFormat: (date, culture) => localizer.format(date, 'DD', culture),
};

const CalendarComponent = ({ small, events: propEvents, dir }) => {
  const [events, setEvents] = useState(propEvents);
  const [selectedEvent, setSelectedEvent] = useState(null);
  // const [formData, setFormData] = useState(null);


  const moveEvent = ({ event, start, end }) => {
    const idx = events.indexOf(event);
    const updatedEvent = { ...event, start, end };

    const nextEvents = [...events];
    nextEvents.splice(idx, 1, updatedEvent);

    setEvents(nextEvents);
  };

  const eventStyleGetter = (event) => {
    let color;

    switch (event.priority) {
      case 'kpending':
        color = '#CB1C8D';
        break;

      case 'kapproved':
        color = '#70bbfd';
        break;

      case 'ppending':
        color = '#332FD0';
        break;

      case 'papproved':
        color = '#10A19D';
        break;

      case 'rreschedule':
        color = '#008068';
        break;

      case 'preschedule':
        color = '#6aff00';
        break;

      default:
        color = '$color-additional';
        break;
    }

    const style = {
      backgroundColor: color,
      border: 'none',
    };

    return {
      style,
    };
  };
 
  // const dayStyleGetter = (date) => {
  //   let style = {};
  //   if (date.getDay() === 1) {
  //     style = {
  //       backgroundColor: 'red',
  //     };
  //   }
  //   return {
  //     style,
  //   };
  // };

  const dayStyleGetter = (date) => {
    let style = {};
  
    if (date.getDay() !== 1) {
      const currentHour = new Date().getHours();
      const currentMinute = new Date().getMinutes();
  
      if (
        (currentHour === 11 && currentMinute >= 0)
        || (currentHour > 11 && currentHour < 13)
        || (currentHour === 13 && currentMinute <= 30)
      ) {
        style = {
          backgroundColor: 'yellow',
        };
      }
    }
  
    if (date.getDay() === 1) {
      style = {
        backgroundColor: 'red',
      };
    }
    return {
      style,
    };
  };
  

  const openModal = (event) => {
    setSelectedEvent(event);
    console.log(event);
  };

  const closeModal = () => {
    setSelectedEvent(null);
  };

  return (
    <div className={`calendar${small ? ' calendar--small' : ''}`}>
      <Modal isOpen={selectedEvent !== null} onRequestClose={closeModal}>
        {selectedEvent && (
        <div>
          {/* <h3>{selectedEvent.title}</h3> */}
          {/* <p>Mulai: {moment(selectedEvent.start).format('LLLL')}</p>
          <p>Selesai: {moment(selectedEvent.end).format('LLLL')}</p> */}
          {/* <ScheduleForm event={selectedEvent} formData={formData} /> */}
          <EditSchedule SchId={selectedEvent.id} />
          <Button
            type="button"
            size="sm"
            onClick={closeModal} 
            style={{ marginTop: '10px' }}
          >
            Close
          </Button>
        </div>
      )}
      </Modal>
      <DragAndDropCalendar
        rtl={dir === 'rtl'}
        localizer={localizer}
        events={events}
        views={['month', 'week', 'day']}
        popup
        formats={formats}
        step={60}
        timeslots={1}
        showMultiDayTimes
        onEventDrop={moveEvent}
        defaultDate={new Date(moment().format('MMMM DD YY'))}
        eventPropGetter={eventStyleGetter}
        dayPropGetter={dayStyleGetter}
        messages={{
        previous: <span className="lnr lnr-chevron-left" />,
        next: <span className="lnr lnr-chevron-right" />,
        today: <span className="lnr lnr-calendar-full" />,
      }}
        onSelectEvent={openModal}
      />
    </div>
);
};

CalendarComponent.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      allDay: PropTypes.bool,
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
      priority: PropTypes.string,
    }),
  ).isRequired,
  small: PropTypes.bool,
  dir: PropTypes.string,

};

CalendarComponent.defaultProps = {
  small: false,
  dir: '',
};

export default CalendarComponent;
