import { createAction } from 'redux-actions';
import axios from 'axios';

import {
    LOCALSTORAGE_TOKEN,
    ADD_VISITING_SUCCESS, ADD_VISITING_FAILED, ADD_VISITING_ONGOING, ADD_VISITING_TIMEOUT,
    VISITING_LIST,
    UPDATE_VISITING_SUCCESS, UPDATE_VISITING_FAILED,
    DELETE_VISITING_SUCCCES, DELETE_VISITING_FAILED,
} from '../types';
import { VISITING_URL } from '../../utils/endPoint';

export const addVisitingSuccess = createAction(ADD_VISITING_SUCCESS);
export const addVisitingFailed = createAction(ADD_VISITING_FAILED);
export const addVisitingOngoing = createAction(ADD_VISITING_ONGOING);
export const addVisitingTimeout = createAction(ADD_VISITING_TIMEOUT);
export const getVisitingSuccess = createAction(VISITING_LIST);
export const updateVisitingSuccess = createAction(UPDATE_VISITING_SUCCESS);
export const updateVisitingFailed = createAction(UPDATE_VISITING_FAILED);
export const deleteVisitingSuccess = createAction(DELETE_VISITING_SUCCCES);
export const deleteVisitingFailed = createAction(DELETE_VISITING_FAILED);


export const addVisiting = (data, history) => async (dispatch) => {
    dispatch(addVisitingOngoing());
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    console.log('dari action Visiting 1', token);
    axios.post(VISITING_URL, data, { headers: { Authorization: `Token ${token}` } })
        .then((res) => {
            console.log('Visiting data', res.data);
            dispatch(addVisitingSuccess({ data: res.data }));
            setTimeout(() => dispatch(addVisitingTimeout()), 3000);
            history.push('/visiting/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addVisitingTimeout()), 3000);
        dispatch(addVisitingFailed({ error }));
    });
};

export const getVisiting = () => async (dispatch) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios.get(VISITING_URL, { headers: { Authorization: `Token ${token}` } }) 
        .then((res) => {
        dispatch(getVisitingSuccess({ data: res.data }));
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addVisitingTimeout()), 3000);
        dispatch(addVisitingFailed({ error }));
    });
};

export const updateVisiting = (data, id, history) => async (dispatch) => {
    dispatch(addVisitingOngoing());
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios.patch(`${VISITING_URL}${id}/`, data, { headers: { Authorization: `Token ${token}` } })
    .then((res) => {
        setTimeout(() => {
            dispatch(addVisitingTimeout());
        }, 3000);
        dispatch(updateVisitingSuccess({ data: res.data }));
        history.push('/visiting/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addVisitingTimeout()), 3000);
        dispatch(updateVisitingFailed({ error }));
    });
};

export const deleteVisiting = (id, history) => async (dispatch) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    console.log('dari action Visiting 4', token);
    axios.delete(`${VISITING_URL}${id}/`, { headers: { Authorization: `Token ${token}` } })
    .then(() => {
        dispatch(deleteVisitingSuccess());
        history.push('/visiting/index');
    })
    .catch((err) => {
        const error = err.response.data;
        dispatch(deleteVisitingFailed({ error }));
    });
};
