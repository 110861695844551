/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUser } from '../../../../redux/actions/userActions';

const BookingForm = (stateOri) => {
  const history = useHistory();
  const { dispatch } = stateOri;
  const { location } = history;
  const { state } = location;
  const { data } = state;
  const { initialize } = stateOri;
  const { listProduct } = stateOri;

  const [dataAge, setDataAge] = useState([]);
  const [dataBb, setDataBb] = useState([]);
  const [dataGender, setDataGender] = useState([]);

  useEffect(() => {
    initialize({
      ...data,
      amount: data.order_detail?.amount,
      age: data.order_detail?.description_2.slice(4),
      weight: data.order_detail?.description_1.slice(3),
      date: data.order_detail?.tanggal_book,
      pax: data.order_detail?.jumlah_pax,
    });
  }, []);
  const splitAge = () => {
    const dataFix = data?.order_detail?.description_1;
    const ageArray = dataFix.split(',');
    const umurArray = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < ageArray.length; i++) {
      const [key, value] = ageArray[i].split('~');
      // eslint-disable-next-line radix
      if (parseInt(value) !== 0) {
        // eslint-disable-next-line radix
        umurArray.push(parseInt(value));
      }
    }
    setDataAge(umurArray);
  };
  const splitBb = () => {
    const dataFix = data?.order_detail?.description_2;
    const bbArray = dataFix.split(',');
    const beratBadanArray = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < bbArray.length; i++) {
      const [key, value] = bbArray[i].split('~');
      // eslint-disable-next-line radix
      if (parseInt(value) !== 0) {
        // eslint-disable-next-line radix
        beratBadanArray.push(parseInt(value));
      }
    }
    setDataBb(beratBadanArray);
  };
  const splitGender = () => {
    const dataFix = data?.order_detail?.description_3;
    const gArray = dataFix.split(',');
    const genderArray = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < gArray.length; i++) {
      const [key, value] = gArray[i].split('~');
      // eslint-disable-next-line radix
      if (parseInt(value) !== 0) {
        // eslint-disable-next-line radix
        genderArray.push(value);
      }
    }
    setDataGender(genderArray);
  };
  useEffect(() => {
    splitAge();
    splitBb();
    splitGender();
    dispatch(getUser());
  }, [dispatch]);
  console.log(dataAge, dataBb, dataGender);
  return (
    <Container>
      <form className="form product-edit">
        <div className="form__form-group">
          <span className="form__form-group-label">Produk</span>
          <div className="form__form-group-field">
            <Field
              component="input"
              type="text"
              disabled
              placeholder={data.order_detail?.product_detail?.name}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Pelanggan</span>
          <div className="form__form-group-field">
            <Field
              component="input"
              type="text"
              disabled
              placeholder={data.order_detail?.user_detail?.fullname}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Status</span>
          <div className="form__form-group-field">
            <Field
              component="input"
              type="text"
              disabled
              placeholder={data.order_detail?.status}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Jumlah</span>
          <div className="form__form-group-field">
            <Field
              min={0}
              name="amount"
              component="input"
              type="number"
              required
              disabled
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Tanggal Booking</span>
          <div className="form__form-group-field">
            <Field
              name="date"
              component="input"
              type="date"
              required
              disabled
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Jumlah Pax</span>
          <div className="form__form-group-field">
            <Field
              name="pax"
              component="input"
              type="number"
              required
              disabled
            />
          </div>
        </div>

        {dataAge.length > 0 && dataBb.length > 0 && dataGender.length > 0
          ? dataAge.map((_, i) => (
            <>
              <div className="form__form-group">
                <span className="form__form-group-label">Umur</span>
                <div className="form__form-group-field">
                  <Field
                    name="age"
                    component="input"
                    type="number"
                    required
                    placeholder={dataAge[i]}
                    disabled
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Berat Badan</span>
                <div className="form__form-group-field">
                  <Field
                    name="weight"
                    component="input"
                    type="number"
                    required
                    placeholder={dataBb[i]}
                    disabled
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Gender</span>
                <div className="form__form-group-field">
                  <Field
                    component="input"
                    type="text"
                    disabled
                    placeholder={dataGender[i]}
                  />
                </div>
              </div>
            </>
            ))
          : null}
      </form>
    </Container>
  );
};

export default connect()(
// bind account loading action creator
  reduxForm({
    form: 'booking_edit_form', // a unique identifier for this form
    enableReinitialize: true,
  })(BookingForm),
);
