/* eslint-disable no-console */
import { createAction } from 'redux-actions';
import axios from 'axios';
import {
  RESETPASSWORD_FAILED,
  RESETPASSWORD_SUCCESS,
  RESETPASSWORDCONFIRM_SUCCESS,
  RESETPASSWORDCONFIRM_FAILED,
} from '../types';
import {
  RESETPASSWORDCONFIRM_URL,
  RESETPASSWORD_URL,
} from '../../utils/endPoint';

export const resetSuccess = createAction(RESETPASSWORD_SUCCESS);
export const resetFailed = createAction(RESETPASSWORD_FAILED);
export const resetConfirmSuccess = createAction(RESETPASSWORDCONFIRM_SUCCESS);
export const resetConfirmFaild = createAction(RESETPASSWORDCONFIRM_FAILED);

export const postResetPassword = data => async (dispatch) => {
  axios.post(RESETPASSWORD_URL, data)
    .then((res) => {
      setTimeout(() => window.location.reload(), 3000);
      dispatch(resetSuccess({ data: res.data }));
    })
    .catch((err) => {
      dispatch(resetFailed({ errorMessage: err }));
    });
};

export const postResetConfirm = (data, history) => async (dispatch) => {
  axios.post(RESETPASSWORDCONFIRM_URL, data)
    .then((res) => {
      dispatch(resetConfirmSuccess({ data: res.data }));
      setTimeout(() => history.push('/'), 3000);
    })
    .catch((err) => {
      dispatch(resetConfirmFaild({ errorMessage: err }));
    });
};
