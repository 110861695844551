import React from 'react';
import { Switch } from 'react-router-dom';
// import DetailCategories from '../../../ServiceCategory/DetailCategory';
import PrivateRoute from '../../../../shared/components/PrivateRoute';
import KbmList from '../../../Kbm/KbmList';

import AddKbm from '../../../Kbm/AddKbm';
import KbmDetail from '../../../Kbm/Detail';

export default () => (
  <Switch>
    <PrivateRoute path="/kbm/index" component={KbmList} />
    <PrivateRoute path="/kbm/add-kbm" component={AddKbm} />
    <PrivateRoute path="/kbm/detail/:id" component={KbmDetail} />

  </Switch>
);
