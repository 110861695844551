/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import {
 Button, ButtonToolbar, Card, CardBody, Col, Alert, 
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderFileInputField from '@/shared/components/form/FileInput';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LOCALSTORAGE_TOKEN } from '../../../../redux/types';
import { PARTNER_URL } from '../../../../utils/endPoint';

const PartnerAddForm = () => {
  const history = useHistory();
  const [err, setErr] = useState();
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const data = new FormData();
    data.append('name', [e.target.name][0].value);
    data.append('image', [e.target.image][0].files[0]);
    data.append('order_display', [e.target.order_display][0].value);

    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    // console.log('dari action partner 1', token);
    axios
      .post(PARTNER_URL, data, { headers: { Authorization: `Token ${token}` } })
      .then((res) => {
        setErr(false);
        setLoading(false);
        history.push('/partnership/index');
      })
      .catch((error) => {
        setErr(true);
        setErrMsg(error.response.data);
        setLoading(false);
      });
  };
  return (
    <Col xs={12} md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form form--horizontal" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">Gambar</span>
              <div className="form__form-group-field">
                <Field name="image" required component={renderFileInputField} />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Nama Mitra</span>
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component="input"
                  type="text"
                  placeholder="Nama Mitra"
                  required
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Urutan</span>
              <div className="form__form-group-field">
                <Field
                  name="order_display"
                  component="input"
                  type="number"
                  placeholder="Urutan"
                  required
                />
              </div>
            </div>
            {/* {
              loading && err ? <Alert>{errMsg}</Alert> : (
                <Alert
                  color={err ? 'success' : 'danger'}
                >
                  <p>
                    <span className="bold-text">Congratulations!</span>
                    Partner Successfully Added
                  </p>
                </Alert>
)
            } */}

            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">
                Submit
              </Button>
              <Button type="button" href="/partnership/index">
                Cancel
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};
export default reduxForm({
  form: 'partner_add_form', // a unique identifier for this form
})(PartnerAddForm);
