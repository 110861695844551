import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import BookingCard from './components/BookingCard';

const BookingDetail = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Pesanan</h3>
      </Col>
    </Row>
    <Row>
      <BookingCard />
    </Row>
  </Container>
);

export default BookingDetail;
