import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reducer as reduxFormReducer } from 'redux-form';
import {
  themeReducer,
  rtlReducer,
  customizerReducer,
  sidebarReducer,
  authReducer,
  resetPasswordReducer,
  partnerReducer,
  categoriesReducer,
  productReducer,
  communityReducer,
  imageProductReducer,
  facilityReducer,
  registrationUserReducer,
  horseReducer,
  cageReducer,
  bookingReducer,
  customerReducer,
  visitingReducer,
  memberReducer,
  kbmReducer,
  userReducer,
  scheduleReducer,
} from '../../redux/reducers/index';
import appConfigReducer from '../../redux/reducers/appConfigReducer';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  rtl: rtlReducer,
  appConfig: appConfigReducer,
  customizer: customizerReducer,
  sidebar: sidebarReducer,
  auth: authReducer, 
  resetPassword: resetPasswordReducer,
  partner: partnerReducer,
  categories: categoriesReducer,
  product: productReducer,
  community: communityReducer,
  imageProduct: imageProductReducer,
  facility: facilityReducer,
  registrationUser: registrationUserReducer,
  horse: horseReducer,
  cage: cageReducer,
  booking: bookingReducer,
  customer: customerReducer,
  visiting: visitingReducer,
  member: memberReducer,
  kbm: kbmReducer,
  user: userReducer,
  schedule: scheduleReducer,
});
const store = createStore(reducer, applyMiddleware(thunk));

export default store;
