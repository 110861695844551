/* eslint-disable max-len */
/* eslint-disable no-sequences */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
 Button, ButtonToolbar, Card, CardBody, Col, Spinner, 
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '@/shared/components/form/Select';
// import TimetableIcon from 'mdi-react/TimetableIcon';
import { connect } from 'react-redux';
// import renderDateTimePickerField from '@/shared/components/form/DateTimePicker';
import axios from 'axios';
import moment from 'moment';
// import { useHistory } from 'react-router';
import { getUser } from '../../../../redux/actions/userActions';
// import { getTrainer } from '../../../../redux/actions/trainerActions';
import { getHorse } from '../../../../redux/actions/horseActions';
import { LOCALSTORAGE_TOKEN } from '../../../../redux/types';
import { SCHEDULE_URL } from '../../../../utils/endPoint';

const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
const ScheduleAddForm = ({
 dispatch, listUser, listHorse, 
}) => {
  const getUserLocal = JSON.parse(localStorage.getItem('LOCALSTORAGE_MYUSER'));
  const [pelatih, setPelatih] = useState([]);
  const [tipe, setTipe] = useState('Memanah');
  const [loading, setLoading] = useState(false);
  // const [startDate, setStartDate] = useState('');
  const [error, setError] = useState('');
  // const history = useHistory();
  useEffect(() => {
    // console.log(error);
    const dataPelatih = async () => {
        const res = await axios.get(
          'https://api.ghaidas.com/api-ghaidas/userbyrole/Kasie Pelatih',
          { headers: { Authorization: `Token ${token}` } },
        );
        // console.log(res.data);
        setPelatih(res.data);
    };
    dataPelatih();
    dispatch(getUser());
    // dispatch(getTrainer());
    dispatch(getHorse());
  }, [dispatch]);

  const listPelatih = pelatih.length > 0
    && pelatih.map(item => ({
      value: item.id,
      label: item.fullname,
    }));
    // console.log(listPelatih);
  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    setLoading(true);
    console.log('trainerId', e.target.pelatih.value);
    if (e.target.user_id.value && e.target.type.value && e.target.start.value && e.target.end.value) {
      data.append('userId', e.target.user_id.value);
      if (e.target.horse_id) {
        data.append('horseId', e.target.horse_id.value);
      }
      if (e.target.pelatih) {
        data.append('trainerId', e.target.pelatih.value);
      }
      // data.append('trainerId', e.pelatih.value);
      data.append('date', moment(e.target.start.value).format('YYYY-MM-DD'));
      data.append('start_time', moment(e.target.start.value).format('HH:mm'));
      data.append('end_time', moment(e.target.end.value).format('HH:mm'));
      data.append('status', e.target.status.value === undefined ? 'Pending' : e.target.status.value);
      data.append('type', e.target.type.value);
      // addScheduleAction(data, history);
      // console.log('idKuda', moment(e.target.start.value).format('YYYY-MM-DD'));
      const result = async () => {
        try {
          const res = await axios.post(SCHEDULE_URL, data, { headers: { Authorization: `Token ${token}` } });
          console.log(res.data);
          setTimeout(() => {
            window.location.reload();
          }, [2000]);
          // history.push('/schedule/index');
          // return res.data;
        } catch (err) {
          console.log(err);
        }
      };
      result();
    } else {
      setError('Isi Data Dengan Lengkap!');
      setLoading(false);
      setTimeout(() => {
        setError('');
      }, [2000]);
      // console.log('tessss');
    }
    // console.log(e.target.status);
  };
  return (
    <Col xs={12} md={12} lg={12}>
      <Card>
        {error ? (
          <Alert
            color="warning"
            style={{ padding: '1rem', borderRadius: 'px' }}
          >
            Isi Data Dengan Lengkap!
          </Alert>
        ) : null}
        <CardBody>
          <form className="form product-edit" onSubmit={onSubmit}>
            <div className="form__form-group-id-category">
              <div className="form__form-group">
                <span className="form__form-group-label">Pelanggan</span>
                <div className="form__form-group-field">
                  <Field
                    name="user_id"
                    component={renderSelectField}
                    type="text"
                    required
                    options={listUser || [{ value: 'other', label: 'Other' }]}
                    placeholder="Choose Pelanggan"
                  />
                </div>
              </div>
            </div>
            <div className="form__form-group-id-category">
              <div className="form__form-group">
                <span className="form__form-group-label">Type</span>
                <div className="form__form-group-field">
                  <Field
                    name="type"
                    component={renderSelectField}
                    type="text"
                    required
                    options={[
                      { value: 'Memanah', label: 'Memanah' },
                      { value: 'Berkuda', label: 'Berkuda' },
                    ]}
                    placeholder="Choose Type"
                    onChange={(e) => {
                      setTipe(e.value);
                    }}
                  />
                </div>
              </div>
            </div>
            {getUserLocal.role === 'Administrasi Dan Keuangan'
              ? tipe === 'Berkuda' && (
              <div className="form__form-group-id-category">
                <div className="form__form-group">
                  <span className="form__form-group-label">Horse</span>
                  <div className="form__form-group-field">
                    <Field
                      name="horse_id"
                      component={renderSelectField}
                      type="text"
                      required
                      options={
                            listHorse || [{ value: 'other', label: 'Other' }]
                          }
                      placeholder="Choose Horse"
                    />
                  </div>
                </div>
              </div>
                )
              : null}
            {getUserLocal.role === 'Administrasi Dan Keuangan' ? (
              <div className="form__form-group-id-category">
                <div className="form__form-group">
                  <span className="form__form-group-label">Pelatih</span>
                  <div className="form__form-group-field">
                    <Field
                      name="pelatih"
                      component={renderSelectField}
                      type="text"
                      required
                      options={listPelatih}
                      placeholder="Choose Pelatih"
                    />
                  </div>
                </div>
              </div>
            ) : null}

            <div className="form__form-group">
              <span className="form__form-group-label">Start</span>
              <div className="form__form-group-field">
                <Field
                  name="start"
                  component="input"
                  type="datetime-local"
                  // required
                  // onChange={e => setStartDate(e)}
                />
                {/* <div className="form__form-group-icon">
                  <TimetableIcon />
                </div> */}
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">End</span>
              <div className="form__form-group-field">
                <Field
                  name="end"
                  component="input"
                  type="datetime-local"
                  // required
                />
                {/* <div className="form__form-group-icon">
                  <TimetableIcon />
                </div> */}
              </div>
            </div>
            <div className="form__form-group-id-category">
              <div className="form__form-group">
                <span className="form__form-group-label">Status</span>
                <div className="form__form-group-field">
                  <Field
                    name="status"
                    component={renderSelectField}
                    type="text"
                    required
                    options={[
                      { value: 'Pending', label: 'Pending' },
                      { value: 'Approved', label: 'Approved' },
                      { value: 'Reschedule', label: 'Reschedule' },
                    ]}
                    placeholder="Choose Status"
                  />
                </div>
              </div>
            </div>

            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" size="sm" type="submit">
                {loading ? <Spinner size="sm">Loading...</Spinner> : 'Submit'}
              </Button>
              <Button type="button" size="sm" href="/schedule/index">
                Cancel
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

const mapStateToProps = (state) => {
  const user = state.user.listData
    ? state.user.listData.map(e => ({ value: `${e.id}`, label: e.fullname }))
    : [];
  // const trainer = state.trainer.listData ? state.trainer.listData.map(e => ({ value: `${e.id}`, label: e.user_detail?.fullname })) : [];
  const horse = state.horse.listData
    ? state.horse.listData.map(e => ({ value: `${e.id}`, label: e.name }))
    : [];
  return {
    listUser: user,
    // listTrainer: trainer,
    listHorse: horse,
  };
};
ScheduleAddForm.propTypes = {
  // errorMessage: PropTypes.string,
  // addScheduleAction: PropTypes.func.isRequired,
  // error: PropTypes.string,
  // status: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  listUser: PropTypes.shape().isRequired,
  listHorse: PropTypes.shape().isRequired,
};

ScheduleAddForm.defaultProps = {
  // errorMessage: '',
  // error: '',
};

export default connect(
  mapStateToProps,
  null,
)(
  reduxForm({
    form: 'schedule_add_form', // a unique identifier for this form
  })(ScheduleAddForm),
);
