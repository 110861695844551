import React from 'react';
import { Switch } from 'react-router-dom';
// import DetailCategories from '../../../ServiceCategory/DetailCategory';
import PrivateRoute from '../../../../shared/components/PrivateRoute';
import ScheduleList from '../../../Schedule/ScheduleList';
// import AddSchedule from '../../../Schedule/AddSchedule';
import ScheduleDetail from '../../../Schedule/Detail';

export default () => (
  <Switch>
    <PrivateRoute path="/schedule/index" component={ScheduleList} />
    {/* <PrivateRoute path="/schedule/add-schedule" component={AddSchedule} /> */}
    <PrivateRoute path="/schedule/detail/:id" component={ScheduleDetail} />
    {/* <PrivateRoute
      path="/cage/detail-cage/:id"
      component={DetailCage}
    /> */}
  </Switch>
);
