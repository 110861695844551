/* eslint-disable react/react-in-jsx-scope */
import { useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';

const memberDetailHandler = (history, info) => {
  history.push({
    pathname: `/member/detail/${info.id}`, 
    state: { data: info },
  });
};


const CreateDataMemberListTable = (dataAPI) => {
  const [copy, setCopy] = useState(false);
  const [confirm, setConfirm] = useState();
  // console.log(dataAPI[0]?.product_detail?.id);
  console.log(dataAPI);
  // console.log(dataAPI[0]?.product_id);
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        accessor: 'id',
        Header: 'No',
        width: 20,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        accessor: 'product',
        Header: 'Produk',
      },
      {
        accessor: 'status',
        Header: 'Status',
      },
      {
        accessor: 'customer',
        Header: 'Pelanggan',
      },
      {
        accessor: 'type',
        Header: 'Tipe',
      },
      {
        accessor: 'day',
        Header: 'Hari',
      },
      {
        accessor: 'start_date',
        Header: 'Mulai',
      },
      {
        accessor: 'end_date',
        Header: 'Selesai',
      },
      {
        accessor: 'action',
        Header: 'Action',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ],
    [],
  );


  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
        nama: '-',
        lokasi: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
            nama: '-',
            lokasi: '-',
          });
        } else {
          data.push({
            id: `${i + 1}`,
            product: dataAPI[i].order_detail?.product_detail?.name,
            status: dataAPI[i].order_detail.status,
            customer: dataAPI[i].order_detail?.user_detail?.fullname,
            day: dataAPI[i]?.day_choice,
            type: dataAPI[i]?.type,
            start_date: dataAPI[i]?.start_date,
            end_date: dataAPI[i]?.end_date,
            

            action: [
              // eslint-disable-next-line no-loop-func
              // eslint-disable-next-line max-len
              (<Button key={i} size="sm" color="success" onClick={() => memberDetailHandler(history, dataAPI[i])}>Detail</Button>),
             dataAPI[i].status !== 'Lunas' ? (
               <Button size="sm" color="primary">
                 <CopyToClipboard
                   text={dataAPI[i].order_detail?.payment_url}
                   onCopy={() => {
                    setCopy(true);
                    setConfirm(dataAPI[i].id);
                    setTimeout(() => {
                      setCopy(false);
                    }, [5000]);
                  }}
                 >
                   <span>{copy && confirm === dataAPI[i].id ? 'Copied' : 'Bayar '}<FaCopy /></span>
                 </CopyToClipboard>
               </Button>
             ) : null,
            ],
          });
        }
      }
    }
  };

  rows();
  const MemberListTableData = { tableHeaderData: columns, tableRowsData: data };
  return MemberListTableData;
};

export default CreateDataMemberListTable;
