import { createAction } from 'redux-actions';
import axios from 'axios';

import {
    LOCALSTORAGE_TOKEN,
    ADD_SCHEDULE_SUCCESS, ADD_SCHEDULE_FAILED, ADD_SCHEDULE_ONGOING, ADD_SCHEDULE_TIMEOUT,
    SCHEDULE_LIST,
    UPDATE_SCHEDULE_SUCCESS, UPDATE_SCHEDULE_FAILED,
    DELETE_SCHEDULE_SUCCCES, DELETE_SCHEDULE_FAILED,
} from '../types';
import { SCHEDULE_URL } from '../../utils/endPoint';

export const addScheduleSuccess = createAction(ADD_SCHEDULE_SUCCESS);
export const addScheduleFailed = createAction(ADD_SCHEDULE_FAILED);
export const addScheduleOngoing = createAction(ADD_SCHEDULE_ONGOING);
export const addScheduleTimeout = createAction(ADD_SCHEDULE_TIMEOUT);
export const getScheduleSuccess = createAction(SCHEDULE_LIST);
export const updateScheduleSuccess = createAction(UPDATE_SCHEDULE_SUCCESS);
export const updateScheduleFailed = createAction(UPDATE_SCHEDULE_FAILED);
export const deleteScheduleSuccess = createAction(DELETE_SCHEDULE_SUCCCES);
export const deleteScheduleFailed = createAction(DELETE_SCHEDULE_FAILED);



export const addSchedule = data => async (dispatch) => {
    dispatch(addScheduleOngoing());
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    console.log('dari action Schedule 1', token);
    axios.post(SCHEDULE_URL, data, { headers: { Authorization: `Token ${token}` } })
        .then((res) => {
            console.log('Schedule data', res.data);
            dispatch(addScheduleSuccess({ data: res.data }));
            window.location.reload();
            // setTimeout(() => dispatch(addScheduleTimeout()), 3000);
            // history.push('/schedule/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addScheduleTimeout()), 3000);
        dispatch(addScheduleFailed({ error }));
    });
};

export const getSchedule = () => async (dispatch) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const getUser = JSON.parse(localStorage.getItem('LOCALSTORAGE_MYUSER'));
    axios.get(SCHEDULE_URL, { headers: { Authorization: `Token ${token}` } }) 
        .then((res) => {
        if (getUser.role === 'Kepala Stable') {
            const dataPending = res.data.filter(item => item.status === 'Pending');
            dispatch(
              getScheduleSuccess({
                data: dataPending.sort((a, b) => b.id - a.id),
              }),
            );
        } else if (getUser.role === 'Kasie Pelatih') {
            const dataReschedule = res.data.filter(
              item => item.status === 'Approved',
            );
            dispatch(
              getScheduleSuccess({
                data: dataReschedule.sort((a, b) => b.id - a.id),
              }),
            );
        } else if (getUser.role === 'Pelatih') {
            const dataApproved = res.data.filter(
              item => item.status === 'Approved',
            );
            console.log(dataApproved);
            const dataPelatih = dataApproved.filter(item => item.trainer_detail?.fullname === getUser.fullname);
            dispatch(
              getScheduleSuccess({
                data: dataPelatih.sort((a, b) => b.id - a.id),
              }),
            );
        } else {
          dispatch(getScheduleSuccess({ data: res.data.sort((a, b) => b.id - a.id) }));
        }
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addScheduleTimeout()), 3000);
        dispatch(addScheduleFailed({ error }));
    });
};

export const updateSchedule = (data, id, history) => async (dispatch) => {
    dispatch(addScheduleOngoing());
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    axios.patch(`${SCHEDULE_URL}${id}/`, data, { headers: { Authorization: `Token ${token}` } })
    .then((res) => {
        setTimeout(() => {
            dispatch(addScheduleTimeout());
        }, 3000);
        dispatch(updateScheduleSuccess({ data: res.data }));
        history.push('/schedule/index');
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addScheduleTimeout()), 3000);
        dispatch(updateScheduleFailed({ error }));
    });
};

export const deleteSchedule = (id, history) => async (dispatch) => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    console.log('dari action Schedule 4', token);
    axios.delete(`${SCHEDULE_URL}${id}/`, { headers: { Authorization: `Token ${token}` } })
    .then(() => {
        dispatch(deleteScheduleSuccess());
        history.push('/schedule/index');
    })
    .catch((err) => {
        const error = err.response.data;
        dispatch(deleteScheduleFailed({ error }));
    });
};
