import { createAction } from 'redux-actions';
import axios from 'axios';

import {
    LOCALSTORAGE_TOKEN,
    ADD_IMAGEPRODUCT_SUCCESS, ADD_IMAGEPRODUCT_FAILED, ADD_IMAGEPRODUCT_ONGOING, ADD_IMAGEPRODUCT_TIMEOUT,
    IMAGEPRODUCT_LIST,
    UPDATE_IMAGEPRODUCT_SUCCESS, UPDATE_IMAGEPRODUCT_FAILED,
    DELETE_IMAGEPRODUCT_SUCCCES, DELETE_IMAGEPRODUCT_FAILED,
} from '../types';
import { IMAGEPRODUCT_URL } from '../../utils/endPoint';

export const addImageProductSuccess = createAction(ADD_IMAGEPRODUCT_SUCCESS);
export const addImageProductFailed = createAction(ADD_IMAGEPRODUCT_FAILED);
export const addImageProductOngoing = createAction(ADD_IMAGEPRODUCT_ONGOING);
export const addImageProductTimeout = createAction(ADD_IMAGEPRODUCT_TIMEOUT);
export const getImageProductSuccess = createAction(IMAGEPRODUCT_LIST);
export const updateImageProductSuccess = createAction(UPDATE_IMAGEPRODUCT_SUCCESS);
export const updateImageProductFailed = createAction(UPDATE_IMAGEPRODUCT_FAILED);
export const deleteImageProductSuccess = createAction(DELETE_IMAGEPRODUCT_SUCCCES);
export const deleteImageProductFailed = createAction(DELETE_IMAGEPRODUCT_FAILED);

const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
export const addImageProduct = (data, dataproduct, history) => async (dispatch) => {
    dispatch(addImageProductOngoing());
    console.log('dari action ImageProduct 1', token);
    console.log('dataproduct', dataproduct);
    axios.post(IMAGEPRODUCT_URL, data, { headers: { Authorization: `Token ${token}` } })
        .then((res) => {
            console.log('ImageProduct data', res.data);
            dispatch(addImageProductSuccess({ data: res.data }));
            setTimeout(() => dispatch(addImageProductTimeout()), 3000);
            // history.push('/product/index');
            history.push({
                pathname: `/product/detail-product/${dataproduct.id}/`, 
                state: { data: dataproduct },
              });
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addImageProductTimeout()), 3000);
        dispatch(addImageProductFailed({ error }));
    });
};


export const getImageProduct = () => async (dispatch) => {
    console.log('dari action ImageProduct 2', token);
    axios.get(IMAGEPRODUCT_URL, { headers: { Authorization: `Token ${token}` } }) 
        .then((res) => {
        dispatch(getImageProductSuccess({ data: res.data }));
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addImageProductTimeout()), 3000);
        dispatch(addImageProductFailed({ error }));
    });
};

export const updateImageProduct = (data, id, history, products) => async (dispatch) => {
    dispatch(addImageProductOngoing());

    console.log('dari action ImageProduct 3', token, id, data);
    axios.patch(`${IMAGEPRODUCT_URL + id}/`, data, { headers: { Authorization: `Token ${token}` } })
    .then((res) => {
        setTimeout(() => {
            dispatch(addImageProductTimeout());
        }, 3000);
        dispatch(updateImageProductSuccess({ data: res.data }));
        // history.push('/product/index');
        history.push({
            pathname: `/product/detail-product/${products.id}/`, 
            state: { data: products },
          });
    })
    .catch((err) => {
        const error = err.response.data;
        setTimeout(() => dispatch(addImageProductTimeout()), 3000);
        dispatch(updateImageProductFailed({ error }));
    });
};

export const deleteImageProduct = (id, history, products) => async (dispatch) => {
    axios.delete(`${IMAGEPRODUCT_URL + id}/`, { headers: { Authorization: `Token ${token}` } })
    .then(() => {
        dispatch(deleteImageProductSuccess());
        // history.push('/product/index');
        history.push({
            pathname: `/product/detail-product/${products.id}/`, 
            state: { data: products },
          });
    })
    .catch((err) => {
        const error = err.response.data;
        dispatch(deleteImageProductFailed({ error }));
    });
};

