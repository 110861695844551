/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
 Container, Col, Row, Spinner, 
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PartnerListTable from './components/PartnerListTable';
import CreateTableData from './components/CreateData';
import { getFacility } from '../../../../redux/actions/facilityActions';
import { LOCALSTORAGE_MYUSER } from '../../../../redux/types';

const Partners = ({ dispatch, listData, loading }) => {
  const partnerListTableData = CreateTableData(listData);

  useEffect(() => {
    const localdata = JSON.parse(localStorage.getItem(LOCALSTORAGE_MYUSER));
    dispatch(getFacility(localdata.id));
  }, []);
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Daftar Fasilitas</h3>
        </Col>
      </Row>
      <Row>{loading && <Spinner color="success" size="md" />}</Row>
      <Row>
        <PartnerListTable partnerListTableData={partnerListTableData} />
      </Row>
    </Container>
  );
};

Partners.propTypes = {
  dispatch: PropTypes.func.isRequired,
  listData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    listData: state.facility.data,
    loading: state.facility.loading,
  });

export default connect(mapStateToProps, null)(Partners);
