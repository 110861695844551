/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, Form } from 'redux-form';
import { Button, Alert } from 'reactstrap';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import EyeIcon from 'mdi-react/EyeIcon';

const ResetPasswordConfirmationForm = ({
  handleSubmit, errorMessage, status,
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  
    const toggleShowPassword = (e) => {
      e.preventDefault();
      setShowPassword(!showPassword);
    };
  
    const toggleShowPasswordConfirm = (e) => {
      e.preventDefault();
      setShowPasswordConfirm(!showPasswordConfirm);
    };

    return (
      <Form className="form reset-password-form" onSubmit={handleSubmit}>
        {status && (
        <div className="form__form-group">
          <div className="mb-3">
            <Alert
              color={status === 'success' ? 'success' : 'danger'}
              isOpen={errorMessage !== '' || status === 'success'}
            >
              <div className="p-2">
                <p>{status === 'success' ? 'Password berhasil di ubah, Silahkan login kembali' : errorMessage}</p>
              </div>
            </Alert>
          </div>
        </div>
    )}
        <div className="form__form-group">
          <span className="form__form-group-label">New Pssword</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="new_password"
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              required
              className="input-without-border-radius"
            />
            <button
              type="button"
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={toggleShowPassword}
            ><EyeIcon />
            </button>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Confirm New Pssword</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="confirmpassword"
              component="input"
              type={showPasswordConfirm ? 'text' : 'password'}
              placeholder="Confirm Password"
              required
              className="input-without-border-radius"
            />
            <button
              type="button"
              className={`form__form-group-button${showPasswordConfirm ? ' active' : ''}`}
              onClick={toggleShowPasswordConfirm}
            ><EyeIcon />
            </button>
          </div>
        </div>
        <Button className="account__btn" color="primary">
          Reset Password
        </Button>
      </Form>
    );
};

ResetPasswordConfirmationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  status: PropTypes.string.isRequired,
};

ResetPasswordConfirmationForm.defaultProps = {
  errorMessage: '',
};

export default reduxForm({
  form: 'resetpassword_form',
})(ResetPasswordConfirmationForm);
