import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CloseIcon from 'mdi-react/CloseIcon';
import {
  ThemeProps,
} from '@/shared/prop-types/ReducerProps';
import ToggleTheme from './ToggleTheme';

const settings = `${process.env.PUBLIC_URL}/img/settings.svg`;

const Customizer = ({
  theme,
  changeToDark,
  changeToLight,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const customizerClass = classNames({
    customizer__wrap: true,
    'customizer__wrap--open': isOpen,
  });

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="customizer">
      <button className="customizer__btn" type="button" onClick={handleOpen}>
        <img className="customizer__btn-icon" src={settings} alt="icon" />
      </button>
      <div className={customizerClass} hidden={!isOpen}>
        <div className="customizer__title-wrap">
          <h5>Theme Customizer</h5>
          <button className="customizer__close-btn" type="button" onClick={handleOpen}>
            <CloseIcon />
          </button>
        </div>
        <ToggleTheme changeToDark={changeToDark} changeToLight={changeToLight} theme={theme} />

      </div>
    </div>
  );
};

Customizer.propTypes = {
  theme: ThemeProps.isRequired,
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
};

export default Customizer;
