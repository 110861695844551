/* eslint-disable arrow-parens */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ResetPasswordConfirmationForm from '@/shared/components/reset_password/ResetPasswordConfirmationForm';
import { postResetConfirm } from '../../../redux/actions/resetPasswordActions';


const onSubmit = (e, handleFunc, token, history) => {
  e.preventDefault();
  const data = new FormData();
  data.append('token', token);
  data.append('new_password', [e.target.password][0].value);
  handleFunc(data, history);
};

const ResetPassword = ({
  status, errorMessage, resetConfirmAction, tokenReset, history,
}) => (
  <div className="account account--not-photo">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">Welcome to
            <span className="account__logo"> Ghaida
              <span className="account__logo-accent">S</span>
            </span>
          </h3>
          <h4 className="account__subhead subhead">Password reset</h4>
        </div>
        <ResetPasswordConfirmationForm
          status={status}
          errorMessage={errorMessage}
          handleSubmit={(e) => onSubmit(e, resetConfirmAction, tokenReset, history)}
          form="reset_password_form"
        />
      </div>
    </div>
  </div>
  );

  ResetPassword.propTypes = {
    resetConfirmAction: PropTypes.func.isRequired,
    errorMessage: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    tokenReset: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
  };
  
  const mapDispatchToProps = {
    resetConfirmAction: postResetConfirm,
  };
  
  const mapStateToProps = (state, props) => {
    console.log('data', state.resetPassword);
    console.log('bawah', props.match.params.token);
    return ({
      tokenReset: props.match.params.token,
      data: state.resetPassword.data,
      errorMessage: state.resetPassword.errorMessage,
      status: state.resetPassword.status,
    });
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
