/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const ImageFormatter = value => (
  <div className="products-list__img-wrap">
    <img src={value} alt="" />
  </div>
);

const productDetailHandler = (history, info) => {
  history.push({
    pathname: `/partnership/detail/${info.id}`, 
    state: { data: info },
  });
};

const CreateDataOrderListTable = (dataAPI) => {
  const history = useHistory();
  // console.log('data api', dataAPI);
  const columns = useMemo(
    () => [
      {
        accessor: 'number',
        Header: 'No',
        width: 20,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Gambar',
        accessor: 'image',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        accessor: 'name',
        Header: 'Nama Mitra',
      },
      {
        accessor: 'order_display',
        Header: 'Urutan',
      },
      {
        accessor: 'status',
        Header: 'Action',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );


  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
        name: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
            name: '-',
          });
        } else {
          data.push({
            id: dataAPI[i].id,
            number: `${i + 1}`,
            name: dataAPI[i].name,
            image: ImageFormatter(`${dataAPI[i].image}`),
            order_display: `${dataAPI[i].order_display ?? ''}`,
            status: [
              // eslint-disable-next-line no-loop-func
              // eslint-disable-next-line max-len
              (<Button key={i} size="sm" color="success" onClick={() => productDetailHandler(history, dataAPI[i])}>Detail</Button>),
            ],
          });
        }
      }
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default CreateDataOrderListTable;
