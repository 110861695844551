import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import PaymentCard from './components/PaymentCard';
import ImageProductList from './ImageProduct/ListingImage';

const Payment = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Produk</h3>
        {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
          information
        </h3> */}
      </Col>
    </Row>
    <Row>
      <PaymentCard />
    </Row>
    <Row>
      <ImageProductList />
    </Row>
  </Container>
);

export default Payment;
